import { useEffect, useRef } from 'react';
import { STATUSES } from '../constants';
const CurrencySelect = ({ iframeUrl, showReceipt, goToChoosePayments }) => {
  const currencySelectIframeRef = useRef();

  useEffect(() => {
    createIframe();

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const createIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.src = iframeUrl;
    iframe.style.border = 'none';
    iframe.style.width = '100%';
    iframe.style.height = '800px';
    iframe.style.overflowY = 'auto';
    const currencySelectIframe = currencySelectIframeRef.current;

    if (currencySelectIframe) {
      currencySelectIframe.innerHTML = '';
      currencySelectIframe.appendChild(iframe);
    }

    window.addEventListener('message', handleMessage);
  };

  const handleMessage = (ev) => {
    var action = ev.data;
    if (action === 'success') {
      showReceipt(STATUSES.SUCCESS); //Success receipt
    } else if (action === 'cancel') {
      goToChoosePayments();
    } else {
      showReceipt(STATUSES.FAILED); //failure receipt
    }
  };

  return (
    <div>
      <div id='currencyselect-iframe' ref={currencySelectIframeRef}></div>
    </div>
  );
};

export default CurrencySelect;
