import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Button from '@mui/material/Button';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { senDTransactionDisclosure } from '../../apis';
import { Cf, Nf, RawHtmlContent } from '../../components/Format';
import { Translate } from '../../i18n/translate';
import { selectData, selectLocale } from '../../store/slices/session/sessionSlice';
import {
  getAmount,
  getClientRate,
  getCurrency,
  getFee,
  getHomeAmount,
  getHomeCurrency,
  getRate,
} from '../../utils/quoteService';
import { MODES } from './constants';

const TransactionDisclosure = ({ setMode, paymentTypeId, goToChoosePayments }) => {
  const locale = useSelector(selectLocale);
  const session = useSelector(selectData);
  const [payerState, setPayerState] = useState('');
  const [payerCountry, setPayerCountry] = useState('');
  const [dfDate, setDfDate] = useState('');
  const [emailSuccess, setEmailSuccess] = useState(false);
  const ctdRef = useRef();

  useEffect(() => {
    const date = new Date();
    const _dfDate = date.setDate(date.getDate() + 10);
    setDfDate(_dfDate);
    setPayerState(session.pledge.payerState);
    setPayerCountry(session.pledge.payerCountry);
  }, [session]);

  const DATE_OPTIONS2 = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  const intl = useIntl();

  const getPhone2 = () => {
    return [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CT',
      'F*',
      'ID',
      'IL',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MN',
      'NJ',
      'OR',
      'PA',
      'TX',
      'VT',
    ].includes(session.pledge.payerState);
  };
  const sendEmail = async () => {
    const combinedDisclosureRequest = {};
    combinedDisclosureRequest.clientID = session.clientId;
    combinedDisclosureRequest.payerEmailAddress = session.buyerDetails.email;
    combinedDisclosureRequest.todaysDate = new Date();
    combinedDisclosureRequest.paymentReferenceNumber = session.dealReference;

    combinedDisclosureRequest.senderFirstName = session.buyerDetails.firstName;
    combinedDisclosureRequest.senderLastName = session.buyerDetails.lastName;

    combinedDisclosureRequest.receiverName = session.sellerInfo.name;
    combinedDisclosureRequest.receiverAddress = session.sellerInfo.address;
    combinedDisclosureRequest.receiverCity = session.sellerInfo.city;
    combinedDisclosureRequest.receiverState = session.sellerInfo.state;
    combinedDisclosureRequest.receiverZipCode = session.sellerInfo.zip;
    combinedDisclosureRequest.receiverCountry = session.sellerInfo.country;
    combinedDisclosureRequest.payerState = session.pledge.payerState;

    combinedDisclosureRequest.dfDate = dfDate;

    combinedDisclosureRequest.transferAmount = getAmount(session.selectedQuote);
    //combinedDisclosureRequest.transferTax = BigDecimal.valueOf(0.00);
    combinedDisclosureRequest.totalFee = session.selectedQuote.fee;
    combinedDisclosureRequest.buyerCurrency = getCurrency(session.selectedQuote);

    combinedDisclosureRequest.exchangeRate = _getRate(session.selectedQuote).toFixed(4);
    combinedDisclosureRequest.homeTransferAmount =
      getHomeAmount(session.selectedQuote) + getFee(session.selectedQuote);
    combinedDisclosureRequest.otherFee = getFee(session.selectedQuote);
    combinedDisclosureRequest.totalToReceiverAmount = getHomeAmount(session.selectedQuote);
    combinedDisclosureRequest.homeCurrency = getHomeCurrency(session.selectedQuote);

    if (['AE', 'AA', 'AP'].includes(session.pledge.payerState)) {
      return;
    } else {
      if (session.pledge.payerState != 'F*') {
        combinedDisclosureRequest.stateAgency = intl
          .formatMessage({ id: 'JS_TRANDIS_STATE_AGENCY_' + session.pledge.payerState })
          .replaceAll('&', '&amp;');
      }
      combinedDisclosureRequest.statePhoneNumber1 = intl.formatMessage({
        id: 'JS_TRANDIS_STATE_PHONE_1_' + session.pledge.payerState,
      });

      if (getPhone2()) {
        combinedDisclosureRequest.statePhoneNumber2 = intl.formatMessage({
          id: 'JS_TRANDIS_STATE_PHONE_2_' + session.pledge.payerState,
        });
      }
      const stateUrl = intl.formatMessage({
        id: 'JS_TRANDIS_STATE_URL_' + session.pledge.payerState,
      });

      if (stateUrl != 'JS_TRANDIS_STATE_URL_' + session.pledge.payerState) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(stateUrl, 'text/html');
        const hrefValue = doc.querySelector('a')?.getAttribute('href');
        combinedDisclosureRequest.stateURL = hrefValue;
      }
    }

    let response = await senDTransactionDisclosure(combinedDisclosureRequest);
    if (response.success) {
      response = response.data;
      if (response.reportId) {
        setEmailSuccess(true);
      }
    }
  };

  const downloadPDF = () => {
    var opt = {
      margin: 1,
      marginBottom: 1,
      filename: 'order-receipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };
    html2pdf(ctdRef.current, opt);
  };

  const submit = () => {
    switch (paymentTypeId) {
      case 'CS_MCP_MC':
      case 'CS_MCP_VISA':
      case 'CS_DCC_MC_VISA':
        setMode(MODES.CURRENCY_SELECT);
        break;
      case 'PIX':
        setMode(MODES.UNLIMINT);
        break;
      case 'US_ONLINE_BANKING':
        setMode(MODES.TRUSTLY_NA);
        break;
      case 'WIRE':
        setMode(MODES.OFFLINE);
        break;
      default:
        setMode(MODES.WORLD_PAY);
    }
  };

  const _getRate = (selectedQuote) => {
    if (selectedQuote.paymentType.id === 'WIRE') {
      return getClientRate(selectedQuote);
    }
    return getRate(selectedQuote);
  };

  return (
    <div className='container max-w-[750px]' ref={ctdRef}>
      <div id='order-receipt'>
        <div className='left'>
          <Translate value='JS_TRANDIS_HEADER' className='text-14-400-24' />
        </div>
        <div>
          <div className='text-16-bold mt-3 flex justify-between'>
            <Translate value='JS_TRANDIS_ORDER_SUM' />
            <span className='text-14 float-right'>
              {new Date().toLocaleDateString(locale.replace('_', '-'), DATE_OPTIONS2)}
            </span>
          </div>
          <div className='text-sm mt-3'>
            <div className='flex justify-between'>
              <Translate value='JS_TRANDIS_SENDER' />
              <span className='float-right text-right text-14-bold'>
                {session.buyerDetails.firstName}
                <span className='ml-1'>{session.buyerDetails.lastName}</span>
              </span>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_RECEIVER' />
              <div className='float-right text-right text-14-bold'>
                <div>{session.sellerInfo.name}</div>
                <div>{session.sellerInfo.address}</div>
                <div>{session.sellerInfo.city}</div>
                <div>{session.sellerInfo.state}</div>
                <div>{session.sellerInfo.zip}</div>
                <div>
                  <Translate value={session.sellerInfo.country} />
                </div>
              </div>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_DF_DATE' />
              <span className='float-right text-right text-14-bold'>
                {new Date(dfDate).toLocaleDateString(locale.replace('_', '-'), DATE_OPTIONS2)}
              </span>
            </div>

            <div className='float-right mt-1 italic'>
              <Translate value='JS_TRANDIS_DF_TEXT' />
            </div>

            <div className='flex justify-between mt-8'>
              <Translate value='JS_TRANDIS_AMOUNT' />
              <span className='float-right text-14-bold'>
                {Nf(getAmount(session.selectedQuote))} {Cf(getCurrency(session.selectedQuote))}
              </span>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_TAX' />
              <span className='float-right text-14-bold'>
                0.00 {Cf(getCurrency(session.selectedQuote))}
              </span>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_FEE' />
              <span className='float-right text-14-bold'>
                {Nf(session.selectedQuote.fee)} {Cf(getCurrency(session.selectedQuote))}
              </span>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_TOTAL' />
              <span className='float-right text-14-bold'>
                {Nf(getAmount(session.selectedQuote) + session.selectedQuote.fee)}{' '}
                {Cf(getCurrency(session.selectedQuote))}
              </span>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_TOTAL_TO_RECEIVER' />
              <span className='float-right text-14-bold'>
                {Nf(getHomeAmount(session.selectedQuote))}{' '}
                {Cf(getHomeCurrency(session.selectedQuote))}
              </span>
            </div>

            <div className='flex justify-between mt-2'>
              <Translate value='JS_TRANDIS_EXCHANGE_RATE' />
              <span className='float-right text-14-bold'>
                1 {getHomeCurrency(session.selectedQuote)}
                <span> = </span>
                {Nf(_getRate(session.selectedQuote), 4)}
                <span className='ml-1'>{getCurrency(session.selectedQuote)}</span>
              </span>
            </div>
          </div>
        </div>
        {/* // Text body */}
        <div className='italic text-14-400-22 mt-4'>
          <RawHtmlContent htmlContent='JS_TRANDIS_FIRST_CONTENT' />
          <div className='mt-2'>
            <RawHtmlContent htmlContent='JS_TRANDIS_SECOND_CONTENT' />
          </div>
          <div className='mt-2'>
            <RawHtmlContent htmlContent='JS_TRANDIS_THIRD_CONTENT' />
          </div>

          <div className='mt-2 text-14-bold not-italic'>
            <RawHtmlContent htmlContent='JS_TRANDIS_FOURTH_CONTENT' />
          </div>

          <div className='mt-2 text-14'>
            <Translate value='JS_TRANDIS_WEBSITE' />
          </div>

          <div className='mt-1 text-14'>
            <RawHtmlContent htmlContent='JS_TRANDIS_WEBSITE_REF' />
          </div>

          <div className='mt-2 text-14-bold'>
            <Translate value='JS_TRANDIS_FIFTH_CONTENT' />
          </div>

          <div className='mt-0.5 text-14'>
            <Translate value='JS_TRANDIS_HEADER' className='mb-1' />
            <RawHtmlContent htmlContent='JS_TRANDIS_ADDRESS' />
          </div>

          <div className='mt-2'>
            <Translate value='JS_TRANDIS_SIXTH_CONTENT' className='text-14-bold' />
            <RawHtmlContent htmlContent='JS_TRANDIS_EMAIL' />
          </div>

          <div className='mt-2'>
            <Translate value='JS_TRANDIS_SEVENTH_CONTENT' className='text-14-bold' />
          </div>

          <div className='mt-0.5'>
            <Translate value='JS_TRANDIS_CALL' />
          </div>

          <div className='mt-2'>
            <Translate value='JS_TRANDIS_ENQUIRY' className='text-14-bold not-italic' />
          </div>

          <div className='mt-1'>
            <Translate value='JS_TRANDIS_ENQUIRY_1' className='text-14-bold' />
          </div>

          <div className='mt-1'>
            <Translate value='JS_TRANDIS_ENQUIRY_2' />
          </div>

          <div className='mt-0.5'>
            <Translate value='JS_TRANDIS_ENQUIRY_3' />
          </div>

          <div className='mt-0.5'>
            <RawHtmlContent htmlContent='JS_TRANDIS_ENQUIRY_4' />
          </div>

          {payerCountry === 'USA' && (
            <div className='mt-2'>
              <div>
                <Translate
                  value={'JS_TRANDIS_STATE_AGENCY_' + payerState}
                  className='text-14-bold'
                />
              </div>

              <div className='mt-1'>
                <Translate value={'JS_TRANDIS_STATE_PHONE_1_' + payerState} />
              </div>

              {getPhone2() && (
                <div className='mt-1'>
                  <Translate value={'JS_TRANDIS_STATE_PHONE_2_' + payerState} />
                </div>
              )}

              <div className='mt-1'>
                <RawHtmlContent htmlContent={'JS_TRANDIS_STATE_URL_' + payerState} />
              </div>
              {payerState === 'CA' && (
                <div className='mt-2'>
                  <RawHtmlContent htmlContent='JS_TRANDIS_ADDITIONAL_TEXT' />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {emailSuccess && (
        <Translate value='JS_EMAIL_SENT' className='text-success flex justify-center' />
      )}
      <div className='flex justify-center mt-4'>
        <div className='grow'></div>
        <div className='cursor-pointer' onClick={sendEmail}>
          <EmailOutlinedIcon className='text-accent' />
          <span className='text-accent text-14 ml-0.5'>
            <Translate value='JS_TRANDIS_EMAIL_BUTTON' />
          </span>
        </div>
        <div className='grow'></div>
        <div onClick={downloadPDF} className='cursor-pointer'>
          <LocalPrintshopOutlinedIcon className='text-accent' />
          <span className='text-accent text-14 ml-0.5'>
            <Translate value='JS_TRANDIS_PRINT_BUTTON' />
          </span>
        </div>
        <div className='grow'></div>
      </div>
      <div className='border-b border-divider mt-2 m-auto'></div>
      <div className='flex justify-between m-auto mt-2  w-[90%]'>
        <Button
          className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
          variant='contained'
          onClick={goToChoosePayments}
          color='secondary'
        >
          <span className='text-secondary'>
            <Translate value='JS_TRANDIS_CANCEL_BUTTON' />
          </span>
        </Button>

        <Button
          className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
          variant='contained'
          onClick={() => submit()}
        >
          <span>
            <Translate value='JS_TRANDIS_PAY_NOW_BUTTON' />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default TransactionDisclosure;
