import { Translate } from '../../i18n/translate';

const InputWithLabel = ({ children, required, label, error }) => {
  return (
    <div className='mb-3'>
      <label className={`text-sm mb-1 block ${error ? 'text-error' : ''}`}>
        {<Translate value={label} />}
        {required && <span>*</span>}
      </label>
      {children}
    </div>
  );
};

export default InputWithLabel;
