import { useEffect } from 'react';
import { STATUSES } from '../constants';

const TrustlyNA = ({ trustlySdkUrl, trustlyDataView, showReceipt }) => {
  useEffect(() => {
    const shadowRoot = document.getElementById('convera-payment');
    if (!shadowRoot) {
      console.log('This component will only render in shadow root');
      return;
    }
    const trustlyScript = createScript(trustlySdkUrl, false, handleScriptLoad);
    shadowRoot.appendChild(trustlyScript);
  }, []);

  const createScript = (src, defer = false, onLoadCallback) => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = defer;

    if (onLoadCallback) {
      script.onload = onLoadCallback;
    }
    return script;
  };

  const handleScriptLoad = () => {
    let trustlyOptions = {
      closeButton: false,
      dragAndDrop: true,
      widgetContainerId: 'widget',
    };

    setTimeout(() => {
      Trustly.establish({ ...trustlyDataView }, trustlyOptions);
      Trustly.addPanelListener((command, obj) => {
        switch (command) {
          case 'event':
            switch (obj.type) {
              case 'close':
                Trustly.removePanelListener();
                break;
              case 'new_location':
                obj.preventDefault();
                obj.stopPropagation();

                if (obj.data.includes('success=true')) {
                  showReceipt(STATUSES.SUCCESS);
                } else {
                  showReceipt(STATUSES.FAILED);
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      });
    }, 10);
  };

  return <div></div>;
};

export default TrustlyNA;
