import { Tab, Tabs } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Footer } from './components/Footer';
import { GenericError } from './components/GenericError';
import GenericModal from './components/GenericModal';
import { Loader } from './components/Loader';
import PaymentSummary from './components/PaymentSummary';
import Tracker from './components/PaymentTracker';
import CStepper from './components/Stepper';
import ChoosePaymentMethod from './modules/ChoosePaymentMethod';
import ConsolidatedPage from './modules/ConsolidatedPage';
import Landing from './modules/Landing';
import PayerDetail from './modules/PayerDetail';
import { PaymentPage } from './modules/PaymentPage';
import { OrderReceipt } from './modules/PaymentPage/orderReceipt';
import ServiceCatalog from './modules/ServiceCatalog';
import StudentDetail from './modules/StudentDetail';
import {
  ROUTE_MAPPING,
  routeName,
  selectHideUI,
  selectRoutesActive,
} from './store/slices/router/routerSlice';
import {
  selectDispayParentLogo,
  selectError,
  selectTrackingDisplay,
} from './store/slices/session/sessionSlice';
import { useScript } from './utils/helper';
import { imagePath } from './utils/imgHelper';

const AppRouter = ({ themeObject }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [trackingScriptLoaded, setTrackingScriptLoaded] = useState(false);
  const route = useSelector(routeName);
  const error = useSelector(selectError);
  const enableTracking = useSelector(selectTrackingDisplay);
  const componentRef = useRef();
  const hideUI = useSelector(selectHideUI);
  const routesActive = useSelector(selectRoutesActive);
  const dispayParentLogo = useSelector(selectDispayParentLogo);

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [route, componentRef]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useScript(
    'https://payment-tracking-ui-qa1.convera.com/static/js/main.js',
    () => {
      setTrackingScriptLoaded(true);
    },
    enableTracking,
  );

  return !hideUI ? (
    <div className='bg-white relative' ref={componentRef}>
      {enableTracking && (
        <div className='p-2'>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label='Make a Payment' />
            <Tab label='Track Payment / Refund' />
          </Tabs>
        </div>
      )}

      <div className='p-2'>
        {activeTab === 0 ? (
          <>
            <GenericModal />
            <CStepper />
            <Loader componentRef={componentRef} />
            <div className='min-h-[400px]'>
              {dispayParentLogo && (
                <div className='flex items-center justify-end mt-4'>
                  <img src={imagePath('convera-powered.svg')} className='w-28' />
                </div>
              )}
              {routesActive && <PaymentSummary />}
              {error != null ? (
                <GenericError />
              ) : (
                routesActive && (
                  <>
                    {route === ROUTE_MAPPING.LANDING && <Landing />}
                    {route === ROUTE_MAPPING.SERVICE_CATALOG && <ServiceCatalog />}
                    {route === ROUTE_MAPPING.PAYMENT_METHOD && <ChoosePaymentMethod />}
                    {route === ROUTE_MAPPING.BUYER_FROM && <StudentDetail />}
                    {route === ROUTE_MAPPING.PAYER_FORM && <PayerDetail />}
                    {route === ROUTE_MAPPING.COSOLIDATED && <ConsolidatedPage />}
                    {route === ROUTE_MAPPING.PAYMENT && <PaymentPage />}
                    {route === ROUTE_MAPPING.ORDER_RECEIPT && <OrderReceipt />}
                  </>
                )
              )}
            </div>
          </>
        ) : (
          <Tracker themeObject={themeObject} scriptLoaded={trackingScriptLoaded} />
        )}
      </div>
      <Footer />
    </div>
  ) : (
    <></>
  );
};

export default AppRouter;
