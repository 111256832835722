import { createTheme } from '@mui/material/styles';

export const createThemeForApp = (
  shadowRootElement,
  fontName,
  primaryColor,
  secondaryColor,
  primaryButtonColor,
) => {
  const theme = createTheme({
    colors: {
      textDefault: '#0A0337',
    },
    typography: {
      fontFamily: fontName,
      fontSize: 14,
    },
    allVariants: {
      fontFamily: fontName,
      fontSize: 14,
    },
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: fontName,
          fontSize: 14,
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '1em',
            textTransform: 'none',
            fontWeight: 700,
            '&.Mui-disabled': {
              backgroundColor: primaryColor,
              opacity: 0.9,
              color: primaryButtonColor,
            },
            lineHeight: 'normal',
            padding: '14px 24px',
            borderRadius: '9999px',
            color: primaryButtonColor,
            '&.MuiButton-containedSecondary': {
              border: '1px solid #000000',
              backgroundColor: '#ffffff',
              color: '#0A0337',
              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#0A0337',
              },
              '&.Mui-disabled': {
                opacity: 0.5,
              },
            },
          },
        },
      },

      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              // color: '#001E37',
              backgroundColor: 'rgba(201, 193, 249, 0.64)',
            },
            '&.Mui-selected:hover': {
              // color: '#001E37',
              backgroundColor: 'rgba(201, 193, 249, 0.64)',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            borderBottom: '1px solid #B6C0C4',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            textTransform: 'none',
            color: '#000000',
            '&.Mui-selected': {
              color: '#000000',
              fontWeight: 'bold',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },

      // MuiMenuItem: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-selected': {
      //         // color: '#001E37',
      //         backgroundColor: 'rgba(201, 193, 249, 0.64)',
      //       },
      //       '&.Mui-selected:hover': {
      //         // color: '#001E37',
      //         backgroundColor: 'rgba(201, 193, 249, 0.64)',
      //       },
      //     },
      //   },
      // },

      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '12.6px 14px', // Adjust the padding as needed
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            '&.Mui-error': {
              // borderWidth: '2px',
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '',
            borderColor: '#0A0337',
          },

          input: {
            backgroundColor: 'white',
            color: '#001E37',
            height: '0.9em',
            borderRadius: '0',
            borderColor: '#0A0337',
            fontSize: '14px',
            '&::before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)', // use your color
            },
            // '&.Mui-disabled': {
            //   backgroundColor: '#f0eceb',
            //   color: '#001E37',
            //   WebkitTextFillColor: '#001E37',
            // },
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.857em',
            color: '#0A0337',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '1em',
            color: '#0A0337',
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            // position: 'absolute',
            marginLeft: 0,
            fontSize: '0.857em',
            // bottom: -20,
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: '1em',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: '8px',
            borderRadius: '12px',
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          container: shadowRootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: shadowRootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: shadowRootElement,
        },
      },
    },

    palette: {
      primary: {
        main: primaryColor,
      },

      secondary: {
        main: secondaryColor,
      },
      error: {
        main: '#CB2E0C',
      },
    },
  });
  return theme;
};
