import { createSlice } from '@reduxjs/toolkit';

export const ROUTE_MAPPING = {
  LANDING: 'LANDING',
  SERVICE_CATALOG: 'SERVICE_CATALOG',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  COSOLIDATED: 'COSOLIDATED',
  BUYER_FROM: 'BUYER_FROM',
  PAYER_FORM: 'PAYER_FORM',
  PAYMENT: 'PAYMENT',
  ORDER_RECEIPT: 'ORDER_RECEIPT',
};
const routerSlice = createSlice({
  name: 'router',
  initialState: {
    index: 0,
    hidePaymentSummary: false,
    routesActive: false /* This flag will activate routes */,
    consolidtedFlow: false,
    routes: [],
    showStepper: false,
    hideUI: false,
    enableLanding: false,
  },
  reducers: {
    navigate: (state, action) => {
      state.index = state.routes.indexOf(action.payload.route);
      const consolidtedIndex = state.routes.indexOf(ROUTE_MAPPING.COSOLIDATED);
      if (state.index == consolidtedIndex) {
        state.consolidtedFlow = true;
      }
    },
    navigateBack: (state) => {
      state.index = state.index - 1;
    },
    showPaymentSummaryAction: (state) => {
      state.hidePaymentSummary = false;
    },
    hidePaymentSummaryAction: (state) => {
      state.hidePaymentSummary = true;
    },
    setEnableLanding: (state, action) => {
      state.enableLanding = action.payload;
    },
    activateRoutes: (state, action) => {
      state.routesActive = action.payload;
    },
    setRoutes: (state, action) => {
      let routesMapping = Object.keys(ROUTE_MAPPING);
      if (action.payload.hideServiceCatalog) {
        routesMapping = routesMapping.filter((r) => r != ROUTE_MAPPING.SERVICE_CATALOG);
      }
      if (!action.payload.enableLanding) {
        routesMapping = routesMapping.filter((r) => r != ROUTE_MAPPING.LANDING);
      }
      if (action.payload.consolBuyerRemitterPageEnabled) {
        routesMapping = routesMapping.filter(
          (r) => r !== ROUTE_MAPPING.BUYER_FROM && r !== ROUTE_MAPPING.PAYER_FORM,
        );
      } else {
        routesMapping = routesMapping.filter((r) => r !== ROUTE_MAPPING.COSOLIDATED);
      }
      state.routes = routesMapping;
    },

    setShowStepper: (state, action) => {
      state.showStepper = action.payload;
    },

    setHideUI: (state, action) => {
      state.hideUI = action.payload;
    },
  },
});

export const {
  navigate,
  navigateBack,
  showPaymentSummaryAction,
  hidePaymentSummaryAction,
  setEnableLanding,
  activateRoutes,
  setRoutes,
  setShowStepper,
  setHideUI,
} = routerSlice.actions;
export const routeName = (state) => state.router.routes[state.router.index];
export const routeIndex = (state) => state.router.index;
export const selectHidePaymentSummary = (state) => state.router.hidePaymentSummary;
export const selectRoutesActive = (state) => state.router.routesActive;
export const selectRoutes = (state) => state.router.routes;
export const showStepper = (state) => state.router.showStepper;
export const selectHideUI = (state) => state.router.hideUI;
export default routerSlice.reducer;
