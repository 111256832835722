export const STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  COMPLIANCE_FAILED: 'COMPLIANCE_FAILED',
  RATE_EXPIRED: 'RATE_EXPIRED',
};

export const MODES = {
  RECEIPT: 'RECEIPT',
  OFFLINE: 'OFFLINE_PAYMENT_INSTRUCTIONS',
  DISCLOSURE: 'TRANSACTION_DISCLOSURE',
  WORLD_PAY: 'WORLD_PAY',
  CURRENCY_SELECT: 'CURRENCY_SELECT',
  UNLIMINT: 'UNLIMINT',
  FLUTTERWAVE: 'FLUTTERWAVE',
  ALIPAY: 'ALIPAY',
  REDIRECT: 'REDIRECT',
  TRUSTLY_NA: 'TRUSTLY_NA',
  EMBEDDED: 'EMBEDDED',
  KLARNA: 'KLARNA',
};

export const PAYMENT_DEAL_STATUSES = {
  TRUSTLYEU_GP2: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AWAITING_PAYMENT'],
  },
  iDEAL_GP2: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AWAITING_PAYMENT'],
  },
  ECOSPEND_GP2: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AWAITING_PAYMENT'],
  },
  SOFORT_GP2: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AWAITING_PAYMENT'],
  },
  ALIPAY_DIRECT_CHINA: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AUTHORISED', 'DEAL_AWAITING_PAYMENT'],
  },
  POLI_AUS_GP2: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED', 'DEAL_AUTH_FAILED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AUTHORISED', 'DEAL_AWAITING_PAYMENT'],
  },
  POLI_NZ_GP2: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED', 'DEAL_AUTH_FAILED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AUTHORISED', 'DEAL_AWAITING_PAYMENT'],
  },
  ICICI_DIGITAL: {
    FAILED: ['DEAL_DECLINED', 'DEAL_CANCELLED', 'DEAL_AUTH_FAILED'],
    PROCESSING: ['DEAL_AWAITING_AUTH'],
    SUCCESS: ['DEAL_AUTHORISED', 'DEAL_AWAITING_PAYMENT'],
  },
};
