import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Cf, Nf, RawHtmlContent } from '../../components/Format';
import { Translate } from '../../i18n/translate';
import { showModalWithFunctions } from '../../store/slices/modal/actions';
import { selectRefDataCache } from '../../store/slices/refdata/refdataSlice';
import { selectLoading } from '../../store/slices/session/sessionSlice';
import { getConfig } from '../../utils/helper';
import {
  getAmount,
  getCurrency,
  getHomeAmountAndFee,
  getHomeCurrency,
} from '../../utils/quoteService';

const HowItWorks = ({ showDetails }) => {
  return (
    <p className='mt-3 text-center'>
      <Button
        variant='text'
        className='text-base text-accent font-medium'
        onClick={() => showDetails()}
      >
        <InfoOutlinedIcon className='text-accent mr-1' />
        <Translate value={'JS_HOW_IT_WORKS'} />
      </Button>
    </p>
  );
};

const PaymentMethodCard = ({ quote, onSelect, flexDir }) => {
  const iconSrc = `${process.env.REACT_APP_BUYER_URL}/geo-buyer/_assets/logo?asset=${quote.paymentType.id}_LOGO`;
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const refDataCache = useSelector(selectRefDataCache);
  const isAPIInProgress = useSelector(selectLoading);

  const HowItWorksContent = () => (
    <RawHtmlContent htmlContent={'JS_' + quote.paymentType.id + '_HOW_IT_WORKS'} />
  );

  const showModal = () => {
    dispatch(
      showModalWithFunctions({
        title: intl.formatMessage({ id: 'JS_HOW_IT_WORKS' }),
        body: <HowItWorksContent />,
        yesLabel: intl.formatMessage({ id: 'JS_OK' }),
      }),
    );
  };
  const isHowItWorksPresent = () => {
    const message = intl.formatMessage({ id: 'JS_' + quote.paymentType.id + '_HOW_IT_WORKS' });
    return !!message && message != 'JS_' + quote.paymentType.id + '_HOW_IT_WORKS';
  };

  return (
    <>
      <div className={`flex ${flexDir === 'row' ? 'flex-row' : 'flex-col'}`}>
        {/* Card header */}
        <div
          className={`text-center bg-gray3 py-5 px-5 border-b border-b-gray4 ${
            flexDir === 'row' ? 'flex-[1_0_50%] max-w-[50%]' : 'pb-8'
          } flex items-center justify-center flex-col`}
        >
          <div className='flex items-center justify-center'>
            <div className='inline-block bg-white mr-3'>
              <img src={iconSrc} className='h-7' alt={quote.paymentType.id} />
            </div>
            <h4 className='text-22 inline-block'>
              <Translate value={quote.paymentType.id + '-LABEL'} />
            </h4>
          </div>

          <div className='flex items-center justify-center mt-3'>
            <div className='mr-4'>
              <p className='text-16-bold font-mono text-right'>
                {Nf(getAmount(quote))} {Cf(getCurrency(quote))}
              </p>
              <p className='text-16 font-mono text-right'>
                {Nf(getHomeAmountAndFee(quote, getConfig(refDataCache)))}{' '}
                {Cf(getHomeCurrency(quote))}
              </p>
            </div>
            <Button
              variant='contained'
              onClick={onSelect}
              className='text-14 !py-3 text-14'
              disabled={isAPIInProgress}
            >
              <Translate value='JS_QUOTE_SELECT' />
            </Button>
          </div>
        </div>

        {/* Card content */}
        <div className={`px-8 py-4 ${flexDir !== 'col' ? 'pb-8' : ''}`}>
          {flexDir !== 'row' && (
            <>
              <div className='text-center mt-[-30px]'>
                <Button
                  variant='text'
                  className='font-medium text-accent bg-white p-1 rounded'
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <span>{showDetails ? '-' : '+'}</span>&nbsp; <Translate value={'JS_DETAILS'} />
                </Button>
              </div>
              <div className={`accordion-content ${showDetails ? 'open' : ''}`}>
                <RawHtmlContent
                  htmlContent={'JS_' + quote.paymentType.id + '_PAYMENT_TYPE'}
                  translateValues={{
                    expiry: quote.expiry,
                    feeCcy: getCurrency(quote),
                    fee: quote.fee,
                  }}
                />
                {quote.configuredGP2Fee && (
                  <RawHtmlContent
                    htmlContent={'JS_' + quote.paymentType.id + '_PAYMENT_TYPE_FEE'}
                    translateValues={{
                      settlementCurrencyFee: quote.settlementCurrencyFee,
                      homeCcy: getHomeCurrency(quote),
                    }}
                  />
                )}
                {isHowItWorksPresent() && <HowItWorks showDetails={showModal} />}
              </div>
            </>
          )}

          {flexDir == 'row' && (
            <div className='accordion-content open'>
              <RawHtmlContent
                htmlContent={'JS_' + quote.paymentType.id + '_PAYMENT_TYPE'}
                translateValues={{
                  expiry: quote.expiry,
                  feeCcy: getCurrency(quote),
                  fee: quote.fee,
                }}
              />
              {quote.configuredGP2Fee && (
                <RawHtmlContent
                  htmlContent={'JS_' + quote.paymentType.id + '_PAYMENT_TYPE_FEE'}
                  translateValues={{
                    settlementCurrencyFee: quote.settlementCurrencyFee,
                    homeCcy: getHomeCurrency(quote),
                  }}
                />
              )}
              {isHowItWorksPresent() && <HowItWorks showDetails={showModal} />}
            </div>
          )}
        </div>
      </div>
      <hr className={`border-gray5 my-5 ${flexDir === 'col' ? 'hidden' : ''}`}></hr>
    </>
  );
};

export default PaymentMethodCard;
