import { combineReducers } from '@reduxjs/toolkit';
import modalSlice from './slices/modal/modalSlice';
import refdataSlice from './slices/refdata/refdataSlice';
import routerSlice from './slices/router/routerSlice';
import sessionSlice from './slices/session/sessionSlice';

const combinedReducer = combineReducers({
  refdata: refdataSlice,
  router: routerSlice,
  modal: modalSlice,
  session: sessionSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'session/resetState') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;