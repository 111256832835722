import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectConsolBuyerRemitterPageEnabled,
  selectLoading,
} from '../../store/slices/session/sessionSlice';

export const Loader = ({ componentRef }) => {
  const isLoading = useSelector(selectLoading);
  const [top, setTop] = useState(0);
  const consolBuyerRemitterPageEnabled = useSelector(selectConsolBuyerRemitterPageEnabled);
  useEffect(() => {
    const updateLoaderPosition = () => {
      if (componentRef.current) {
        const rect = componentRef.current.getBoundingClientRect();
        if (rect.top >= 0) {
          if (500 > rect.height) setTop(rect.height / 2);
          else setTop(400);
        } else {
          if (consolBuyerRemitterPageEnabled) {
            setTop((rect.height * 4) / 5);
          } else {
            setTop((rect.height * 2) / 3);
          }
        }
      }
    };
    window.addEventListener('scroll', updateLoaderPosition);
    updateLoaderPosition();

    return () => {
      window.removeEventListener('scroll', updateLoaderPosition);
    };
  }, [isLoading, componentRef]);

  if (!isLoading) return null;
  return (
    <CircularProgress
      className='absolute z-50 overflow-hidden left-0 right-0 m-auto'
      style={{
        top: top + 'px',
      }}
    />
  );
};
