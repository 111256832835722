import { jwtDecode } from 'jwt-decode';
import { eventBus } from './events/eventBus';
import { EPS_EVENT } from './events/eventTypes';

let tokenExpiryTimeoutId = null;
const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const checkTokenExpiry = (token) => {
  if (!token) return;

  if (tokenExpiryTimeoutId) {
    clearTimeout(tokenExpiryTimeoutId);
    tokenExpiryTimeoutId = null;
  }

  if (isTokenExpired(token)) {
    eventBus.emit(EPS_EVENT.TOKEN_EXPIIRED, {
      source: 'EPS_CONVERA',
      timestamp: new Date().getTime(),
      eventType: EPS_EVENT.TOKEN_EXPIIRED,
    });
  } else {
    const decodedToken = jwtDecode(token);
    const timeUntilExpiry = decodedToken.exp * 1000 - Date.now();
    tokenExpiryTimeoutId = setTimeout(() => {
      console.debug('Token expired');
      eventBus.emit(EPS_EVENT.TOKEN_EXPIIRED, {
        source: 'EPS_CONVERA',
        timestamp: new Date().getTime(),
        eventType: EPS_EVENT.TOKEN_EXPIIRED,
      });
      tokenExpiryTimeoutId = null;
    }, timeUntilExpiry);
  }
};
