/** @jsxImportSource react */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { routeIndex, selectRoutes, showStepper } from '../../store/slices/router/routerSlice';
import { selectPaymentFlowComplete } from '../../store/slices/session/sessionSlice';
import './Stepper.scss';

function CStepper() {
  const routes = useSelector(selectRoutes);
  const [steps, setSteps] = useState([]);
  const _showStepper = useSelector(showStepper);
  const routerIndex = useSelector(routeIndex);
  const paymentFlowComplete = useSelector(selectPaymentFlowComplete);

  useEffect(() => {
    /* total step is five 
    And with conditions like hideservice catalog, or consoldited flow the steps is reduced from five steps.
    check routerslice
    */
    setSteps(routes.slice(0, routes.length - 1));
  }, [routes]);

  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${steps.length}, 1fr)`,
    gap: '0.25rem',
  };

  return (
    <>
      {_showStepper && (
        <div style={gridStyles}>
          {steps.map((key, index) => (
            <div
              key={index}
              className={`rounded  h-4 ${
                routerIndex == index && !paymentFlowComplete
                  ? 'bg-successTint'
                  : index < routerIndex || paymentFlowComplete
                  ? 'bg-success'
                  : 'bg-gray2'
              }`}
            ></div>
          ))}
        </div>
      )}
    </>
  );
}

export default CStepper;
