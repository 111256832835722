import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useRef, useState } from 'react';
import { getTxnStatus } from '../../apis';
import { PAYMENT_DEAL_STATUSES, STATUSES } from './constants';
import './paymentPartnerCheckout.scss';

const findReceiptStatus = (paymentType, transactionStatus) => {
  if (PAYMENT_DEAL_STATUSES[paymentType]?.SUCCESS.includes(transactionStatus)) {
    return STATUSES.SUCCESS;
  } else if (PAYMENT_DEAL_STATUSES[paymentType]?.FAILED.includes(transactionStatus)) {
    return STATUSES.FAILED;
  } else if (PAYMENT_DEAL_STATUSES[paymentType]?.PROCESSING.includes(transactionStatus)) {
    return STATUSES.PROCESSING;
  } else return '';
};

const PaymentPartnerCheckout = ({ session, showReceipt, extURL }) => {
  const [timeUp, setTimeUp] = useState(false);
  const formRef = useRef(null);
  const pspFormUrl = session.pledge.pspConnectionUrl;
  const pspFormMethod = session.pledge.httpMethod;
  const pspFormData = session.pledge.pspData;
  const pollingOnRedirect = session.selectedQuote.paymentType.pollingOnRedirect;

  useEffect(() => {
    let countdown = 300;
    let timeOutList = [];

    let statusRequest = {
      dealReference: session.pledge.dealReference,
      paymentType: session.selectedQuote.paymentType.id,
    };

    const fetchTransactionStatus = async (statusRequest, showReceipt, timeOutList) => {
      const response = await getTxnStatus(statusRequest);
      if (response.success) {
        let txnStatusResponse = response.data;
        let status = findReceiptStatus(
          statusRequest.paymentType,
          txnStatusResponse.data.transactionStatus,
        );
        if (txnStatusResponse.data.notificationReceived && status) {
          setTimeUp(true);
          timeOutList.forEach((value) => {
            clearTimeout(value);
          });
          showReceipt(status);
        }
      }
    };

    const updateCountDown = () => {
      countdown--;
      if (countdown > 0) {
        let tid = setTimeout(updateCountDown, 1000);
        timeOutList.push(tid);
        if (countdown % 5 === 0) {
          fetchTransactionStatus(statusRequest, showReceipt, timeOutList);
        }
      } else if (countdown === 0) {
        setTimeUp(true);
        timeOutList.forEach((value) => {
          clearTimeout(value);
        });
        showReceipt(STATUSES.PROCESSING);
      }
    };

    if (pollingOnRedirect && !timeUp) {
      updateCountDown();
    }

    if (!pollingOnRedirect) {
      if (pspFormUrl) {
        formRef.current.submit();
      }
    }

    return () => {
      console.debug('Clear timeout when unmount');
      timeOutList.forEach((value) => {
        clearTimeout(value);
      });
    };
  }, []);

  return (
    <div className='pt-3 pb-3  max-w-[750px] m-auto'>
      <div className='mt-1 mb-2'>
        <h4 className='text-16-bold mt-3'> </h4>
        <div className='ppc-info-container'>
          <InfoOutlinedIcon className='mr-4' />
          {/* TODO: Ul > li, will come as translations */}
          <ul>
            <li>
              <b>Please complete your payment in the new window that has automatically opened.</b>{' '}
              After completing your payment, return to this screen to view your receipt.
              <br />
              {(extURL || (!pollingOnRedirect && pspFormUrl)) && (
                <p>
                  If you have a pop-up blocker enabled, please{' '}
                  {extURL ? (
                    <a
                      className='a-bold mr-0.5'
                      href={`${extURL}`}
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      click here
                    </a>
                  ) : (
                    <a
                      className='a-bold mr-0.5'
                      onClick={() => {
                        formRef.current.submit();
                      }}
                    >
                      click here
                    </a>
                  )}
                  to open the new window to complete your payment.
                </p>
              )}
            </li>
          </ul>
          {!extURL && pspFormUrl ? (
            <form
              ref={formRef}
              id={'pspForm'}
              target={'_blank'}
              action={pspFormUrl}
              method={pspFormMethod}
            >
              {Object.keys(pspFormData).map((name, index) => (
                <input key={index} type='hidden' name={name} value={pspFormData[name]}></input>
              ))}
            </form>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPartnerCheckout;
