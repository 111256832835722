export const RELATIONS_TO_STUDENT_OTHER = [
  {
    value: 'FAMILY',
    displayName: 'JS_PAYER_FAMILY',
    renderCondition: true,
  },
  {
    value: 'AGENT',
    displayName: 'JS_PAYER_AGENT',
    renderCondition: 'model.paymentType.id !== "GEOSWIFTUNIONPAY"',
  },
  {
    value: 'ORGANIZATION',
    displayName: 'JS_PAYER_ORG',
    renderCondition: 'model.paymentType.id !== "GEOSWIFTUNIONPAY"',
  },
  {
    value: 'SPONSOR',
    displayName: 'JS_PAYER_SPONSOR',
    renderCondition: 'model.paymentType.id !== "GEOSWIFTUNIONPAY"',
  },
];

export const RELATIONS_TO_STUDENT_VP_BANK = [
  {
    value: 'BIOLOGICAL_PARENT',
    displayName: 'JS_PAYER_BIOLOGICAL_PARENT',
    renderCondition: true,
  },
  {
    value: 'PARENT_INLAW',
    displayName: 'JS_PAYER_PARENT_INLAW',
    renderCondition: true,
  },
  {
    value: 'SPOUSE',
    displayName: 'JS_PAYER_SPOUSE',
    renderCondition: true,
  },
  {
    value: 'BIOLOGICAL_CHILD',
    displayName: 'JS_PAYER_BIOLOGICAL_CHILD',
    renderCondition: true,
  },
  {
    value: 'ADOPTED_PARENT_OR_CHILD',
    displayName: 'JS_PAYER_ADOPTED_PARENT_OR_CHILD',
    renderCondition: true,
  },
  {
    value: 'CHILD_INLAW',
    displayName: 'JS_PAYER_CHILD_INLAW',
    renderCondition: true,
  },
  {
    value: 'BIOLOGICAL_SIBLING',
    displayName: 'JS_PAYER_BIOLOGICAL_SIBLING',
    renderCondition: true,
  },
  {
    value: 'LEGAL_REPRESENTATIVE',
    displayName: 'JS_PAYER_LEGAL_REPRESENTATIVE',
    renderCondition: true,
  },
  {
    value: 'MYSELF',
    displayName: 'JS_STUDENT_LABEL',
    renderCondition: true,
  },
];

export const ATTORNEY_APPOINTER_OPTIONS = [
  {
    value: 'STUDENT_APPOINTED',
    displayName: 'JS_STUDENT_APPOINTED',
  },
  {
    value: 'PARENT_APPOINTED',
    displayName: 'JS_PARENT_APPOINTED',
  },
];

export const ADDRESS_PROOF_DOC_OPTIONS_VP_BANK = [
  {
    value: 'PASSPORT',
    displayName: 'Passport',
    noteText: 'JS_PASSPORT_NOTE_TEXT',
  },
  {
    value: 'CITIZEN_ID_CARD',
    displayName: 'Citizen Identity Card',
    noteText: 'JS_CITIZEN_ID_NOTE_TEXT',
  },
];

export const ADDRESS_PROOF_DOC_OPTIONS_INR_BANK = [
  {
    value: 'PASSPORT',
    displayName: 'Passport',
    noteText: 'JS_PASSPORT_NOTE_TEXT',
    showExpiryField: true, //TODO: Hide and show show expiry field based on this key
    dateExpiredError: 'JS_PASSPORT_EXPIRY_ERROR', //TODO: Date expired error should be shown based on this key
  },
  {
    value: 'DRIVER_LICENSE',
    displayName: 'JS_DRIVERS_LICENSE',
    noteText: 'JS_DRIVERS_NOTE_TEXT',
    showExpiryField: true,
    dateExpiredError: 'JS_DRIVERS_EXPIRY_ERROR',
  },
  {
    value: 'UTILITY_BANK',
    displayName: 'Utility bills',
    noteText: 'JS_BILLS_NOTE_TEXT',
    showExpiryField: false,
    dateExpiredError: '',
  },
];

export const CAML_DOC_TYPES = [
  {
    value: 'Driving License Number',
    displayName: 'JS_DOC_DRIVING_LICENSE',
  },
  {
    value: 'Government ID',
    displayName: 'JS_DOC_GOV_ID',
  },
  {
    value: 'National Identification Reference',
    displayName: 'JS_DOC_NATIONAL_ID',
  },
  {
    value: 'Passport Number',
    displayName: 'JS_DOC_PASSPORT',
  },
  {
    value: 'Tax ID',
    displayName: 'JS_DOC_TAX_ID',
  },
  {
    value: 'Other',
    displayName: 'JS_DOC_OTHER',
  },
];

export const CAML_ACCNT_TYPES = [
  {
    value: 'Checking',
    displayName: 'JS_ACCOUNT_CHECKING',
  },
  {
    value: 'Savings',
    displayName: 'JS_ACCOUNT_SAVINGS',
  },
  {
    value: 'Other',
    displayName: 'JS_ACCOUNT_OTHER',
  },
];

export const SESSSION_VP = {
  clientId: '1000473340',
  sellerInfo: {
    sellerId: '1000473340',
    paymentTypes: ['ICICI', 'INR_BANK_ACCOUNT', 'WIRE', 'VPBank_VND'],
    currencies: [
      'CHF',
      'FJD',
      'MXN',
      'QAR',
      'ZAR',
      'TND',
      'XPF',
      'AUD',
      'ILS',
      'JPY',
      'PLN',
      'GBP',
      'HUF',
      'KWD',
      'TRY',
      'LBP',
      'JOD',
      'AED',
      'HKD',
      'EUR',
      'DKK',
      'CAD',
      'USD',
      'NOK',
      'NAD',
      'MAD',
      'SGD',
      'CZK',
      'OMR',
      'KES',
      'SEK',
      'NZD',
      'WST',
      'CNH',
      'BHD',
    ],
    debtorPortalBrandingView: null,
    introductionText: null,
    marketingText: null,
    contactUsText: null,
    footerText: null,
    contactEmail: null,
    phoneNumber: null,
    homeCurrency: 'USD',
    industrySegment: 'EDUCATION',
    country: 'USA',
    address: 'Howie Street',
    city: 'Howkle',
    state: 'NY',
    zip: '345345',
    legalEntityId: '1000004601',
    legalEntityDisclaimer: {
      disclaimerTextViews: [],
    },
    wubsMarketingEnabled: true,
    name: 'VPBank',
    expiry: 72,
    sellerIndustry: 'EDUCATION',
    captchaEnabled: false,
    remitterFieldMetadata: {
      fieldMetadata: [
        {
          key: {
            fieldName: 'payerName',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: true,
        },
        {
          key: {
            fieldName: 'payerAddress',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: true,
        },
        {
          key: {
            fieldName: 'payerAddress2',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerCity',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: true,
        },
        {
          key: {
            fieldName: 'payerState',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerZip',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerCountry',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: true,
        },
        {
          key: {
            fieldName: 'payerAccountNumber',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerBankName',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: true,
        },
        {
          key: {
            fieldName: 'payerBankAddress',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerBankAddress2',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerBankCity',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerBankState',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerBankZip',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: false,
        },
        {
          key: {
            fieldName: 'payerBankCountry',
            legalEntityId: 1000004601,
          },
          fieldVisible: true,
          fieldMandatory: true,
        },
      ],
    },
    complianceMandatoryFields: [],
    contactLinkEnabled: false,
    supportContactEmail: null,
    defaultLanguage: 'en_GB',
    gpxRemitterEnabled: false,
    isMcpCreditCardEnabled: false,
    payerCountries: [
      'NZL',
      'FJI',
      'PNG',
      'GLP',
      'MHL',
      'WLF',
      'GMB',
      'MYS',
      'MYT',
      'TWN',
      'POL',
      'OMN',
      'RCA',
      'SUR',
      'ARE',
      'KEN',
      'ARG',
      'GNB',
      'ARM',
      'UZB',
      'BTN',
      'SEN',
      'TGO',
      'IRL',
      'FLK',
      'QAT',
      'BDI',
      'NLD',
      'IRQ',
      'SVK',
      'SVN',
      'GNQ',
      'THA',
      'ABW',
      'ASM',
      'SWE',
      'ISL',
      'MKD',
      'BEL',
      'ISR',
      'KWT',
      'LIE',
      'DZA',
      'BEN',
      'ATA',
      'RUS',
      'ATF',
      'ATG',
      'ITA',
      'SWZ',
      'TZA',
      'PAK',
      'BFA',
      'PAN',
      'SGP',
      'UKR',
      'SGS',
      'JEY',
      'KGZ',
      'CHE',
      'DJI',
      'REU',
      'CHL',
      'PRI',
      'CHN',
      'MLI',
      'BWA',
      'HRV',
      'KHM',
      'IDN',
      'PRT',
      'MLT',
      'TJK',
      'VNM',
      'CYM',
      'PRY',
      'SHN',
      'CYP',
      'SYC',
      'RWA',
      'BGD',
      'AUS',
      'AUT',
      'LKA',
      'PSE',
      'GAB',
      'ZWE',
      'BGR',
      'CZE',
      'NOR',
      'UMI',
      'CIV',
      'MMR',
      'TKL',
      'KIR',
      'TKM',
      'GRD',
      'GRC',
      'PCN',
      'HTI',
      'GRL',
      'YEM',
      'AFG',
      'MNE',
      'MNG',
      'NPL',
      'BHS',
      'BHR',
      'MNP',
      'GBR',
      'SJM',
      'DMA',
      'TLS',
      'BIH',
      'HUN',
      'AGO',
      'WSM',
      'FRA',
      'MOZ',
      'NAM',
      'PER',
      'DNK',
      'GTM',
      'FRO',
      'SLB',
      'VAT',
      'SLE',
      'NRU',
      'AIA',
      'GUF',
      'SLV',
      'GUM',
      'FSM',
      'DOM',
      'CMR',
      'GUY',
      'AZE',
      'MAC',
      'GEO',
      'TON',
      'NCL',
      'SMR',
      'ERI',
      'KNA',
      'MAR',
      'VCT',
      'BLR',
      'BLZ',
      'PHL',
      'COD',
      'ESH',
      'PYF',
      'URY',
      'COK',
      'COM',
      'COL',
      'USA',
      'ESP',
      'EST',
      'BMU',
      'MSR',
      'ZMB',
      'KOR',
      'SOM',
      'VUT',
      'ECU',
      'ALB',
      'ETH',
      'GGY',
      'MCO',
      'NER',
      'LAO',
      'VEN',
      'GHA',
      'CPV',
      'MDA',
      'MTQ',
      'MDG',
      'SPM',
      'NFK',
      'LBN',
      'LBR',
      'BOL',
      'MDV',
      'GIB',
      'LBY',
      'HKG',
      'LSO',
      'NGA',
      'MUS',
      'IMN',
      'LCA',
      'JOR',
      'GIN',
      'VGB',
      'CAN',
      'TCA',
      'TCD',
      'AND',
      'ROU',
      'CRI',
      'IND',
      'MEX',
      'SRB',
      'KAZ',
      'SAU',
      'ANT',
      'JPN',
      'LTU',
      'TTO',
      'PLW',
      'HMD',
      'MWI',
      'SSD',
      'NIC',
      'CCK',
      'FIN',
      'TUN',
      'LUX',
      'UGA',
      'IOT',
      'BRA',
      'TUR',
      'BRB',
      'TUV',
      'DEU',
      'EGY',
      'LVA',
      'JAM',
      'NIU',
      'VIR',
      'ZAF',
      'BRN',
      'HND',
    ],
    payerFeeCountries: [
      'NZL',
      'FJI',
      'PNG',
      'GLP',
      'MHL',
      'WLF',
      'GMB',
      'MYS',
      'MYT',
      'TWN',
      'POL',
      'OMN',
      'RCA',
      'SUR',
      'ARE',
      'KEN',
      'ARG',
      'GNB',
      'ARM',
      'UZB',
      'BTN',
      'SEN',
      'TGO',
      'IRL',
      'FLK',
      'QAT',
      'BDI',
      'NLD',
      'IRQ',
      'SVK',
      'SVN',
      'GNQ',
      'THA',
      'ABW',
      'ASM',
      'SWE',
      'ISL',
      'MKD',
      'BEL',
      'ISR',
      'KWT',
      'LIE',
      'DZA',
      'BEN',
      'ATA',
      'RUS',
      'ATF',
      'ATG',
      'ITA',
      'SWZ',
      'TZA',
      'PAK',
      'BFA',
      'PAN',
      'SGP',
      'UKR',
      'SGS',
      'JEY',
      'KGZ',
      'CHE',
      'DJI',
      'REU',
      'CHL',
      'PRI',
      'CHN',
      'MLI',
      'BWA',
      'HRV',
      'KHM',
      'IDN',
      'PRT',
      'MLT',
      'TJK',
      'VNM',
      'CYM',
      'PRY',
      'SHN',
      'CYP',
      'SYC',
      'RWA',
      'BGD',
      'AUS',
      'AUT',
      'LKA',
      'PSE',
      'GAB',
      'ZWE',
      'BGR',
      'CZE',
      'NOR',
      'UMI',
      'CIV',
      'MMR',
      'TKL',
      'KIR',
      'TKM',
      'GRD',
      'GRC',
      'PCN',
      'HTI',
      'GRL',
      'YEM',
      'AFG',
      'MNE',
      'MNG',
      'NPL',
      'BHS',
      'BHR',
      'MNP',
      'GBR',
      'SJM',
      'DMA',
      'TLS',
      'BIH',
      'HUN',
      'AGO',
      'WSM',
      'FRA',
      'MOZ',
      'NAM',
      'PER',
      'DNK',
      'GTM',
      'FRO',
      'SLB',
      'VAT',
      'SLE',
      'NRU',
      'AIA',
      'GUF',
      'SLV',
      'GUM',
      'FSM',
      'DOM',
      'CMR',
      'GUY',
      'AZE',
      'MAC',
      'GEO',
      'TON',
      'NCL',
      'SMR',
      'ERI',
      'KNA',
      'MAR',
      'VCT',
      'BLR',
      'BLZ',
      'PHL',
      'COD',
      'ESH',
      'PYF',
      'URY',
      'COK',
      'COM',
      'COL',
      'USA',
      'ESP',
      'EST',
      'BMU',
      'MSR',
      'ZMB',
      'KOR',
      'SOM',
      'VUT',
      'ECU',
      'ALB',
      'ETH',
      'GGY',
      'MCO',
      'NER',
      'LAO',
      'VEN',
      'GHA',
      'CPV',
      'MDA',
      'MTQ',
      'MDG',
      'SPM',
      'NFK',
      'LBN',
      'LBR',
      'BOL',
      'MDV',
      'GIB',
      'LBY',
      'HKG',
      'LSO',
      'NGA',
      'MUS',
      'IMN',
      'LCA',
      'JOR',
      'GIN',
      'VGB',
      'CAN',
      'TCA',
      'TCD',
      'AND',
      'ROU',
      'CRI',
      'IND',
      'MEX',
      'SRB',
      'KAZ',
      'SAU',
      'ANT',
      'JPN',
      'LTU',
      'TTO',
      'PLW',
      'HMD',
      'MWI',
      'SSD',
      'NIC',
      'CCK',
      'FIN',
      'TUN',
      'LUX',
      'UGA',
      'IOT',
      'BRA',
      'TUR',
      'BRB',
      'TUV',
      'DEU',
      'EGY',
      'LVA',
      'JAM',
      'NIU',
      'VIR',
      'ZAF',
      'BRN',
      'HND',
    ],
    buyerCountries: [
      'NZL',
      'FJI',
      'PNG',
      'GLP',
      'MHL',
      'WLF',
      'GMB',
      'MYS',
      'MYT',
      'TWN',
      'POL',
      'OMN',
      'RCA',
      'SUR',
      'ARE',
      'KEN',
      'ARG',
      'GNB',
      'ARM',
      'UZB',
      'BTN',
      'SEN',
      'TGO',
      'IRL',
      'FLK',
      'QAT',
      'BDI',
      'NLD',
      'IRQ',
      'SVK',
      'SVN',
      'GNQ',
      'THA',
      'ABW',
      'ASM',
      'SWE',
      'ISL',
      'MKD',
      'BEL',
      'ISR',
      'KWT',
      'LIE',
      'DZA',
      'BEN',
      'ATA',
      'RUS',
      'ATF',
      'ATG',
      'ITA',
      'SWZ',
      'TZA',
      'PAK',
      'BFA',
      'PAN',
      'SGP',
      'UKR',
      'SGS',
      'JEY',
      'KGZ',
      'CHE',
      'DJI',
      'REU',
      'CHL',
      'PRI',
      'CHN',
      'MLI',
      'BWA',
      'HRV',
      'KHM',
      'IDN',
      'PRT',
      'MLT',
      'TJK',
      'VNM',
      'CYM',
      'PRY',
      'SHN',
      'CYP',
      'SYC',
      'RWA',
      'BGD',
      'AUS',
      'AUT',
      'LKA',
      'PSE',
      'GAB',
      'ZWE',
      'BGR',
      'CZE',
      'NOR',
      'UMI',
      'CIV',
      'MMR',
      'TKL',
      'KIR',
      'TKM',
      'GRD',
      'GRC',
      'PCN',
      'HTI',
      'GRL',
      'YEM',
      'AFG',
      'MNE',
      'MNG',
      'NPL',
      'BHS',
      'BHR',
      'MNP',
      'GBR',
      'SJM',
      'DMA',
      'TLS',
      'BIH',
      'HUN',
      'AGO',
      'WSM',
      'FRA',
      'MOZ',
      'NAM',
      'PER',
      'DNK',
      'GTM',
      'FRO',
      'SLB',
      'VAT',
      'SLE',
      'NRU',
      'AIA',
      'GUF',
      'SLV',
      'GUM',
      'FSM',
      'DOM',
      'CMR',
      'GUY',
      'AZE',
      'MAC',
      'GEO',
      'TON',
      'NCL',
      'SMR',
      'ERI',
      'KNA',
      'MAR',
      'VCT',
      'BLR',
      'BLZ',
      'PHL',
      'COD',
      'ESH',
      'PYF',
      'URY',
      'COK',
      'COM',
      'COL',
      'USA',
      'ESP',
      'EST',
      'BMU',
      'MSR',
      'ZMB',
      'KOR',
      'SOM',
      'VUT',
      'ECU',
      'ALB',
      'ETH',
      'GGY',
      'MCO',
      'NER',
      'LAO',
      'VEN',
      'GHA',
      'CPV',
      'MDA',
      'MTQ',
      'MDG',
      'SPM',
      'NFK',
      'LBN',
      'LBR',
      'BOL',
      'MDV',
      'GIB',
      'LBY',
      'HKG',
      'LSO',
      'NGA',
      'MUS',
      'IMN',
      'LCA',
      'JOR',
      'GIN',
      'VGB',
      'CAN',
      'TCA',
      'TCD',
      'AND',
      'ROU',
      'CRI',
      'IND',
      'MEX',
      'SRB',
      'KAZ',
      'SAU',
      'ANT',
      'JPN',
      'LTU',
      'TTO',
      'PLW',
      'HMD',
      'MWI',
      'SSD',
      'NIC',
      'CCK',
      'FIN',
      'TUN',
      'LUX',
      'UGA',
      'IOT',
      'BRA',
      'TUR',
      'BRB',
      'TUV',
      'DEU',
      'EGY',
      'LVA',
      'JAM',
      'NIU',
      'VIR',
      'ZAF',
      'BRN',
      'HND',
    ],
    isWebIntegrationFieldsIgnorable: false,
    isWebIntegrationFieldsEditable: false,
    webIntegrationEnabled: false,
    consolBuyerRemitterPageEnabled: false,
  },
  locale: 'en_GB',
  sso: false,
  serviceItemViewList: {
    totalRecordCount: 0,
    isMultiSettlementEnabled: false,
    serviceItemViews: [
      {
        id: 100000042222,
        clientId: '1000473340',
        serviceName: 'Tuition',
        displayServiceIdentifierFlag: false,
        serviceIdentifier: '1',
        description: 'Tuition fee',
        displayDescriptionFlag: true,
        status: 'ACTIVE',
        priority: 1,
        editable: true,
        maxAmount: null,
        minAmount: null,
        expiryDate: null,
        captureNotes: false,
        amountOwing: 500,
        displayAmountOwingFlag: true,
        debtorGroup: 'All',
        displayExpiryDate: false,
        serviceGroupCode1: null,
        serviceGroupCode2: null,
        serviceGroupCode3: null,
        serviceGroupDescription1: null,
        serviceGroupDescription2: null,
        serviceGroupDescription3: null,
        mandatory: false,
        wubsSettlementFee: false,
        providerClientId: null,
        providerClientName: null,
      },
      {
        id: 100000045851,
        clientId: '1000473340',
        serviceName: 'Training',
        displayServiceIdentifierFlag: false,
        serviceIdentifier: '2',
        description: 'Training',
        displayDescriptionFlag: true,
        status: 'ACTIVE',
        priority: 2,
        editable: true,
        maxAmount: null,
        minAmount: null,
        expiryDate: null,
        captureNotes: false,
        amountOwing: 1001,
        displayAmountOwingFlag: true,
        debtorGroup: 'All',
        displayExpiryDate: false,
        serviceGroupCode1: null,
        serviceGroupCode2: null,
        serviceGroupCode3: null,
        serviceGroupDescription1: null,
        serviceGroupDescription2: null,
        serviceGroupDescription3: null,
        mandatory: false,
        wubsSettlementFee: false,
        providerClientId: null,
        providerClientName: null,
      },
    ],
  },
  customFieldList: [
    {
      minDate: null,
      maxDate: null,
      name: 'businessId',
      type: 'TEXT',
      minLength: 0,
      maxLength: 10,
      width: 10,
      regex: null,
      values: [],
      mandatory: true,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'businessId_ERROR',
      helpKey: 'businessId_HELP',
      date: false,
      labelKey: 'businessId_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'firstName',
      type: 'TEXT',
      minLength: 0,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: true,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'firstName_ERROR',
      helpKey: 'firstName_HELP',
      date: false,
      labelKey: 'firstName_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'lastName',
      type: 'TEXT',
      minLength: 0,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: true,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'lastName_ERROR',
      helpKey: 'lastName_HELP',
      date: false,
      labelKey: 'lastName_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'address',
      type: 'TEXT',
      minLength: 0,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: true,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'address_ERROR',
      helpKey: 'address_HELP',
      date: false,
      labelKey: 'address_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'city',
      type: 'TEXT',
      minLength: 0,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: false,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'city_ERROR',
      helpKey: 'city_HELP',
      date: false,
      labelKey: 'city_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'state',
      type: 'TEXT',
      minLength: 0,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: false,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'state_ERROR',
      helpKey: 'state_HELP',
      date: false,
      labelKey: 'state_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'zip',
      type: 'TEXT',
      minLength: 0,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: false,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: false,
      country: false,
      errorKey: 'zip_ERROR',
      helpKey: 'zip_HELP',
      date: false,
      labelKey: 'zip_LABEL',
      text: true,
    },
    {
      minDate: null,
      maxDate: null,
      name: 'email',
      type: 'EMAIL',
      minLength: 7,
      maxLength: 40,
      width: 40,
      regex: null,
      values: [],
      mandatory: true,
      layoutHint: null,
      validationId: null,
      scriptBasedValidation: false,
      select: false,
      checkbox: false,
      radio: false,
      email: true,
      country: false,
      errorKey: 'email_ERROR',
      helpKey: 'email_HELP',
      date: false,
      labelKey: 'email_LABEL',
      text: false,
    },
  ],
  selectedServices: {
    country: 'VNM',
    debtorGroup: 'All',
    services: [
      {
        id: 100000045851,
        pledgedAmount: 1001,
        notes: null,
        providerClientId: null,
        priority: 2,
      },
    ],
  },
  selectedQuote: {
    quoteId: null,
    buyerAmount: 27346660,
    homeAmount: 1001,
    fee: 2489446,
    clientRate: null,
    buyerCurrency: 'VND',
    homeCurrency: 'USD',
    quoteTime: 1697872644524,
    quoteDate: '21 Oct 2023 12:47 +05:30',
    expiry: 72,
    quoteStatus: 'SUCCESS',
    requestIdentifier: null,
    feePercentage: null,
    expiryDate: '24 Oct 2023 12:47 +05:30',
    externalQuoteReference: null,
    paymentType: {
      id: 'VPBank_VND',
      description: 'VP Bank',
      countries: '*',
      precedence: 1,
      expectReceivable: true,
      fxInvolved: true,
      provideInstructions: true,
      receivableInHomeCurrency: true,
      currencyFromClient: false,
      currencies: null,
      onlinePayment: false,
      defaultBankName: null,
      defaultBankAddress1: null,
      defaultBankAddress2: null,
      defaultBankCity: null,
      defaultBankState: null,
      defaultBankZip: null,
      defaultBankCountry: null,
      bankDetailsFixed: false,
      displayBankDetails: true,
      defaultPspId: null,
      refundEnabled: false,
      refundUploadEnabled: false,
      externalAuthRequestEnabled: false,
      allowedFileTypes: null,
      maxFileSize: null,
      isIndicativePricing: false,
      pspPaymentType: null,
      pspPaymentSubtype: null,
      isMcpCreditCard: false,
      quoteProvider: 'DEFAULT',
      isTriangulationEnabled: false,
      isSwiftPaymentEnabled: false,
      supportsClientMorMcpModel: false,
      isEmergentFlow: false,
      enableDocCollection: true,
      iftiEnabled: false,
      sendBankDataToCct: false,
      senderCorrespondentName: null,
      senderCorrespondentAddress: null,
      senderCorrespondentAddress2: null,
      senderCorrespondentCity: null,
      senderCorrespondentState: null,
      senderCorrespondentZip: null,
      senderCorrespondentCountry: null,
      taxEnabled: false,
      shortPaymentDealReviewFlag: false,
      overPaymentDealReviewFlag: false,
      fulfilmentCurrencyOverride: {},
      feeModel: 'DEFAULT',
      worldPayMerchantType: null,
      dfAvailableDuration: 0,
      ctdEnabled: false,
      monitorIncoming: false,
      pspPaymentMethod: null,
    },
    currentQuote: {
      quoteId: 89919097,
      clientId: '1000473340',
      clientRate: 1,
      clientRateIn: false,
      costRate: 1,
      costRateIn: false,
      fee: 100.25,
      quoteTime: 1697872644524,
      expectedAmount: 1101.25,
      homeAmount: 1001,
      expectedCurrency: 'USD',
      homeCurrency: 'USD',
      buyerQuoteCurrency: 'VND',
      buyerQuoteAmount: 27346660,
      buyerQuoteRate: 0.00004027,
      buyerQuoteRateIn: true,
      buyerQuoteCostRate: 0.00004072,
      isBuyerQuoteCostRateIn: true,
      feePercentage: null,
      prePricingClientRate: 0.00004027,
      homeAmountAndFee: 1101.25,
      isTriangulatedPledge: false,
      finalPartnerBuyerRate: null,
      initialPartnerBuyerRate: null,
      isPartnerBuyerRateIn: false,
      externalQuoteReference: null,
    },
    configuredGP2Fee: false,
    settlementCurrencyFee: 100.25,
  },
  buyerDetails: {
    address: 'sdfsdf',
    businessId: 'ffsdfsdf',
    invoiceNumber: null,
    city: null,
    country: 'VNM',
    currency: null,
    email: 'fsdfdsf@fdfsdf.com',
    firstName: 'fsdfsdfsdf',
    lastName: 'fsdfsdf',
    state: null,
    zip: null,
    debtorGroup: null,
    customFields: {},
  },
  payerCountry: null,
  enquiryEmail: 'gpfs.generalEnquiries@westernUnion.com',
  enquiryPhone1: '#Telephone Number 1#',
  enquiryPhone2: '#Telephone Number 2#',
  pledge: null,
  returnUrl: null,
  externalPaymentReference: null,
  buyerCanEdit: null,
  paymentTypeFields: {
    fieldList: [
      {
        paymentTypeId: 'VPBank_VND',
        fieldId: 'contactNo',
        visible: true,
        required: true,
        obfuscate: false,
        validationExpression: null,
        optionValues: null,
        countries: [],
      },
      {
        paymentTypeId: 'VPBank_VND',
        fieldId: 'onlineCourseEnrolment',
        visible: true,
        required: false,
        obfuscate: false,
        validationExpression: null,
        optionValues: null,
        countries: [],
      },
      {
        paymentTypeId: 'VPBank_VND',
        fieldId: 'payerAccountNumber',
        visible: true,
        required: true,
        obfuscate: false,
        validationExpression: null,
        optionValues: null,
        countries: [],
      },
    ],
  },
  kycDocList: [
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 1,
      description: 'Tuition bill and/or letter of admission',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression: true,
      conditionalExpression: null,
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 2,
      description: 'Students valid passport',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression: true,
      conditionalExpression: null,
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 3,
      description: 'Copies of visa documentation',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression:
        'session.sellerInfo.country !== "CAN" && session.selectedQuote.currentQuote.expectedAmount >= 1000 && session.selectedQuote.currentQuote.expectedCurrency === "USD" && !onlineCourseEnrolment',
      conditionalExpression:
        '"CAN" !== $scope.session.sellerInfo.country &&($scope.model.selectedQuote.currentQuote.expectedAmount >= 1000 &&$scope.model.selectedQuote.currentQuote.expectedCurrency == "USD") &&!document.getElementById("onlineCourseEnrolmentCheckbox").checked;',
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 4,
      description: 'Payer passport/citizen card front page',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression: 'model.payer.relation !== "MYSELF"',
      conditionalExpression: '_($scope).get("model.payer.relation") !== "MYSELF"',
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 5,
      description: 'Payer passport/citizen card back page',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression: 'model.payer.relation !== "MYSELF"',
      conditionalExpression: '_($scope).get("model.payer.relation") !== "MYSELF"',
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 6,
      description: 'Relationship document 1',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression: 'model.payer.relation !== "MYSELF"',
      conditionalExpression: '_($scope).get("model.payer.relation") !== "MYSELF"',
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 7,
      description: 'Relationship document 2',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression:
        '["PARENT_INLAW", "CHILD_INLAW", "BIOLOGICAL_SIBLING"].includes(model.payer.relation) || (model.payer.relation === "LEGAL_REPRESENTATIVE" && model.payer.attorneyAppointer === "PARENT_APPOINTED")',
      conditionalExpression:
        '["PARENT_INLAW", "CHILD_INLAW", "BIOLOGICAL_SIBLING"].includes(_($scope).get("model.payer.relation")) || (_($scope).get("model.payer.relation") === "LEGAL_REPRESENTATIVE" && $scope.model.payer.attorneyAppointer === "PARENT_APPOINTED")',
    },
    {
      paymentTypeId: 'VPBank_VND',
      docSeq: 8,
      description: 'Proof of online course enrollment',
      isMandatory: true,
      maxSize: 4,
      isEnabled: true,
      reactConditionalExpression: 'onlineCourseEnrolment',
      conditionalExpression:
        'document.getElementById("onlineCourseEnrolmentCheckbox") &&  document.getElementById("onlineCourseEnrolmentCheckbox").checked;',
    },
  ],
  worldPayIframe: null,
  dealReference: null,
  worldPayPaymentType: null,
  payerName: null,
  flutterwavePublicKey: null,
  flutterwaveLogoUrl: null,
  remitter: null,
  trustlySdkUrl: null,
  cohortGoRedirectUrl: null,
  trustlyDataView: null,
  trustlyErrorResponse: null,
  capturedTrustlyTransaction: false,
  trustlyTransactionStatus: null,
  externalPaymentUrl: null,
  sofortStatus: null,
  trustlyEmeaPaymentUrl: null,
  alipayQRCodeUrl: null,
  i18nHostname: null,
  mfeHostname: null,
  mfeVersion: null,
  mfeContext: null,
  pspLogo: null,
  transactionStatus: null,
  isWPPaymentNotificationTriggered: false,
  petnetRedirectionUrl: null,
};
