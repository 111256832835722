import {
  Autocomplete,
  Box,
  CircularProgress,
  Popper,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

const SmartAddress = ({
  addressValue,
  label,
  country,
  setFormData,
  placeholder,
  helperText,
  error,
  onBlur,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startTyping, setStartTyping] = useState(false);
  const [lastTime, setLastTime] = useState(new Date().getTime());
  const axiosSourceRef = useRef(null);
  const smartyMinimumCharacter = 5;
  const smartyMinimumDelay = 5000;

  const updteFormAddress = (address) => {
    setFormData({ address: address });
  };

  const canSearch = (query) => {
    /* Optimize search */
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - lastTime;
    return (
      (smartyMinimumCharacter && query.length % smartyMinimumCharacter == 0) ||
      timeElapsed >= smartyMinimumDelay
    );
  };

  const fetchOptions = async (query) => {
    if (!canSearch(query)) {
      return;
    }

    setIsLoading(true);
    try {
      let queryString = `?search=${query}&max_results=10`;
      if (country === 'USA') {
        queryString += '&source=all';
      } else {
        queryString += '&country=' + country;
      }
      const response = await postQuery(queryString, 'search');
      if (country === 'USA') {
        setOptions(
          response.data
            ? response.data.suggestions.map((c) => {
                return {
                  ...c,
                  address_text:
                    c.street_line +
                    ' ' +
                    c.secondary +
                    ' ' +
                    c.city +
                    ', ' +
                    c.state +
                    ' ' +
                    c.zipcode,
                  selectedAddress: c.street_line + ' ' + c.secondary,
                };
              })
            : [],
        );
      } else {
        setOptions(response.data ? response.data.candidates : []);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching data for address ID:', error);
      }
    } finally {
      setIsLoading(false);
      setLastTime(new Date().getTime());
    }
  };

  const postQuery = (queryString, type) => {
    if (axiosSourceRef.current) {
      axiosSourceRef.current.cancel('new search');
    }
    // Create a new cancel token source
    const source = axios.CancelToken.source();
    axiosSourceRef.current = source;
    const url = process.env.REACT_APP_API_BASE_URL + '/smarty/lookup/' + country;
    return axios.post(url, { query: queryString, type: type }, { cancelToken: source.token });
  };

  useEffect(() => {
    return () => {
      if (axiosSourceRef.current) {
        axiosSourceRef.current.cancel('Component unmounted or input changed.');
      }
    };
  }, []);

  const getFieldsUSA = (option) => {
    const data = {
      city: option.city,
      state: option.state,
      zip: option.zipcode,
      address2: option.secondary,
      address: option.street_line,
    };
    setFormData(data);
  };

  const getFields = async (option) => {
    setIsLoading(true);
    try {
      const response = await postQuery(
        `/${option.address_id}?country=${country}&search=${option.address_text}`,
        'search',
      );
      const candidate = response.data.candidates[0];
      const data = {
        city: candidate.locality,
        state: candidate.administrative_area,
        zip: candidate.postal_code,
        address: candidate.street,
        address2: '',
      };
      setFormData(data);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching detailed address data:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const searchWithAddressSelected = async (option) => {
    const selectedText =
      option.street_line +
      ' ' +
      option.secondary +
      ' (' +
      option.entries +
      ') ' +
      option.city +
      ', ' +
      option.state +
      ' ' +
      option.zipcode;
    const searchText = option.street_line + ' ' + option.secondary;
    const query = `?search=${searchText}&selected=${selectedText}`;

    setIsLoading(true);
    try {
      const response = await postQuery(query);
      setOptions(
        response.data.suggestions.map((c) => {
          return {
            ...c,
            address_text:
              c.street_line + ' ' + c.secondary + ' ' + c.city + ', ' + c.state + ' ' + c.zipcode,
          };
        }),
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching detailed address data:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const searchWithAddressId = async (option) => {
    setIsLoading(true);
    try {
      const response = await postQuery(
        `/${option.address_id}?country=${country}&search=${option.address_text}`,
        'search',
      );
      setOptions(response.data ? response.data.candidates : []);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching detailed address data:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const ConditionalPopper = styled(Popper)(({ options }) => ({
    ...(options.length === 0 && {
      height: 0,
      visibility: 'hidden', // Hide the dropdown entirely when there are no options
    }),
  }));

  return (
    <Autocomplete
      ListboxProps={{
        style: { padding: 0 },
      }}
      freeSolo
      PopperComponent={(props) => <ConditionalPopper {...props} options={options} />}
      options={options}
      filterOptions={(options) => options}
      value={addressValue || ''}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.address_text)}
      onInputChange={(event, value) => {
        if (!startTyping) {
          return;
        }
        updteFormAddress(value);
        if (!value) {
          setOptions([]);
          return;
        }
        fetchOptions(value);
      }}
      onBlur={() => {
        onBlur();
      }}
      renderOption={(props, option) =>
        option && (
          <Box
            component='li'
            {...props}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => {
              setStartTyping(false);
              setOptions([]);
              if (option.entries <= 1) {
                setOptions([]);
                updteFormAddress(option.address_text);
                if (country === 'USA') {
                  getFieldsUSA(option);
                } else {
                  getFields(option);
                }
              } else {
                if (country === 'USA') {
                  updteFormAddress(option.selectedAddress);
                  searchWithAddressSelected(option);
                } else {
                  updteFormAddress(option.address_text);
                  searchWithAddressId(option);
                }
              }
            }}
          >
            <Typography>{option.address_text}</Typography>
          </Box>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant='outlined'
          helperText={helperText}
          error={error}
          onKeyDown={() => {
            setStartTyping(true);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SmartAddress;
