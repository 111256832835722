import { createSlice } from '@reduxjs/toolkit';
import { initRefdata } from './action';

const refdataSlice = createSlice({
  name: 'refdata',
  initialState: {
    countries: [],
    countryDialingCodes: [],
    languages: [],
    provinceList: [],
    error: null,
    payerComplianceCountries: 'CAN',
    defaultBuyerPortalLanguage: 'en-GB',
    isFABEnabled: false,
    isPayerFieldsVisible: false,
    configList: {},
    serverSessionTimeout: 1800,
    allCountries: [],
    sanctionedCountries: [],
    smartyApiEnabledCountries: [],
  },
  reducers: {
    reset: (state) => (state.countries = []),
  },
  extraReducers: (builder) => {
    builder.addCase(initRefdata.fulfilled, (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    });
  },
});
export const { reset } = refdataSlice.actions;
export const selectCountries = (state) => state.refdata.countries;
export const selectDialCodes = (state) => state.refdata.countryDialingCodes;
export const selectAllCountries = (state) => state.refdata.allCountries;
export const selectSmartyEnabledCountries = (state) => state.refdata.smartyApiEnabledCountries;
export const selectRefDataCache = (state) => state.refdata;
export default refdataSlice.reducer;
