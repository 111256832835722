import { useEffect, useState } from 'react';

export function useDynamicColumns(defaultColumns, containerRef, breakpoint) {
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    const calculateColumns = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        if (containerWidth > breakpoint) {
          setColumns(2);
        } else {
          setColumns(1);
        }
      }
    };

    calculateColumns();
    window.addEventListener('resize', calculateColumns);

    return () => {
      window.removeEventListener('resize', calculateColumns);
    };
  }, [containerRef, breakpoint]);

  return columns;
}
