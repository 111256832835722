import { useEffect, useRef, useState } from 'react';
import { getTxnStatus } from '../../../apis';
import { useScript } from '../../../utils/helper';
import { STATUSES } from '../constants';

export const KlarnaPayment = ({ session, showReceipt }) => {
  const intervalRef = useRef();
  const [scriptReady, setScriptReady] = useState(false);
  const [apiFlag, setAPIFlag] = useState(false);
  const [elapsedSeconds, setElapsedSeconds] = useState(1200);

  useScript('https://x.klarnacdn.net/kp/lib/v1/api.js', () => {
    setScriptReady(true);
  });

  useEffect(() => {
    if (apiFlag) {
      checkStatus();
    }
  }, [apiFlag]);

  const checkStatus = async () => {
    let statusRequest = {
      dealReference: session.pledge.dealReference,
      paymentType: session.selectedQuote.paymentType.id,
    };
    let response = await getTxnStatus(statusRequest);
    if (response.success) {
      response = response.data.data;
      const status = response.status;
      if (
        response.notificationReceived &&
        (status === 'DEAL_AUTHORISED' || status == 'DEAL_AWAITING_PAYMENT')
      ) {
        clearInterval(intervalRef.current);
        showReceipt(STATUSES.SUCCESS);
      } else if (!response.approved || status === 'DEAL_DECLINED') {
        clearInterval(intervalRef.current);
        showReceipt(STATUSES.FAILED);
      }
    }
    setAPIFlag(false);
  };

  useEffect(() => {
    if (session.klarnaClientToken && scriptReady) {
      Klarna.Payments.init({
        client_token: session.klarnaClientToken,
      });

      const remitter = session.remitter;

      Klarna.Payments.authorize(
        {
          payment_method_category: 'klarna',
        },
        {
          shipping_address: {
            city: remitter.city /*"hjghjg"*/,
            country: remitter.countryCode /*"GB"*/, //two digit country code?
            email: remitter.email /*"jayaagr@convera.com"*/,
            family_name: remitter.lastName,
            given_name: remitter.firstName,
            //phone: /*remitter.contactNo*/,
            postal_code: remitter.zip,
            //region: "", //can we exclude?
            street_address: remitter.address,
            street_address2: remitter.address2,
            //title: "" //can we exclude?
          },
          billing_address: {
            city: remitter.city,
            country: remitter.countryCode,
            email: remitter.email,
            family_name: remitter.lastName,
            given_name: remitter.firstName,
            //phone: /*remitter.contactNo*/,
            postal_code: remitter.zip,
            //region: "", //can we exclude?
            street_address: remitter.address,
            street_address2: remitter.address2,
            //title: "" //can we exclude?
          },
          customer: {
            date_of_birth: remitter.dateOfBirth /* "1991-05-21"*/,
          },
        },
        async (res) => {
          if (!res.approved) {
            showReceipt(STATUSES.FAILED);
          } else {
            intervalRef.current = setInterval(() => {
              setElapsedSeconds((pastsecond) => {
                if (elapsedSeconds > 0) {
                  if (!apiFlag) {
                    setAPIFlag(true);
                  }
                  return pastsecond - 1;
                } else {
                  clearInterval(intervalRef.current);
                  showReceipt(STATUSES.FAILED);
                  return 0;
                }
              });
            }, 1000);
          }
        },
      );
    }

    return () => clearInterval(intervalRef.current);
  }, [scriptReady]);

  return <></>;
};
