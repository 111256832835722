import { Translate } from '../../i18n/translate';

export const ERROR_MESSAGES = {
  required: <Translate value='JS_fieldIsRequired' />,
  email: <Translate value='JS_INVALID_EMAIL' />,
  SWIFT_REGEX: <Translate value='JS_invalidCharactersMessage' />,
  CONTACT_NO_REGEX: <Translate value='JS_invalidContactNoMessage' />,
  ALPHA_NUMERIC: <Translate value='JS_invalidZipCodeAlphaNumericOnly' />,
  USA_ZIP_CODE: <Translate value='JS_invalidZipCodeUSA' />,
  CAN_ZIP_CODE: <Translate value='JS_invalidZipCodeCAN' />,
  MEX_ZIP_CODE: <Translate value='JS_invalidZipCodeMEX' />,
  COUNTRY_ZIP: <Translate value='JS_invalidZipCodeAlphaNumericOnly' />,
  CAML_ADDRESS: <Translate value='JS_PAYER_CAML_PAYER_ADDRESS_VALIDATION' />,
  ANY_ADDRESS: <Translate value='JS_PAYER_INVALID_ADDRESS_VALIDATION' />,
  PATTERN: <Translate value='JS_invalidCharactersMessage' />,
};

export const PATTERNS = {
  email: /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  name: /^[A-Za-z ]+$/i,
  SWIFT_REGEX:
    /^[ àèìòÀÈÌÒáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæa-zA-Z0-9,.:'\\+()?'\\/-]*$/,
  CONTACT_NO_REGEX: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$/,
  ALPHA_NUMERIC: /^[0-9a-zA-Z()\\:,. -]{0,}$/i,
  USA_ZIP_CODE_REGEX: /^\d{5}(\d{4})?$/,
  CAN_ZIP_CODE_REGEX: /^([A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d|0)$/,
  FIVE_DEGITS_ONLY_REGEX: /^[0-9]{5}$/,
  SWIFT_ADDRESS_REGEX: /^[^!@#$%\\^&*()+=]*$/i,
  ADDRESS_REGEX:
    /^(?!.*po\s*(bin|box)|.*PO\s*(BoX)|.*PO\s*(BOx)|.*PO\s*(boX)|.*PO\s*(bOx)|.*PO\s*(bOX)|.*Po\s*(BoX)|.*Po\s*(BOx)|.*Po\s*(boX)|.*Po\s*(bOx)|.*Po\s*(bOX)|.*pO\s*(bOx)|.*pO\s*(bOX)|.*pO\s*(Box)|.*pO\s*(BoX)|.*pO\s*(BOx)|.*pO\s*(BOX)|.*po\s*(BoX)|.*po\s*(BOx)|.*po\s*(bOx)|.*po\s*(bOX)|.*po\s*(boX)|.*pO\s*(boX)|.*pO\s*(box)|.*PO\s*(Box)|.*PO\s*(box)|.*Po\s*(box)|.*Po\s*(BOX)|.*po\s*(BOX)|.*po\s*(Box)|.*PO\s*(BOX)|.*Po\s*(Box)|.*p. o.\s*(bin|box)|.*P. O.\s*(Box)|.*P. O.\s*(BOX)|.*P. o.\s*(Box)|.*post office\s*(bin|box)|.*gpo\s*(bin|box)|.*g.p.o.\s*(bin|box)|.*locked bag|.*null|.*not applicable|.*not provided|.*none provided|.*BP\s*\d|.*b.p.\s*\d|.*b. p.\s*\d|.*boite postale|.*postfach|.*box |.*N\/A|.*\b(?=\w)X\b(?<=\w)|.*\b(?=\w)XX\b(?<=\w)|.*\b(?=\w)XXX\b(?<=\w)|.*\b(?=\w)XXXX\b(?<=\w)|.*\b(?=\w)XXXXX\b(?<=\w)|.*\b(?=\w)XXXXXX\b(?<=\w)|.*\b(?=\w)XXXXXXX\b(?<=\w)|.*\b(?=\w)XXXXXXXX\b(?<=\w)|.*\b(?=\w)None\b(?<=\w)|.*\b(?=\w)X X\b(?<=\w)|-|\.|,|.*boîte postale|\s+|.*p.o.\s*(bin|box)|.*\b(?=\w)NA$\b(?<=\w)|.*\b(?=\w)na$\b(?<=\w).*|.*([!@#$%^&*()+=])).*$/,
};

export const INITIAL_VALUES = {
  firstName: '',
  middleName: '',
  lastName: '',
  payerName: '',
};

export const API_NAME_MAPPING = {
  firstName: '',
  lastName: '',
  middleName: '',
  payerName: 'name',
  idNumber: '',
  dateOfBirth: '',
  nationality: '',
  email: '',
  dialingFromCountry: '',
  contactNo: '',
  payerAddress: 'address',
  payerAddress2: 'address2',
  payerCity: 'city',
  payerState: 'state',
  payerZip: 'zip',
  payerCountry: 'country',
  branchCode: '',
  bankSwiftCode: '',
  payerBankName: 'bankName',
  payerAccountNumber: 'accountNumber',
  payerBankAddress: 'bankAddress',
  payerBankAddress2: 'bankAddress2',
  payerBankCity: 'bankCity',
  payerBankState: 'bankState',
  payerBankZip: 'bankZip',
  bankCountry: 'bankCountry',
};
