import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { deleteKycDocument, uploadKycDocument } from '../../apis';
import { Translate } from '../../i18n/translate';
import InputWithLabel from '../InputWithLabel';
import './doc.scss';
import DocumentPreview from './documentPreview';

const MAX_FILE_SIZE = 4;
const ALLOWED_FILE_TYPES = ['.png', '.jpg', '.pdf', '.jpeg'];

const DocUploader = ({
  required,
  labelName,
  isVerified,
  updateDoc,
  docSeq,
  getDynamicFieldLabel,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploaded, setUploaded] = useState(false);

  const getLabelName = (type) => {
    //title | placeholder
    if (!getDynamicFieldLabel) {
      return labelName;
    }

    if (type === 'title') {
      return getDynamicFieldLabel('JS_DOC_UPLOAD_TITLE_' + docSeq);
    }

    if (type === 'placeholder') {
      return getDynamicFieldLabel('JS_DOC_UPLOAD_PLACEHOLDER_' + docSeq);
    }
  };

  const chooseFile = async () => {
    if (!selectedFile) {
      // document.getElementById('fileInput' + docSeq).click();
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      //else => delete FILE
      const response = await deleteKycDocument(docSeq);

      if (response.success) {
        setUploaded(false);
        updateDoc({ isUploaded: false });
        setErrorMessage('');
        setSelectedFile(null);
        updateDoc({ isVerified: false });
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    let fileError = '';

    if (selectedFile) {
      const sizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2);
      updateDoc({ isError: sizeInMB > MAX_FILE_SIZE });
      fileError = sizeInMB > MAX_FILE_SIZE ? 'JS_DOC_COL_FILE_SIZE_ERROR_MESSAGE' : '';
      setSelectedFile(selectedFile);

      if (ALLOWED_FILE_TYPES.indexOf('.' + selectedFile.name.split('.').pop().toLowerCase()) < 0) {
        fileError = 'JS_DOC_COL_FILE_TYPE_ERROR_MESSAGE';
        updateDoc({ isError: true });
      }
    }

    setErrorMessage(fileError);

    if (!fileError) {
      uploadFile(selectedFile);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('sequence', docSeq);

    const response = await uploadKycDocument(formData);

    if (response.success) {
      setUploaded(true);
      updateDoc({ isUploaded: true });
    }
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
    updateDoc({ isVerified: true });
  };

  const renderHelperText = () => {
    if (isUploaded) {
      return (
        <p className='text-success2'>
          <CheckCircleOutlineOutlinedIcon className='text-base mr-1' />
          <Translate value='JS_DOC_COL_SUCCESS_MESSAGE' />
        </p>
      );
    } else if (errorMessage) {
      return (
        <p className='text-error'>
          <Translate value={errorMessage} />
        </p>
      );
    }
  };

  return (
    <div>
      <InputWithLabel label={getLabelName('title') + (required ? ' *' : '')}>
        <TextField
          label={''}
          name='name'
          value={selectedFile?.name ?? ''}
          variant='outlined'
          inputProps={{
            placeholder: getLabelName('placeholder'),
            readOnly: true,
          }}
          error={!!errorMessage}
          helperText={renderHelperText()}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => chooseFile()}
                  edge='end'
                >
                  {!selectedFile ? (
                    <AttachFileIcon className='text-accent' />
                  ) : (
                    <DeleteOutlineOutlinedIcon className='text-error' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </InputWithLabel>

      {/* Hidden file input */}
      <input
        type='file'
        ref={fileInputRef}
        id={'fileInput' + docSeq}
        accept={ALLOWED_FILE_TYPES.join(',')}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Review modal trigger button */}

      {isUploaded && (
        <div
          className={`text-center accordion-content mb-4 ${
            selectedFile && !errorMessage && 'open'
          }`}
        >
          <Button
            className={`${
              isVerified ? 'bg-success2' : 'bg-accent'
            }  text-white font-normal px-4 py-2`}
            startIcon={<VisibilityOutlinedIcon />}
            onClick={() => setOpenPreview(true)}
          >
            {isVerified ? getLabelName('title') + ' reviewed' : 'Review ' + getLabelName('title')}
          </Button>
          {!isVerified && (
            <p className='text-error text-sm mt-1'>
              <Translate value='JS_DOC_COL_PREVIEW_MESSAGE' />
            </p>
          )}
        </div>
      )}

      <DocumentPreview
        open={openPreview}
        file={selectedFile}
        onClose={handlePreviewClose}
        labelName={getLabelName('title')}
      />
    </div>
  );
};

export default DocUploader;
