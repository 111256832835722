import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTxnStatus } from '../../../apis';
import { Nf, RawHtmlContent } from '../../../components/Format';
import { Translate } from '../../../i18n/translate';
import { selectData } from '../../../store/slices/session/sessionSlice';
import { getHomeAmount, getHomeCurrency } from '../../../utils/quoteService';
import { STATUSES } from '../constants';

const TIMER_SECONDS = 600; //3 minutes

const AliPay = ({ QRCodeURL, showReceipt }) => {
  const intervalRef = useRef();
  const [timer, setTimer] = useState();
  const [apiFinished, setApiFinished] = useState(true);
  const session = useSelector(selectData);

  useEffect(() => {
    runTimer();
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (timer == 0) {
      showReceipt(STATUSES.FAILED);
    } else {
      fetchStatus();
    }
  }, [timer, apiFinished]);

  const runTimer = () => {
    clearInterval(intervalRef.current);
    setTimer(TIMER_SECONDS);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalRef.current);
          return 0;
        }
      });
    }, 1000);
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const fetchStatus = async () => {
    if (!apiFinished) {
      return;
    }
    let statusRequest = {
      dealReference: session.pledge.dealReference,
      paymentType: session.selectedQuote.paymentType.id,
    };
    setApiFinished(false);
    let response = await getTxnStatus(statusRequest);
    setApiFinished(true);
    if (response.success) {
      response = response.data.data;
      const status = response.status;
      if (response.notificationReceived && status === 'DEAL_AUTHORISED') {
        clearInterval(intervalRef.current);
        showReceipt(STATUSES.SUCCESS);
      } else if (
        response.notificationReceived &&
        (status === 'DEAL_AWAITING_AUTH' || status === 'DEAL_DECLINED')
      ) {
        clearInterval(intervalRef.current);
        showReceipt(STATUSES.FAILED);
      }
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        padding: 0,
      }}
    >
      <DialogTitle className='!text-18-bold !p-5 !pb-0 relative'>
        <Translate value='ALIPAY_QR_SCAN' />
      </DialogTitle>
      <DialogContent className='!p-5 text-base'>
        <DialogContentText className='!text-lightGray3'>
          <Translate value='ALIPAY_QR_TITLE' />
        </DialogContentText>
        <div className='flex justify-center'>
          <img src={QRCodeURL} alt='' />
        </div>
        <p className='text-chromeYellow text-center text-16-bold'>
          <RawHtmlContent
            htmlContent='ALIPAY_QR_EXPIRE_IN'
            translateValues={{ expire_in: `${minutes}:${seconds < 10 ? '0' : ''}${seconds}` }}
          />
        </p>
        <div className='text-center text-18-bold'>
          <p>
            {Nf(getHomeAmount(session.selectedQuote))} {getHomeCurrency(session.selectedQuote)}
          </p>
          <p>to</p>
          <p>{session.sellerInfo.name}</p>
        </div>
      </DialogContent>
      <DialogActions className='!p-0'>
        <div className='flex bg-accentLight p-4 text-accentDark'>
          <InfoOutlinedIcon className='mr-4' />
          <p className='text-accentDark'>
            <RawHtmlContent htmlContent='ALIPAY_QR_WARNING' />
          </p>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AliPay;
