import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalData, selectModalOpen } from '../../store/slices/modal/modalSlice';
import { imagePath } from '../../utils/imgHelper';

export default function GenericModal() {
  const modalData = useSelector(selectModalData);
  const open = useSelector(selectModalOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleClick = (action) => {
    handleClose();
    if (action === 'yes' && typeof modalData.yesAction === 'function') {
      modalData.yesAction();
    } else if (action === 'no' && typeof modalData.noAction === 'function') {
      modalData.noAction();
    }
  };

  return (
    // <div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle className='!text-18-bold !p-5 !pb-0 relative'>
        {modalData?.icon && (
          <img src={imagePath(modalData?.icon)} className='!initial left-0 top-0' />
        )}
        {modalData?.title}
        <IconButton aria-label='close' className='!absolute top-3 right-5' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='!p-5 text-base'>
        <DialogContentText className='!text-lightGray3'>{modalData?.body}</DialogContentText>
      </DialogContent>
      <DialogActions className='!p-5'>
        {modalData?.yesLabel && (
          <Button variant='contained' className='text-14' onClick={() => handleClick('yes')}>
            {modalData.yesLabel}
          </Button>
        )}
        {modalData?.noLabel && (
          <Button
            className='text-14'
            disableElevation
            variant='contained'
            color='secondary'
            onClick={() => handleClick('no')}
            autoFocus
          >
            {modalData.noLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
    // </div>
  );
}
