// eventBus.js
class EventBus {
  constructor() {
    this.listeners = {};
  }

  // Method to add event listeners
  on(event, listener) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(listener);
  }

  // Method to remove event listeners
  off(event, listener) {
    if (!this.listeners[event]) return;
    this.listeners[event] = this.listeners[event].filter((l) => l !== listener);
  }

  // Method to dispatch events
  emit(event, data) {
    if (!this.listeners[event]) return;
    this.listeners[event].forEach((listener) => listener(data));
  }
}

// Create a singleton instance of the event bus
export const eventBus = new EventBus();
