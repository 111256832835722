import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceDetails, getToken } from '../../apis';
import { Translate } from '../../i18n/translate';
import { initRefdata } from '../../store/slices/refdata/action';
import {
  ROUTE_MAPPING,
  activateRoutes,
  hidePaymentSummaryAction,
  navigate,
  routeName,
  setRoutes,
  setShowStepper,
} from '../../store/slices/router/routerSlice';
import {
  selectError,
  selectSsoRequest,
  setCatalog,
  setData,
  setError,
  setHideReceipt,
  setHideServiceCatalog,
  setHomeAmount,
  setHomeCurrency,
  setIsGPXClient,
  setToken,
} from '../../store/slices/session/sessionSlice';
import { getSsoInfo } from '../../utils/helper';

export const GenericError = () => {
  const error = useSelector(selectError);
  const [open, setOpen] = React.useState(true);
  const [hideButton, setHideButton] = React.useState(false);
  const ssoRequest = useSelector(selectSsoRequest);
  const [showLandingPage, setShowLandingPage] = React.useState(false); // To check on click only
  const route = useSelector(routeName);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hidePaymentSummaryAction());
  });

  const fetchToken = async () => {
    const { merchantId, merchantSecret, sellerId, buyerId } = getSsoInfo(ssoRequest);
    try {
      const data = {
        sellerId: sellerId,
        merchantId: merchantId,
        merchantSecret: merchantSecret,
        buyerId: buyerId,
      };
      const response = await getToken(data);
      if (response.success) {
        const accessResponse = response.data;
        const token = accessResponse.Authorization[0];
        dispatch(setToken(token));
        const hideServiceCatalog =
          accessResponse.hideServiceCatalogue &&
          accessResponse.hideServiceCatalogue.length == 1 &&
          accessResponse.hideServiceCatalogue[0] === 'true';
        const hideReceiptPage =
          accessResponse.hideReceiptPage &&
          accessResponse.hideReceiptPage.length == 1 &&
          accessResponse.hideReceiptPage[0] === 'true';
        const enableLanding =
          accessResponse.displayLandingPage &&
          accessResponse.displayLandingPage.length == 1 &&
          accessResponse.displayLandingPage[0] === 'true';
        let consolBuyerRemitterPageEnabled = false;
        setShowLandingPage(enableLanding);
        if (!enableLanding) {
          const serviceResponseData = await getServiceDetails(ssoRequest);
          const serviceResponse = serviceResponseData.data.data;
          consolBuyerRemitterPageEnabled =
            serviceResponse.sellerInfo.consolBuyerRemitterPageEnabled;
          if (hideServiceCatalog) {
            dispatch(setHideServiceCatalog(true));
            const serviceItems = serviceResponse.serviceItemViewList?.serviceItemViews;
            serviceItems.forEach((s) => {
              s.included = true;
            });
            const isGpx = serviceResponse.sellerInfo.sellerIndustry != 'EDUCATION';
            dispatch(setIsGPXClient(isGpx));
            dispatch(setHomeAmount(getTotalAmount(serviceItems)));
            dispatch(setHomeCurrency(serviceResponse.sellerInfo?.homeCurrency));
          }
          dispatch(setData(serviceResponse));
        }
        dispatch(
          setRoutes({
            hideServiceCatalog: hideServiceCatalog,
            consolBuyerRemitterPageEnabled: consolBuyerRemitterPageEnabled,
            hideReceiptPage: hideReceiptPage,
            enableLanding: enableLanding,
          }),
        );
        if (hideServiceCatalog) {
          dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT_METHOD }));
        }
        if (enableLanding) {
          dispatch(navigate({ route: ROUTE_MAPPING.LANDING }));
        }
        dispatch(setHideReceipt(hideReceiptPage));
        dispatch(initRefdata());
        dispatch(setShowStepper(true));
        dispatch(setToken(token));
        dispatch(setCatalog(null));
        dispatch(setError(null));
        dispatch(activateRoutes(true));
      }
    } catch (error) {
      setHideButton(false);
      console.error(`Token API ${error.message}`); // TODO: remove
    }
  };

  const getTotalAmount = (serviceItems) =>
    serviceItems.reduce((total, c) => {
      total += c.mandatory || c.included ? parseFloat(c.amountOwing || 0) : 0;
      return total;
    }, 0);

  return (
    <div className='pt-3 pb-3'>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Collapse in={open}>
          <Alert
            sx={{ backgroundColor: '#FD7557', alignItems: 'center', display: 'flex' }}
            icon={<ErrorOutlineOutlinedIcon className='text-black ml-1' />}
            severity='error'
            onClose={() => {
              setOpen(false);
            }}
          >
            <span className='font-bold text-black'>Server Error</span>
            {error && (
              <span className='font-normal text-black text-16'>
                —{' '}
                {error.error && error.error.message
                  ? error.error.message
                  : 'An error has occurred or your session has timed out.'}{' '}
                {error.error && error.error.code ? `(Error ID: ${error.error.code} )` : ''}
              </span>
            )}
          </Alert>
        </Collapse>
      </Box>
      <div className='flex justify-center'>
        {!hideButton && (
          <Button
            className='text-14  font-medium mt-2 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
            variant='contained'
            onClick={() => {
              dispatch(setError(null));
              setOpen(false);
              setHideButton(true);
              if (!showLandingPage) {
                if (route !== ROUTE_MAPPING.SERVICE_CATALOG) {
                  dispatch(navigate({ route: ROUTE_MAPPING.SERVICE_CATALOG }));
                }
              }
              fetchToken();
            }}
          >
            <Translate value='START_NEW_QUOTE' />
          </Button>
        )}
      </div>
    </div>
  );
};
