import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import InputWithLabel from '../InputWithLabel';

const DatePicker = ({
  name,
  labelKey,
  value,
  handleChange,
  handleBlur,
  mandatory = false,
  disabled = false,
  minDate = '',
  maxDate = '',
  error,
  helperText,
}) => {
  const intl = useIntl();
  return (
    // TODO: handle date errors, min date and max date
    <InputWithLabel required={mandatory} label={labelKey}>
      <TextField
        label=''
        name={name}
        value={value}
        variant='outlined'
        inputProps={{
          min: minDate,
          max: maxDate,
          placeholder: intl.formatMessage({ id: labelKey }),
          type: 'date',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        required={mandatory}
        error={error}
        helperText={helperText}
        fullWidth
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </InputWithLabel>
  );
};

export default DatePicker;
