import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, TranslateWithValues } from '../../../i18n/translate';
import { selectQuote, setLoading } from '../../../store/slices/session/sessionSlice';
import { STATUSES } from '../constants';

const CountdownTimer = ({ showReceipt }) => {
  const initialCountdownTime = 15 * 60;
  const [countdownTime, setCountdownTime] = useState(initialCountdownTime);
  const [minutes, setMinutes] = useState(Math.floor(initialCountdownTime / 60));
  const [, setSeconds] = useState(initialCountdownTime % 60);
  const [secondsFormatted, setSecondsFormatted] = useState(
    ('0' + (initialCountdownTime % 60)).slice(-2),
  );
  const countdownHandle = useRef(null);
  const timeoutHandle = useRef(null);
  const [expired, setExpired] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    countdownHandle.current = setInterval(() => {
      setCountdownTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdownHandle.current);
          setExpired(true);
          dispatch(setLoading(true));
          timeoutHandle.current = setTimeout(() => {
            clearTimeout(timeoutHandle.current);
            showReceipt(STATUSES.FAILED);
          }, 10000);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => {
      dispatch(setLoading(false));
      clearInterval(countdownHandle.current);
      clearTimeout(timeoutHandle.current);
    };
  }, []);

  useEffect(() => {
    setMinutes(Math.floor(countdownTime / 60));
    setSeconds(countdownTime % 60);
    setSecondsFormatted(('0' + (countdownTime % 60)).slice(-2));
  }, [countdownTime]);

  return (
    <div>
      <div className='mb-2 mt-1 text-center'>
        {!expired && (
          <div className='text-16-bold'>
            {TranslateWithValues('JS_UNLIMIT_QUOTE_EXPIRE_H1', {
              minutes: minutes,
              secondsFormatted: secondsFormatted,
            })}
          </div>
        )}
        {expired && (
          <div className='text-16-bold'>
            <Translate value='JS_UNLIMIT_QUOTE_EXPIRE_H2' />
          </div>
        )}
        {!expired && (
          <div className='mt-0.5'>
            <Translate value='JS_UNLIMIT_QUOTE_EXPIRE_H3' />
          </div>
        )}
        {expired && (
          <div className='mt-0.5'>
            <Translate value='JS_UNLIMIT_QUOTE_EXPIRE_H4' />
          </div>
        )}
      </div>
    </div>
  );
};

const Unlimint = ({ iframeUrl, showReceipt, goToChoosePayments }) => {
  const unLimintIframeRef = useRef();
  const [rateIsValid, setRateIsValid] = useState(true);
  const selectedQuote = useSelector(selectQuote);
  useEffect(() => {
    if (
      selectedQuote.paymentType.id == 'NGN_BANK_TRANSFER' &&
      selectedQuote.rateExpiryTime <= new Date().getTime()
    ) {
      setRateIsValid(false);
    }
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (rateIsValid) {
      createIframe();
    } else {
      showReceipt(STATUSES.RATE_EXPIRED);
    }
  }, [rateIsValid]);

  const createIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.src = iframeUrl;
    iframe.style.border = 'none';
    iframe.style.width = '100%';
    iframe.style.height = '600px';
    iframe.style.overflowY = 'auto';
    const unLimintIframe = unLimintIframeRef.current;

    if (unLimintIframe) {
      unLimintIframe.innerHTML = '';
      unLimintIframe.appendChild(iframe);
    }

    window.addEventListener('message', handleMessage);
  };

  const handleMessage = (ev) => {
    var action = ev.data;
    if (action === 'success') {
      showReceipt(STATUSES.SUCCESS); //Success receipt
    } else if (action === 'cancel') {
      goToChoosePayments();
    } else {
      showReceipt(STATUSES.FAILED); //failure receipt
    }
  };

  return (
    <>
      {selectedQuote.paymentType.id == 'NGN_BANK_TRANSFER' && (
        <CountdownTimer showReceipt={showReceipt} />
      )}
      <div id='unlimint-iframe' ref={unLimintIframeRef}></div>;
    </>
  );
};

export default Unlimint;
