import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { CountrySelect } from '../../components/CountryDropDown';
import DatePicker from '../../components/DatePicker';
import InputWithLabel from '../../components/InputWithLabel';
import { Translate } from '../../i18n/translate';
import PayerCAMLAuthUsersInput from './PayerCAMLAuthUsersInput';
import PayerFormContext from './payerFormContext';
import { CAML_ACCNT_TYPES, CAML_DOC_TYPES } from './payerHelpers';

const PayerAdditionalOptionalFields = ({ handleInputChange, handleBlur, setCountry }) => {
  const {
    getDynamicFieldLabel,
    countries,
    routingCodeLabel,
    currencies,
    //directly from payer form
    formData,
    errors,
  } = useContext(PayerFormContext);

  const [showAdditionalCamlFields, setShowAdditionalCAMLFields] = useState('');
  const intl = useIntl();

  const getTranslatedValue = (label) => {
    return intl.formatMessage({ id: label });
  };

  const renderIndiviualEntityTypes = () => {
    return (
      <div>
        <h4 className='text-16-bold mb-3'>
          <Translate value='JS_PAYER_CAML_PAYER_INFO' />
        </h4>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_PHONE_NUMBER'>
          <TextField
            label={''}
            name={'camlPhoneNumber'}
            value={formData['camlPhoneNumber']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_PHONE_NUMBER_PLACEHOLDER'),
            }}
            error={!!errors['camlPhoneNumber']}
            helperText={errors['camlPhoneNumber']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <DatePicker
          name='camlDateOfBirth'
          labelKey='JS_PAYER_CAML_PAYER_DOB'
          value={formData['camlDateOfBirth']}
          handleChange={handleInputChange}
          mandatory={false}
          maxDate={new Date().toISOString().split('T')[0]}
        />

        <InputWithLabel
          label='JS_PAYER_CAML_PAYER_COUNTRY'
          tooltip={getDynamicFieldLabel('JS_PAYER_CAML_PAYER_COUNTRY')}
        >
          <CountrySelect
            name='contactCountryCode'
            countries={countries}
            setCountry={(v) => {
              setCountry('contactCountryCode', v);
            }}
            value={formData['contactCountryCode']}
            error={!!errors['contactCountryCode']}
          />
          {errors['contactCountryCode'] && (
            <FormHelperText error>{errors['contactCountryCode']}</FormHelperText>
          )}
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_ALIAS'>
          <TextField
            label={''}
            name='camlNickName'
            value={formData['camlNickName']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_ALIAS_PLACEHOLDER'),
            }}
            error={!!errors['camlNickName']}
            helperText={errors['camlNickName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel
          label='JS_PAYER_CAML_PAYER_ID_DOC_TYPE'
          tooltip={'JS_PAYER_CAML_PAYER_ID_DOC_TYPE_HINT'}
        >
          <FormControl fullWidth>
            <Select name='camlDocType' value={formData['camlDocType']} onChange={handleInputChange}>
              {CAML_DOC_TYPES.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  <Translate value={option.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputWithLabel>

        <InputWithLabel
          label='JS_PAYER_CAML_PAYER_ID_DOC_REF'
          tooltip='JS_PAYER_CAML_PAYER_ID_DOC_REF_HINT'
        >
          <TextField
            label={''}
            name='camlDocRef'
            value={formData['camlDocRef']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_ID_DOC_REF_PLACEHOLDER'),
            }}
            error={!!errors['camlDocRef']}
            helperText={errors['camlDocRef']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel
          label='JS_PAYER_CAML_PAYER_ID_DOC_COUNTRY'
          tooltip={getDynamicFieldLabel('JS_PAYER_CAML_PAYER_ID_DOC_COUNTRY')}
        >
          <CountrySelect
            name='camlDocCountryOfIssue'
            countries={countries}
            setCountry={(value) => {
              setCountry('camlDocCountryOfIssue', value);
            }}
            value={formData['camlDocCountryOfIssue']}
            error={!!errors['camlDocCountryOfIssue']}
          />
          {errors['camlDocCountryOfIssue'] && (
            <FormHelperText error>{errors['camlDocCountryOfIssue']}</FormHelperText>
          )}
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_OCCUPATION'>
          <TextField
            label={''}
            name='camlOccupation'
            value={formData['camlOccupation']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_OCCUPATION_PLACEHOLDER'),
              maxLength: 100,
            }}
            error={!!errors['camlOccupation']}
            helperText={errors['camlOccupation']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_EMPLOYER_NAME'>
          <TextField
            label={''}
            name='camlEmployerName'
            value={formData['camlEmployerName']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_EMPLOYER_NAME_PLACEHOLDER'),
              maxLength: 100,
            }}
            error={!!errors['camlEmployerName']}
            helperText={errors['camlEmployerName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      </div>
    );
  };

  const renderBusinessEntityTypes = () => {
    return (
      <div>
        <h4 className='text-16-bold mb-3'>
          <Translate value='JS_PAYER_CAML_BUSINESS_INFO' />
        </h4>

        <InputWithLabel label='JS_PAYER_CAML_COMPANY_PHONE_NUMBER'>
          <TextField
            label={''}
            name={'camlPhoneNumber'}
            value={formData['camlPhoneNumber']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_PHONE_NUMBER_PLACEHOLDER'),
            }}
            error={!!errors['camlPhoneNumber']}
            helperText={errors['camlPhoneNumber']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_PRINCIPAL_BUSINESS'>
          <TextField
            label={''}
            name={'camlPrincipalBusiness'}
            value={formData['camlPrincipalBusiness']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_PRINCIPAL_BUSINESS_PLACEHOLDER'),
            }}
            error={!!errors['camlPrincipalBusiness']}
            helperText={errors['camlPrincipalBusiness']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_REG_NUM'>
          <TextField
            label={''}
            name={'camlRegNumber'}
            value={formData['camlRegNumber']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_REG_NUM_PLACEHOLDER'),
            }}
            error={!!errors['camlRegNumber']}
            helperText={errors['camlRegNumber']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel
          label='JS_PAYER_CAML_PAYER_REG_COUNTRY'
          tooltip={getDynamicFieldLabel('JS_PAYER_CAML_PAYER_REG_COUNTRY')}
        >
          <CountrySelect
            name='camlRegCountryOfIssue'
            countries={countries}
            setCountry={(v) => setCountry('camlRegCountryOfIssue', v)}
            value={formData['camlRegCountryOfIssue']}
            error={!!errors['camlRegCountryOfIssue']}
          />
          {errors['camlRegCountryOfIssue'] && (
            <FormHelperText error>{errors['camlRegCountryOfIssue']}</FormHelperText>
          )}
        </InputWithLabel>

        {/* TODO: Get values from this component */}
        <PayerCAMLAuthUsersInput label='JS_PAYER_CAML_PAYER_AUTHUSER' />
      </div>
    );
  };

  const renderCommonFields = () => {
    return (
      <div className='mt-5'>
        <h4 className='text-16-bold mb-3'>
          <Translate value='JS_PAYER_CAML_PAYER_BANK_INFO' />
        </h4>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_ACC_HOLDER_NAME'>
          <TextField
            label={''}
            name={'camlAccountHolderName'}
            value={formData['camlAccountHolderName']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_ACC_HOLDER_NAME_PLACEHOLDER'),
            }}
            error={!!errors['camlAccountHolderName']}
            helperText={errors['camlAccountHolderName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel
          label={getDynamicFieldLabel('JS_PAYER_CAML_PAYER_ACC_TYPE')}
          tooltip={'JS_PAYER_CAML_PAYER_ID_DOC_TYPE_HINT'}
        >
          <FormControl fullWidth>
            <Select
              name='camlBankAccType'
              value={formData['camlBankAccType']}
              onChange={handleInputChange}
            >
              {CAML_ACCNT_TYPES.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  <Translate value={option.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors['camlBankAccType'] && (
            <FormHelperText error>{errors['camlBankAccType']}</FormHelperText>
          )}
        </InputWithLabel>

        <InputWithLabel label={routingCodeLabel}>
          <TextField
            label={''}
            name={'bankRoutingCode'}
            value={formData['bankRoutingCode']}
            variant='outlined'
            inputProps={{
              placeholder:
                getTranslatedValue('JS_PAYER_CAML_PAYER_BANK_ROUTING_CODE_PLACEHOLDER') +
                ' ' +
                routingCodeLabel,
            }}
            error={!!errors['bankRoutingCode']}
            helperText={errors['bankRoutingCode']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label='swiftCodeLabel'>
          <TextField
            label={''}
            name={'bankSwiftCode'}
            value={formData['bankSwiftCode']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_BANK_SWIFT_CODE_PLACEHOLDER'),
              maxLength: 11,
            }}
            error={!!errors['bankSwiftCode']}
            helperText={errors['bankSwiftCode']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_ACC_CURRENCY'>
          <FormControl fullWidth>
            <Select
              name='camlAccountCurrency'
              value={formData['camlAccountCurrency']}
              onChange={handleInputChange}
            >
              {currencies.map((option, index) => (
                <MenuItem key={index} value={option}>
                  <Translate value={`CC_${option}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors['camlAccountCurrency'] && (
            <FormHelperText error>{errors['camlAccountCurrency']}</FormHelperText>
          )}
        </InputWithLabel>

        <DatePicker
          name='camlAccountOpenedDate'
          labelKey='JS_PAYER_CAML_PAYER_ACC_OPENED_DATE'
          value={formData['camlAccountOpenedDate']}
          handleChange={handleInputChange}
          mandatory={false}
          maxDate={new Date().toISOString().split('T')[0]}
        />

        <InputWithLabel label='JS_PAYER_CAML_PAYER_CONTACT_NAME'>
          <TextField
            label={''}
            name={'camlContactPersonName'}
            value={formData['camlContactPersonName']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_CONTACT_NAME_PLACEHOLDER'),
            }}
            error={!!errors['camlContactPersonName']}
            helperText={errors['camlContactPersonName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label={'JS_PAYER_CAML_PAYER_CONTACT_EMAIL'}>
          <TextField
            label={''}
            name={'camlContactPersonEmail'}
            value={formData['camlContactPersonEmail']}
            variant='outlined'
            inputProps={{
              placeholder: getDynamicFieldLabel('JS_PAYER_CAML_PAYER_CONTACT_EMAIL_PLACEHOLDER'),
              maxLength: 150,
            }}
            error={!!errors['camlContactPersonEmail']}
            helperText={errors['camlContactPersonEmail']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>

        <InputWithLabel label='JS_PAYER_CAML_PAYER_CONTACT_PHONE'>
          <TextField
            label={''}
            name={'camlContactPersonNumber'}
            value={formData['camlContactPersonNumber']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_CONTACT_PHONE_PLACEHOLDER'),
            }}
            error={!!errors['camlContactPersonNumber']}
            helperText={errors['camlContactPersonNumber']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      </div>
    );
  };

  return (
    <div>
      <h4 className='text-16-bold mb-3'>
        <Translate value='JS_PAYER_CAML_INFO' />
      </h4>
      {/* <InputWithLabel label='Who will be making this payment?'> */}
      <FormControl>
        <RadioGroup
          name={'showAdditionalCamlFields'}
          className='flex-row justify-between'
          onChange={(e) => setShowAdditionalCAMLFields(e.target.value)}
          value={showAdditionalCamlFields}
        >
          <FormControlLabel
            value='SKIP'
            control={<Radio />}
            label={<Translate value={'JS_PAYER_CAML_SKIP'} />}
          />
          <FormControlLabel
            value='SHOW'
            control={<Radio />}
            label={<Translate value={'JS_PAYER_CAML_SHOW'} />}
          />
        </RadioGroup>
      </FormControl>
      {/* </InputWithLabel> */}

      {showAdditionalCamlFields === 'SHOW' && (
        <>
          <InputWithLabel label='JS_PAYER_CAML_WHO_IS_MAKING_PAYMENT'>
            <FormControl>
              <RadioGroup
                name={'camlEntityType'}
                className='flex-row justify-between'
                onChange={handleInputChange}
                value={formData['camlEntityType']}
              >
                <FormControlLabel
                  value='INDIVIDUAL'
                  control={<Radio />}
                  label={<Translate value={'JS_PAYER_CAML_INDIVIDUAL'} />}
                />
                <FormControlLabel
                  value='BUSINESS'
                  control={<Radio />}
                  label={<Translate value={'JS_PAYER_CAML_BUSINESS'} />}
                />
              </RadioGroup>
            </FormControl>
          </InputWithLabel>

          <>
            {formData['camlEntityType'] === 'INDIVIDUAL' && renderIndiviualEntityTypes()}
            {formData['camlEntityType'] === 'BUSINESS' && renderBusinessEntityTypes()}
            {renderCommonFields()}
          </>
        </>
      )}
    </div>
  );
};

export default PayerAdditionalOptionalFields;
