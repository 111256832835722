import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RawHtmlContent } from '../Format';

const DocUploadInfo = ({ paymentTypeId }) => {
  return (
    <div className='doc-info-container'>
      <InfoOutlinedIcon className='mr-4' />
      <RawHtmlContent htmlContent={paymentTypeId + '_JS_UPLOAD_DOC_SPECS'} />
    </div>
  );
};

export default DocUploadInfo;
