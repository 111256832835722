import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteKycDocument, getAgentDetails, pledge, syncSession } from '../../apis';
import InputWithLabel from '../../components/InputWithLabel';
import { Translate } from '../../i18n/translate';
import { showModalWithFunctions } from '../../store/slices/modal/actions';
import { selectCountries, selectRefDataCache } from '../../store/slices/refdata/refdataSlice';
import {
  ROUTE_MAPPING,
  navigate,
  showPaymentSummaryAction,
} from '../../store/slices/router/routerSlice';
import {
  selectConsolBuyerRemitterPageEnabled,
  selectData,
  selectLocale,
  setBankCountry,
  setData,
} from '../../store/slices/session/sessionSlice';
import { getConfigValue } from '../../utils/helper';
import DocUploadSection from './docUploadSection';
import { API_NAME_MAPPING, ERROR_MESSAGES, PATTERNS } from './formHelper';
import PayerForm from './payerForm';
import {
  ATTORNEY_APPOINTER_OPTIONS,
  RELATIONS_TO_STUDENT_OTHER,
  RELATIONS_TO_STUDENT_VP_BANK,
} from './payerHelpers';
const GPFS_CONTEXT = '/geo-buyer'; //TODO: Find the exact value
const IS_APOLLO_3DS_ENABLED = 'false';

const PayerDetail = () => {
  const session = useSelector(selectData);
  const refDataCache = useSelector(selectRefDataCache);
  const refdataCountries = useSelector(selectCountries);
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const [documents, setDocuments] = useState([]);
  const [documentsVerified, setDocumentsVerified] = useState(false);
  const [countries, setCountries] = useState([]);
  const [iftiEnabled, setIftiEnabled] = useState(false);
  const [isApolloCreditCard, setIsApolloCreditCard] = useState(false);
  const [restrictPoBox, setRestrictPoBox] = useState(false);
  const [otherFieldsErrors, setOtherFeildsErrors] = useState({});
  const [otherFieldsValid, setOtherFieldsValid] = useState(false);
  const [formData, setFormData] = useState({
    terms: false,
    payerState: '',
  });
  const [errors, setErrors] = useState({});
  const [infoMessage, setInfoMessage] = useState('');
  const [routingCodeLabel, setRoutingCodeLabel] = useState(''); //TODO: Use this in optional fields

  const [agentClientEnabled, setAgentEnabled] = useState(false);
  const [agentClientsList, setAgentClientList] = useState([]);
  const [addressProof, setAddressProof] = useState('');
  const [model, setModel] = useState({
    terms: false,
    docQuality: false,
    selectedQuote: null,
    remitterDetails: {},
    fabDetails: {},
    payer: {
      who: '',
      relation: '',
      attorneyAppointer: '',
    },
    agentOption: 'NO',
    agent: null,
    paymentType: {
      id: '',
    },
    payType: null,
    session: session,
  });
  const [ref, setRef] = useState({
    isCAMLSectionVisible: false,
  });
  const consolBuyerRemitterPageEnabled = useSelector(selectConsolBuyerRemitterPageEnabled);
  const [relationships, setRelationships] = useState(RELATIONS_TO_STUDENT_OTHER);

  const intl = useIntl();

  useEffect(() => {
    dispatch(showPaymentSummaryAction());
    if (!session) {
      return;
    }

    let updatedModel = { ...model };
    let updatedFormData = { ...formData };

    updatedModel.selectedQuote = session.selectedQuote;
    updatedModel.paymentType = session.selectedQuote.paymentType;

    if (session.selectedQuote.paymentType.id === 'VPBank_VND') {
      updatedModel.payer.who = 'SOMEONE_ELSE';
      setRelationships(RELATIONS_TO_STUDENT_VP_BANK);
    }

    if (consolBuyerRemitterPageEnabled && session.selectedQuote.paymentType.id !== 'VPBank_VND') {
      updatedModel.payer.who = 'MYSELF';
    }

    setModel(updatedModel);

    if (!session.kycDocList || session.kycDocList.length === 0) {
      setDocumentsVerified(true);
    }

    getCountries();
    let isCAMLVisible = updatedModel.paymentType.id.indexOf('WIRE') === 0 || false;
    let updatedRef = {
      isCAMLVisible,
      displayCAMLMandatoryFields: getConfigValue(
        'BUYER.CAML.MADATORY.FIELDS.VISIBLE',
        refDataCache.configList,
      ),
      isPayerFieldsVisible: refDataCache.isPayerFieldsVisible,
      isCAMLSectionVisible:
        isCAMLVisible && (isGPXCan() || session.selectedServices.country === 'CAN'),
      sellerImg: GPFS_CONTEXT + '/_assets/logo?sellerId=' + session.clientId,
      GPX: session?.sellerInfo.sellerIndustry !== 'EDUCATION',
      paymentTypeFields: _.keyBy(session.paymentTypeFields.fieldList, 'fieldId'),
    };
    if (
      consolBuyerRemitterPageEnabled ||
      (updatedRef.GPX && !session.sellerInfo.gpxRemitterEnabled)
    ) {
      copyRemitterFromBuyer();
    }

    let iftiEnabledValue =
      !_.isNil(session.selectedQuote) &&
      !_.isNil(session.selectedQuote.paymentType) &&
      session.selectedQuote.paymentType.iftiEnabled;
    setIftiEnabled(iftiEnabledValue);

    updatedFormData.bankCountry = session.selectedServices.country;
    updatedFormData.language = locale.replace('-', '_');

    let apolloCreditCardValue =
      (IS_APOLLO_3DS_ENABLED === 'true' &&
        updatedModel.paymentType.isMcpCreditCard &&
        updatedModel.paymentType.id.indexOf('APOLLO_CREDIT') === 0) ||
      false;
    setIsApolloCreditCard(apolloCreditCardValue);

    if (apolloCreditCardValue || updatedRef?.paymentTypeFields['contactNo']?.visible) {
      //=> renderContactNo
      updatedFormData.dialingFromCountry = session.selectedServices.country;
    }

    if (isFieldVisible('payerCountry') || iftiEnabledValue || apolloCreditCardValue) {
      updatedFormData.payerCountry = session.selectedServices.country;

      if (updatedRef.isCAMLSectionVisible) {
        updatedFormData.contactCountryCode = session.selectedServices.country;
      }
    }

    setRestrictPoBox(
      session.sellerInfo.country === 'AUS' ||
        session.selectedServices.country === 'AUS' ||
        session.sellerInfo.country === 'NZL' ||
        session.selectedServices.country === 'NZL',
    );

    getState(session.buyerDetails.state);
    setRef(updatedRef);
    initRemitterBank(updatedModel, updatedFormData);
    fetchAgentDetails();
  }, []);

  useEffect(() => {
    generateInfoMessage();
  }, [model.paymentType]);

  useEffect(() => {
    if (session.selectedQuote.paymentType.enableDocCollection && session.kycDocList.length > 0) {
      generateDocList(session.kycDocList, session.selectedQuote.paymentType.id);
    }
  }, [model, addressProof, formData.fundingSource]);

  useEffect(() => {
    validateOtherFields(false);
  }, [model.payer.who, model.payer.relation, model.payer.attorneyAppointer, model.agent]);

  const fetchAgentDetails = async () => {
    const response = await getAgentDetails();
    if (response.success) {
      setAgentEnabled(!!response.data.agentEnabled);
      setAgentClientList(response.data.agentClients ?? []);
    }
  };

  const generateDocList = (kycDocList, paymentTypeId) => {
    let docSeqArray = paymentTypeId === 'VPBank_VND' ? [4, 5, 6, 7] : [];
    let docs = [];

    kycDocList.forEach((doc) => {
      if (doc.isEnabled && doc.reactConditionalExpression != null) {
        let conditionalExp =
          docSeqArray.includes(doc.docSeq) || docSeqArray.length === 0
            ? eval(doc.reactConditionalExpression)
            : false;
        if (conditionalExp) {
          let exisitingDoc = documents.find((e) => e.docSeq === doc.docSeq);

          if (exisitingDoc) {
            docs.push(exisitingDoc);
          } else {
            docs.push({
              required: doc.isMandatory,
              labelName: doc.description,
              docSeq: doc.docSeq,
              isVerified: false,
              isError: false,
              paymentTypeId: doc.paymentTypeId,
            });
          }
        }
      }
    });

    deletePreviousDocs(docs, documents);
    verifyDocs(docs);
    setDocuments(docs);
  };

  const deletePreviousDocs = async (newDocs, oldDocs) => {
    const docSeqs = newDocs.map((doc) => doc.docSeq);

    const deletePromises = [];

    oldDocs.forEach((doc) => {
      if (!docSeqs.includes(doc.docSeq) && doc.isUploaded) {
        deletePromises.push(deleteKycDocument(doc.docSeq));
      }
    });

    if (deletePromises.length) await Promise.all(deletePromises);
  };

  const getCountries = async () => {
    const sortedCountries = [...refdataCountries].sort((a, b) => {
      let aTranslated = intl.formatMessage({ id: a });
      let bTranslated = intl.formatMessage({ id: b });
      return aTranslated.localeCompare(bTranslated);
    });
    setCountries(sortedCountries);
  };

  const onRelationshipSelected = (e) => {
    setModel({ ...model, payer: { ...model.payer, relation: e.target.value } });
    setOtherFeildsErrors({ ...otherFieldsErrors, payerWho: '' });
  };

  const handlePayerTypeChange = (e) => {
    let payer = e.target.value;
    setModel({ ...model, payer: { ...model.payer, who: payer } });
    if (consolBuyerRemitterPageEnabled || (ref.GPX && !session.sellerInfo.gpxRemitterEnabled)) {
      return;
    }

    if (payer === 'MYSELF') {
      getAlertForStudentPayer();
      copyRemitterFromBuyer();
    } else {
      getAlertForSomeOneElsePayer();
      clearRemitter();
    }
    setOtherFeildsErrors({ ...otherFieldsErrors, payerType: '' });
  };

  const getAlertForStudentPayer = () => {
    if (model.paymentType.isMcpCreditCard) {
      displayWhoPaysMessage('MSG_MCP_STUDENT_AS_PAYER');
    } else if (isAchOrCad()) {
      getAlertForSomeOneElsePayer();
    } else {
      displayWhoPaysMessage('INR_BANK_ACCOUNT_JS_STUDENT_AS_PAYER');
    }
  };

  const getAlertForSomeOneElsePayer = () => {
    if (isAchOrCad()) {
      displayWhoPaysMessage(model.paymentType.id + '_JS_STUDENT_AS_PAYER');
    }
  };

  const displayWhoPaysMessage = (message) => {
    let tranlatedMessage = getTranslatedValue(message);
    dispatch(
      showModalWithFunctions({
        title: 'Note',
        body: tranlatedMessage,
        yesLabel: 'Ok',
      }),
    );
  };

  const legalRepChanged = (e) => {
    setModel({ ...model, payer: { ...model.payer, attorneyAppointer: e.target.value } });
    setOtherFeildsErrors({ ...otherFieldsErrors, legalRep: '' });
  };

  const agentChanged = (e) => {
    setModel({ ...model, agent: e.target.value });
    setOtherFeildsErrors({ ...otherFieldsErrors, agent: '' });
  };

  const updateDoc = (docSeq, data) => {
    let updatedDocs = documents.map((e) => (e.docSeq === docSeq ? { ...e, ...data } : e));
    setDocuments(updatedDocs);

    verifyDocs(updatedDocs);
  };

  const verifyDocs = (docs) => {
    if (!docs.length) {
      setDocumentsVerified(true);
      return;
    }
    let allVerified = docs
      .filter((doc) => doc.required) // Filter only required documents
      .every((doc) => doc.isVerified);
    setDocumentsVerified(allVerified);
  };

  const getTranslatedValue = (label) => {
    return intl.formatMessage({ id: label });
  };

  // Form related functions
  const getDynamicFieldLabel = (defaultLabel) => {
    let label = '';

    if (
      model.paymentType.id === 'INR_BANK_ACCOUNT' &&
      defaultLabel.includes('DOC_UPLOAD') &&
      (defaultLabel.includes('1') || defaultLabel.includes('2'))
    ) {
      label = model.paymentType.id + '_' + defaultLabel + '_' + addressProof;
    } else if (
      model.paymentType.id === 'VPBank_VND' &&
      defaultLabel.includes('DOC_UPLOAD') &&
      (defaultLabel.includes('6') || defaultLabel.includes('7'))
    ) {
      label = model.paymentType.id + '_' + defaultLabel + '_' + model.payer.relation;
    } else if (
      model.paymentType.id === 'VPBank_VND' &&
      defaultLabel.includes('JS_DOC_UPLOAD') &&
      (defaultLabel.includes('4') || defaultLabel.includes('5'))
    ) {
      label = model.paymentType.id + '_' + defaultLabel + '_' + addressProof;
    } else {
      label = model.paymentType.id + '_' + defaultLabel;
    }

    var translatedLabel = getTranslatedValue(label);
    if (translatedLabel == null || translatedLabel === label) {
      return getTranslatedValue(defaultLabel);
    }
    return translatedLabel;
  };

  const isCAMLFieldVisible = (fieldId) => {
    let isCAN =
      session?.selectedServices?.country === 'CAN' || session?.sellerInfo?.country === 'CAN';

    if (
      model.paymentType.id.indexOf('INR_BANK_ACCOUNT') === 0 ||
      model.paymentType.id.indexOf('VPBank_VND') === 0
    ) {
      if (fieldId == 'branchCode') return isPaymentFieldVisible(fieldId);

      if (fieldId == 'payerAccountNumber')
        return isFieldMandatory(fieldId) || isPaymentFieldVisible(fieldId);
    } else if (isCAN && model.paymentType.id.indexOf('WIRE') === 0) {
      if (fieldId == 'branchCode' || fieldId == 'bankSwiftCode') {
        return false;
      }
    }

    if (
      ref.isCAMLVisible ||
      model.paymentType.bankDetailsFixed ||
      model.paymentType.displayBankDetails
    ) {
      if (ref.isPayerFieldsVisible && !ref.GPX) {
        let complianceCountries = ref.payerComplianceCountries;
        if (complianceCountries) {
          let countriesArr = ref.payerComplianceCountries.split(',');
          if (countriesArr.indexOf(ref.sellerInfo.country) !== -1) {
            return isPayerFieldVisible(fieldId);
          }
        }

        if (isComplianceFieldMandatory(fieldId)) {
          return isPayerFieldVisible(fieldId);
        }
        if (
          ref.displayCAMLMandatoryFields === 'true' &&
          isCAN &&
          model.paymentType.id.indexOf('WIRE') === 0
        ) {
          return isPayerFieldVisible(fieldId);
        }
      } else {
        if (ref.GPX) {
          if (isCAN && model.paymentType.id.indexOf('WIRE') === 0) {
            if (fieldId == 'branchCode' || fieldId == 'bankSwiftCode') {
              return false;
            }
            return isPayerFieldVisible(fieldId);
          }
        }
        return false;
      }
    }
    return false;
  };

  const isPaymentFieldVisible = (fieldId) => {
    return (
      ref.paymentTypeFields &&
      ref.paymentTypeFields[fieldId] &&
      ref.paymentTypeFields[fieldId].visible
    );
  };

  const isPaymentFieldRequired = (fieldId) => {
    if (!ref.paymentTypeFields) return false;
    return ref.paymentTypeFields[fieldId] && ref.paymentTypeFields[fieldId].required;
  };

  const isFieldVisible = (field) => {
    return _(getField(field)).get('fieldVisible');
  };

  const isFieldMandatory = (field) => {
    return (
      _(getField(field)).get('fieldMandatory') ||
      ((formData['payerCountry'] === 'USA' ||
        formData['payerCountry'] === 'CAN' ||
        (formData['payerCountry'] === 'CHN' &&
          session.selectedQuote.paymentType.defaultPspId === 'WorldPay')) &&
        field === 'payerState')
    );
  };

  const getLabelforPayerName = () => {
    let payerNameLabel = '';
    if (model.paymentType.id.indexOf('APOLLO_CREDIT') == 0) {
      payerNameLabel = 'PAYER_NAME_LABEL_FOR_APOLLO_CC';
    } else if (model.paymentType.id.indexOf('GEOSWIFT_CREDIT') == 0) {
      payerNameLabel = 'PAYER_NAME_LABEL_FOR_GEOSWIFT_CC';
    } else {
      payerNameLabel = getDynamicFieldLabel('PAYER_NAME_LABEL');
    }
    return getTranslatedValue(payerNameLabel);
  };

  const getHelpTextForPayerName = () => {
    let payerNameHelperText = '';
    if (model.paymentType.id.indexOf('APOLLO_CREDIT') == 0) {
      payerNameHelperText = 'PAYER_NAME_PLACEHOLDER_FOR_APOLLO_CC';
    } else if (model.paymentType.id.indexOf('GEOSWIFT_CREDIT') == 0) {
      payerNameHelperText = 'PAYER_NAME_PLACEHOLDER_FOR_GEOSWIFT_CC';
    } else {
      payerNameHelperText = 'PAYER_NAME_PLACEHOLDER';
    }
    return getTranslatedValue(payerNameHelperText);
  };

  const getPaymentFieldValidationExpression = (fieldId) => {
    let validationExpression = '';
    if (ref.paymentTypeFields?.[fieldId]?.validationExpression) {
      // Convert string patterns in API to regex before passing to payerForm
      if (typeof ref.paymentTypeFields[fieldId].validationExpression === 'string') {
        validationExpression = new RegExp(ref.paymentTypeFields[fieldId].validationExpression);
      }
      return validationExpression ?? PATTERNS.SWIFT_REGEX;
    }
    return PATTERNS.SWIFT_REGEX;
  };

  const getLabelforPayer = (label) => {
    const _label = isApolloCreditCard ? label + '_FOR_APOLLO_CC' : label;
    return getTranslatedValue(_label);
  };

  const getAddressValidation = () => {
    if (
      model.paymentType.id.indexOf('WIRE') !== -1 ||
      model.paymentType.id.indexOf('CAD_TRUSTLY') !== -1 ||
      model.paymentType.id.indexOf('CAD_ONLINE_BANKING') !== -1
    ) {
      if (session.sellerInfo.country === 'CAN' || formData['payerCountry'] === 'CAN') {
        return PATTERNS.ADDRESS_REGEX;
      }
    }
    if (restrictPoBox) {
      return PATTERNS.ADDRESS_REGEX;
    }
    return PATTERNS.SWIFT_ADDRESS_REGEX;
  };

  const getAddressError = () => {
    if (['WIRE', 'CAD_TRUSTLY', 'CAD_ONLINE_BANKING'].includes(model.paymentType.id)) {
      if (session.sellerInfo.country === 'CAN' || formData['payerCountry'] === 'CAN') {
        return ERROR_MESSAGES.CAML_ADDRESS;
      }
    }
    if (restrictPoBox) {
      return ERROR_MESSAGES.CAML_ADDRESS;
    }
    return ERROR_MESSAGES.PATTERN;
  };

  const getValidationExpressionForZipCode = () => {
    if (formData['payerCountry'] === 'USA') {
      return PATTERNS.USA_ZIP_CODE_REGEX;
    } else if (formData['payerCountry'] === 'CAN') {
      return PATTERNS.CAN_ZIP_CODE_REGEX;
    } else if (formData['payerCountry'] === 'MEX') {
      return PATTERNS.FIVE_DEGITS_ONLY_REGEX;
    } else if (isApolloCreditCard) {
      return PATTERNS.ALPHA_NUMERIC;
    }

    return PATTERNS.SWIFT_REGEX;
  };

  const invalidZipCodeErrorMsg = () => {
    if (formData['payerCountry'] === 'USA') {
      return ERROR_MESSAGES.USA_ZIP_CODE;
    } else if (formData['payerCountry'] === 'CAN') {
      return ERROR_MESSAGES.CAN_ZIP_CODE;
    } else if (formData['payerCountry'] === 'MEX') {
      return ERROR_MESSAGES.MEX_ZIP_CODE;
    } else if (isApolloCreditCard) {
      return ERROR_MESSAGES.ALPHA_NUMERIC;
    }
    return ERROR_MESSAGES.SWIFT_REGEX;
  };

  const isUsAch = () => {
    return model.paymentType.id == 'US_ACH';
  };

  const isUsOnlineBanking = () => {
    return model.paymentType.id == 'US_ONLINE_BANKING';
  };

  const isGPXCan = () => {
    return session.sellerInfo.country === 'CAN' || formData['payerCountry'] === 'CAN';
  };

  const isGPXCaml = () => {
    if (formData['payerCountry'] === 'CAN') {
      return true;
    }
    let countriesArr = 'USA,MEX,CAN'.split(',');
    return (
      session.sellerInfo.country === 'CAN' && countriesArr.indexOf(formData['payerCountry']) !== -1
    );
  };

  const isComplianceFieldMandatory = (field) => {
    const optionalFieldForNonNACountries = field === 'payerBankState' || field === 'payerBankZip';
    const isNAPayer = _.includes(['USA', 'MEX', 'CAN'], formData['payerCountry']);
    return isComplianceFieldVisible(field) && !(optionalFieldForNonNACountries && !isNAPayer);
  };

  const isComplianceFieldVisible = (field) => {
    return _.includes(_.chain(session).get('sellerInfo.complianceMandatoryFields').value(), field);
  };

  const getLabelForCaState = (label) => {
    return label + '_CAN';
  };

  const getValidationExpressionForBankZipCode = () => {
    if (formData['bankCountry'] === 'USA') {
      return PATTERNS.USA_ZIP_CODE_REGEX;
    } else if (formData['bankCountry'] === 'CAN') {
      return PATTERNS.CAN_ZIP_CODE_REGEX;
    } else if (formData['bankCountry'] === 'MEX') {
      return PATTERNS.FIVE_DEGITS_ONLY_REGEX;
    }

    return PATTERNS.SWIFT_REGEX;
  };

  const invalidBankZipCodeErrorMsg = () => {
    if (formData['bankCountry'] === 'USA') {
      return ERROR_MESSAGES.USA_ZIP_CODE;
    } else if (formData['bankCountry'] === 'CAN') {
      return ERROR_MESSAGES.CAN_ZIP_CODE;
    } else if (formData['bankCountry'] === 'MEX') {
      return ERROR_MESSAGES.MEX_ZIP_CODE;
    } else if (isApolloCreditCard) {
      return ERROR_MESSAGES.ALPHA_NUMERIC;
    }
    return ERROR_MESSAGES.SWIFT_REGEX;
  };

  const isPayerFieldVisible = (field) => {
    return (
      ref.isPayerFieldsVisible ||
      isFieldMandatory(field) ||
      isComplianceFieldVisible(field) ||
      ref.isCAMLVisible ||
      isPaymentFieldVisible(field)
    );
  };

  const getField = (field) => {
    return _.chain(session)
      .get('sellerInfo.remitterFieldMetadata.fieldMetadata')
      .find(function (fieldMeta) {
        return _(fieldMeta).get('key.fieldName') === field;
      })
      .value();
  };

  //end of form related functions

  //other functions from old code starts
  const generateInfoMessage = () => {
    let messageKey = '';
    if (model.paymentType.isMcpCreditCard) {
      messageKey = 'MSG_MCP_STUDENT_AS_PAYER';
    } else if (isAchOrCad()) {
      messageKey = model.paymentType.id + '_STUDENT_AS_PAYER';
    } else if (model.paymentType.id == 'INR_BANK_ACCOUNT') {
      messageKey = 'INR_BANK_ACCOUNT_JS_STUDENT_AS_PAYER';
    } else {
      messageKey = 'MSG_CONSOL_VIEW_PAYER';
    }

    setInfoMessage(getTranslatedValue(messageKey));
  };

  const isAchOrCad = () => {
    return model.paymentType.id == 'US_ACH' || model.paymentType.id == 'CAD_TRUSTLY';
  };

  const copyRemitterFromBuyer = () => {
    let updatedFormData = {};

    if (isPaymentFieldVisible('firstName')) {
      updatedFormData.firstName = session.buyerDetails.firstName ?? '';
    }
    if (isPaymentFieldVisible('lastName')) {
      updatedFormData.lastName = session.buyerDetails.lastName ?? '';
    }
    if (!isPaymentFieldVisible('firstName') && !isPaymentFieldVisible('lastName')) {
      updatedFormData.payerName =
        session.buyerDetails.firstName + ' ' + session.buyerDetails.lastName;
    }

    updatedFormData.payerAddress = session.buyerDetails.address ?? '';
    updatedFormData.payerCity = session.buyerDetails.city ?? '';
    updatedFormData.payerCountry = session.buyerDetails.country ?? '';
    const address = session.buyerDetails.address;
    if (address && address.lastIndexOf(', ') != -1) {
      updatedFormData.payerAddress = address.substring(0, address.lastIndexOf(','));
      updatedFormData.payerAddress2 = address.substring(
        address.lastIndexOf(', ') + 2,
        address.length,
      );
    } else {
      updatedFormData.payerAddress = address;
    }
    updatedFormData.payerState = getState(session.buyerDetails.state, session.buyerDetails.country);

    updatedFormData.payerZip = session.buyerDetails.zip ?? '';
    if (_.indexOf(refDataCache.allCountries, updatedFormData.payerCountry) == -1) {
      updatedFormData.payerCountry = '';
    }

    if (
      updatedFormData.payerCountry === 'CHN' &&
      session.selectedQuote.paymentType.defaultPspId === 'WorldPay'
    ) {
      updatedFormData.payerState = '';
    }

    updatedFormData.email = session.buyerDetails.email;
    setFormData({
      ...formData,
      ...updatedFormData,
    });
  };

  const clearRemitter = () => {
    let updatedFormData = {
      firstName: '',
      lastName: '',
      payerName: '',
      payerAddress: '',
      payerAddress2: '',
      payerCity: '',
      payerState: '',
      payerZip: '',
      email: '',
      branchCode: '',
      bankSwiftCode: '',
      idNumber: '',
      dateOfBirth: '',
      nationality: '',
    };

    if (!(isFieldVisible('payerCountry') || iftiEnabled || isApolloCreditCard)) {
      updatedFormData.payerCountry = '';
    }

    setFormData({
      ...formData,
      ...updatedFormData,
    });
  };

  const initRemitterBank = (model, latestFormaData) => {
    let updatedFormData = {
      payerBankName: model.paymentType.defaultBankName,
      payerBankAddress: model.paymentType.defaultBankAddress1,
      payerBankAddress2: model.paymentType.defaultBankAddress2,
      payerBankCity: model.paymentType.defaultBankCity,
      payerBankState: model.paymentType.defaultBankState,
      payerBankZip: model.paymentType.defaultBankZip,
      bankCountry: session.selectedServices.country,
      language: locale.replace('-', '_'),
    };
    setFormData({
      ...latestFormaData,
      ...updatedFormData,
    });

    changeLabel();
  };

  const changeLabel = () => {
    let newRoutingCodeLabel = '';
    switch (session.selectedServices.country) {
      case 'USA':
        newRoutingCodeLabel = 'Fedwire Routing Code';
        break;
      case 'AUS':
        newRoutingCodeLabel = 'BSB Number';
        break;
      case 'CAN':
        newRoutingCodeLabel = 'Transit Number';
        break;
      case 'GBR':
        newRoutingCodeLabel = 'Sort Code';
        break;
      case 'IRL':
        newRoutingCodeLabel = 'Sort Code';
        break;
      default:
        newRoutingCodeLabel = 'Routing Code';
    }

    setRoutingCodeLabel(newRoutingCodeLabel);
  };

  const getState = (state, countryCode) => {
    let tempState = null;

    refDataCache.provinceList.forEach((item) => {
      let filterState = getTranslatedValue(item.countryCode + '_' + item.name);
      if (filterState == state || item.name == state) {
        tempState = item.name;
      }
    });

    const provinceListCountries = _.reduce(
      refDataCache.provinceList,
      (acc, item) => {
        if (acc.indexOf(item.countryCode) == -1) {
          acc.push(item.countryCode);
        }
        return acc;
      },
      [],
    );

    if (provinceListCountries.indexOf(countryCode) != -1) {
      return tempState != null ? tempState : '';
    }
    return state;
  };

  const submit = async () => {
    // set bankcountry from formData in store
    let countryBank = formData['bankCountry'];
    dispatch(setBankCountry(countryBank));

    let data = prepareSubmitData();
    let response = await pledge(data);
    if (response.success) {
      response = response.data;
      if (!response.error) {
        reSyncSession();
      } else if (response.errorCode == 'KYC_FAILED') {
        if (response.kycFailCount > 2) {
          // TODO: show dialog with NOTE: KYC_FAILED_RE_DIRECT and ok button
        } else {
          // TODO: show dialog with NOTE: KYC_FAILED_MSG and ok button
        }
      } else if (response?.validationResult?.errors != null) {
        let validationErrors = {};
        response.validationResult.errors.forEach((e) => {
          validationErrors[e.fieldName] = <Translate value={'JS_' + e.errorCode} />;
        });
        setErrors({ ...errors, ...validationErrors });
      }
    }
  };

  const reSyncSession = async () => {
    const response = await syncSession();
    if (response.success) {
      dispatch(setData(response.data));
      dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT }));
    }
  };

  const prepareSubmitData = () => {
    let data = {
      remitter: {},
      trackingId: '',
      affiliateUri: '',
      affiliateAgentId: '',
      marketingOptIn: formData['optInMarketingConsentCheckbox'],
    };
    Object.keys(formData).map((key) => {
      let apiKeyName = !API_NAME_MAPPING[key] ? key : API_NAME_MAPPING[key];
      data.remitter[apiKeyName] = formData[key];
    });
    delete data.remitter.terms;
    data.language = formData['language'];
    data.remitter.payer = model.payer.who === 'SOMEONE_ELSE' ? model.payer.relation : 'g';

    if (model.agentOption === 'YES' && !!model.agent) {
      data.remitter.payerAgentId = model.agent.id;
      data.remitter.payerAgentName = model.agent.name;
    }
    return data;
  };

  const validateOtherFields = (showErrors) => {
    let otherErrors = {
      payerType: !model.payer.who ? ERROR_MESSAGES.required : '',
      payerWho:
        model.payer.who !== 'MYSELF' && !model.payer.relation ? ERROR_MESSAGES.required : '',
      legalRep:
        model.payer.relation === 'LEGAL_REPRESENTATIVE' && !model.payer.attorneyAppointer
          ? ERROR_MESSAGES.required
          : '',
      agent: model.agentOption === 'YES' && !model.agent ? ERROR_MESSAGES.required : '',
    };

    let formValid = Object.keys(otherErrors).some((fieldName) => otherErrors[fieldName] !== '');

    setOtherFieldsValid(!formValid);

    if (showErrors) {
      setOtherFeildsErrors({ ...otherFieldsErrors, ...otherErrors });
    }
  };

  return (
    <div className='container py-5 max-w-[750px]'>
      {(!consolBuyerRemitterPageEnabled || model.paymentType.id === 'VPBank_VND') && (
        <h4 className='text-16-bold mb-3'>
          <Translate value='PAYER_DETAILS' />
        </h4>
      )}

      {/* Section - 1: Relationship section */}
      {((!consolBuyerRemitterPageEnabled && !ref.GPX) || session.sellerInfo.gpxRemitterEnabled) &&
        model.paymentType.id !== 'VPBank_VND' && (
          <InputWithLabel
            label='JS_WHO_IS_MAKING_PAYMENT'
            required={true}
            error={!!otherFieldsErrors['payerType']}
          >
            <FormControl>
              <RadioGroup
                name={'payerType'}
                className='flex-row justify-between'
                onChange={handlePayerTypeChange}
                value={model.payer.who}
              >
                <FormControlLabel
                  value='MYSELF'
                  control={<Radio />}
                  label={<Translate value='JS_STUDENT_LABEL' />}
                />
                <FormControlLabel
                  value='SOMEONE_ELSE'
                  control={<Radio />}
                  label={<Translate value='JS_SOMEONE_ELSE_LABEL' />}
                />
              </RadioGroup>
            </FormControl>
          </InputWithLabel>
        )}

      {model.payer.who === 'SOMEONE_ELSE' && (
        <InputWithLabel
          label={
            model.paymentType.id !== 'VPBank_VND'
              ? 'JS_HOW_YOU_RELATE_TO_STUDENT'
              : 'JS_HOW_YOU_RELATE_TO_STUDENT_VPBANK'
          }
          required={true}
        >
          <FormControl fullWidth error={!!otherFieldsErrors['payerWho']}>
            <Select name='payerWho' value={model.payer.relation} onChange={onRelationshipSelected}>
              {relationships.map(
                (option, index) =>
                  eval(option.renderCondition) && (
                    <MenuItem key={index} value={option.value}>
                      <Translate value={option.displayName} />
                    </MenuItem>
                  ),
              )}
            </Select>
          </FormControl>
        </InputWithLabel>
      )}

      {model.payer.relation === 'LEGAL_REPRESENTATIVE' && (
        <InputWithLabel label='JS_STUDENT_ATTORNEY_APPOINTER' required={true}>
          <FormControl fullWidth>
            <Select
              name='legalRep'
              value={model.payer.attorneyAppointer}
              onChange={legalRepChanged}
              error={!!otherFieldsErrors['legalRep']}
            >
              {ATTORNEY_APPOINTER_OPTIONS.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  <Translate value={option.displayName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputWithLabel>
      )}

      {/* Agent client fields */}
      {agentClientEnabled && (
        <div>
          <InputWithLabel label='JS_AGENT_DETAILS'>
            <FormControl>
              <RadioGroup
                name={'agentOption'}
                className='flex-row justify-between'
                onChange={(e) => setModel({ ...model, agentOption: e.target.value })}
                value={model.agentOption}
              >
                <FormControlLabel
                  value='NO'
                  control={<Radio />}
                  label={<Translate value='JS_AGENT_NO_LABEL' />}
                />
                <FormControlLabel
                  value='YES'
                  control={<Radio />}
                  label={<Translate value='JS_AGENT_YES_LABEL' />}
                />
              </RadioGroup>
            </FormControl>
          </InputWithLabel>

          {model.agentOption === 'YES' && (
            <InputWithLabel label='JS_AGENT_NAME_LABEL' required={true}>
              <FormControl fullWidth error={!!otherFieldsErrors['agent']}>
                <Select name='agent' value={model.agent} onChange={agentChanged}>
                  {agentClientsList.map((agent, index) => (
                    <MenuItem key={index} value={agent}>
                      {agent.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputWithLabel>
          )}
        </div>
      )}

      {consolBuyerRemitterPageEnabled && (
        <>
          {model.paymentType.id !== 'VPBank_VND' && (
            <h4 className='text-16-bold mb-3'>
              <Translate value='PAYER_DETAILS' />
            </h4>
          )}
          <div className='p-5 mt-1 bg-warning mb-1'>
            <Translate className={'font-bold'} value='JS_Note_Emphasis' />
            {infoMessage}
          </div>
        </>
      )}

      {/* ALI pay doc upload */}
      {model.paymentType.id === 'ALIPAY_DIRECT_CHINA' && (
        <DocUploadSection
          getDynamicFieldLabel={getDynamicFieldLabel}
          model={model}
          documents={documents}
          updateDoc={updateDoc}
          handleAddressProofChange={(e) => setAddressProof(e.target.value)}
          identificationDocumentTypes={
            ref.paymentTypeFields?.['identificationType']?.optionValues || []
          }
        />
      )}

      {/* Section-2 :Doc upload section */}
      {/* Doc collection for VPBank_VND */}
      {model.paymentType.id === 'VPBank_VND' &&
        model.paymentType.enableDocCollection &&
        model.payer.relation && (
          <DocUploadSection
            getDynamicFieldLabel={getDynamicFieldLabel}
            model={model}
            documents={documents}
            updateDoc={updateDoc}
            handleAddressProofChange={(e) => setAddressProof(e.target.value)}
          />
        )}

      <PayerForm
        model={model}
        getDynamicFieldLabel={getDynamicFieldLabel}
        isCAMLFieldVisible={isCAMLFieldVisible}
        isPaymentFieldVisible={isPaymentFieldVisible}
        isPaymentFieldRequired={isPaymentFieldRequired}
        isFieldVisible={isFieldVisible}
        isApolloCreditCard={isApolloCreditCard}
        iftiEnabled={iftiEnabled}
        isFieldMandatory={isFieldMandatory}
        getLabelforPayerName={getLabelforPayerName}
        getHelpTextForPayerName={getHelpTextForPayerName}
        getPaymentFieldValidationExpression={getPaymentFieldValidationExpression}
        countries={countries}
        languages={refDataCache.languages}
        stateProvinces={
          refDataCache.provinceList
            ?.filter((e) => e.countryCode === formData['payerCountry'])
            .map((p) => {
              return {
                ...p,
                tName: intl.formatMessage({ id: p.countryCode + '_' + p.name }),
              };
            })
            .sort((p1, p2) => (p1.tName > p2.tName ? 1 : -1)) ?? []
        }
        bankStateProvinces={
          refDataCache.provinceList
            ?.filter((e) => e.countryCode === formData['bankCountry'])
            .map((p) => {
              return {
                ...p,
                tName: intl.formatMessage({ id: p.countryCode + '_' + p.name }),
              };
            })
            .sort((p1, p2) => (p1.tName > p2.tName ? 1 : -1)) ?? []
        }
        payerFeeCountries={session.sellerInfo.payerFeeCountries}
        payerCountries={session.sellerInfo.payerCountries}
        currencies={session.sellerInfo.currencies}
        renderContactNo={isApolloCreditCard || isPaymentFieldVisible('contactNo')}
        getLabelforPayer={getLabelforPayer}
        getAddressValidation={getAddressValidation}
        getAddressError={getAddressError}
        getValidationExpressionForZipCode={getValidationExpressionForZipCode}
        invalidZipCodeErrorMsg={invalidZipCodeErrorMsg}
        isUsAch={isUsAch}
        isUsOnlineBanking={isUsOnlineBanking}
        isGPXCan={isGPXCan}
        isGPXCaml={isGPXCaml}
        isComplianceFieldMandatory={isComplianceFieldMandatory}
        getValidationExpressionForBankZipCode={getValidationExpressionForBankZipCode}
        invalidBankZipCodeErrorMsg={invalidBankZipCodeErrorMsg}
        getLabelForCaState={getLabelForCaState}
        refs={ref}
        formData={formData}
        documentsVerified={documentsVerified}
        setFormData={setFormData}
        submit={submit}
        errors={errors}
        setErrors={setErrors}
        validateOtherFields={validateOtherFields}
        otherFieldsValid={otherFieldsValid}
        documents={documents}
        updateDoc={updateDoc}
        setAddressProof={setAddressProof}
        routingCodeLabel={routingCodeLabel}
      />
    </div>
  );
};

export default PayerDetail;
