import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useState } from 'react';
import { Translate } from '../../i18n/translate';
import InputWithLabel from '../InputWithLabel';

const DatePickerDropdown = ({ label, minDate, maxDate, required, onChange }) => {
  const [selectedDate, setSelectedDate] = useState({
    day: '',
    month: '',
    year: '',
  });
  const [dateErrorMessage, setDateErrorMessage] = useState('');

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 101 }, (_, i) => currentYear - i);

  const handleDateChange = (event, field) => {
    setSelectedDate({ ...selectedDate, [field]: event.target.value });
  };

  const handleBlur = () => {
    isValidDate();
  };

  const isValidDate = () => {
    const { day, month, year } = selectedDate;
    if (required && (!day || !month || !year)) {
      setDateErrorMessage('Date is required');
      onChange('');
      return false; // Required field validation
    }

    if (minDate) {
      const selectedDateObj = new Date(year, month - 1, day);

      if (selectedDateObj < minDate) {
        setDateErrorMessage('PM_MIN_DATE_ERROR');
        onChange('');
        return false; // Min date validation
      }
    }

    if (maxDate) {
      const selectedDateObj = new Date(year, month - 1, day);

      if (selectedDateObj > maxDate) {
        setDateErrorMessage('PM_MAX_DATE_ERROR');
        onChange('');
        return false; // Max date validation
      }
    }

    checkDateValidity();
  };

  const formatDate = (day, month, year) => {
    return [day.toString().padStart(2, '0'), month.toString().padStart(2, '0'), year].join('/');
  };

  const checkDateValidity = () => {
    const { day, month, year } = selectedDate;
    if (day && month && year) {
      const selectedDate = new Date(year, month - 1, day); // Month is 0-based
      if (
        selectedDate.getFullYear() === year &&
        selectedDate.getMonth() === month - 1 &&
        selectedDate.getDate() === day
      ) {
        setDateErrorMessage('');
        onChange(formatDate(day, month, year)); //If valid date, send to parent form
        return selectedDate.toDateString();
      }
    }

    onChange('');
    setDateErrorMessage('PM_INVALID_DATE_ERROR');
    return 'Impossible';
  };

  return (
    <InputWithLabel label={label} required={required}>
      <div className='flex items-center justify-center gap-1'>
        <FormControl variant='outlined' fullWidth error={!!dateErrorMessage}>
          <InputLabel htmlFor='dateof_birth_day'>
            <Translate value='Day' />
          </InputLabel>
          <Select
            label='Day'
            value={selectedDate.day}
            onChange={(e) => handleDateChange(e, 'day')}
            onBlur={handleBlur}
            inputProps={{
              name: 'day',
              id: 'day',
            }}
            id='dateof_birth_day'
          >
            <MenuItem value='' disabled>
              <em>Day</em>
            </MenuItem>
            {days.map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant='outlined' fullWidth error={!!dateErrorMessage}>
          <InputLabel htmlFor='dateof_birth_month'>
            <Translate value='Month' />
          </InputLabel>
          <Select
            id='dateof_birth_month'
            label='Month'
            value={selectedDate.month}
            onChange={(e) => handleDateChange(e, 'month')}
            onBlur={handleBlur}
            inputProps={{
              name: 'month',
              id: 'month',
            }}
          >
            <MenuItem value='' disabled>
              <em>Month</em>
            </MenuItem>
            {months.map((month, index) => (
              <MenuItem key={index} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant='outlined' fullWidth error={!!dateErrorMessage}>
          <InputLabel htmlFor='dateof_birth_year'>
            <Translate value='Year' />
          </InputLabel>
          <Select
            id='dateof_birth_year'
            label='Year'
            value={selectedDate.year}
            onChange={(e) => handleDateChange(e, 'year')}
            onBlur={handleBlur}
            inputProps={{
              name: 'year',
              id: 'year',
            }}
          >
            <MenuItem value='' disabled>
              Year
            </MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <div>
          <strong>Selected Date:</strong> {formattedDate()}
        </div> */}
      </div>
      {dateErrorMessage && (
        <FormHelperText error>
          <Translate value={dateErrorMessage} />
        </FormHelperText>
      )}
    </InputWithLabel>
  );
};

export default DatePickerDropdown;
