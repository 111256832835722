import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RawHtmlContent } from '../../components/Format';
import { Translate } from '../../i18n/translate';
import { selectRefDataCache } from '../../store/slices/refdata/refdataSlice';
import {
  selectConsolBuyerRemitterPageEnabled,
  selectLoading,
} from '../../store/slices/session/sessionSlice';
import ConslidatedContext from '../ConsolidatedPage/consolidatedContext';
import DocUploadSection from './docUploadSection';
import { ERROR_MESSAGES, PATTERNS } from './formHelper';
import PayerAdditionalOptionalFields from './payerAdditionalOptionalFields';
import PayerAddressSection from './payerAddressSection';
import PayerBankDetailsSection from './payerBankDetailsSection';
import PayerFormContext from './payerFormContext';
import PayerPersonalDetailsSection from './payerPersonalDetailsSection';

const PayerForm = ({
  model,
  getDynamicFieldLabel,
  isCAMLFieldVisible,
  isPaymentFieldVisible,
  isPaymentFieldRequired,
  isFieldVisible,
  isApolloCreditCard,
  iftiEnabled,
  isFieldMandatory,
  getLabelforPayerName,
  getHelpTextForPayerName,
  getPaymentFieldValidationExpression,
  countries,
  languages,
  stateProvinces,
  bankStateProvinces,
  payerCountries,
  payerFeeCountries,
  renderContactNo,
  getLabelforPayer,
  getAddressValidation,
  getAddressError,
  getValidationExpressionForZipCode,
  invalidZipCodeErrorMsg,
  isUsAch,
  isUsOnlineBanking,
  isGPXCan,
  isGPXCaml,
  isComplianceFieldMandatory,
  getValidationExpressionForBankZipCode,
  invalidBankZipCodeErrorMsg,
  getLabelForCaState,
  refs,
  formData,
  documentsVerified,
  setFormData,
  submit,
  errors,
  setErrors,
  validateOtherFields,
  otherFieldsValid,
  documents,
  updateDoc,
  setAddressProof,
  routingCodeLabel,
  currencies,
}) => {
  const [validations, setValidations] = useState({});
  const [isFormValid, setFormValid] = useState(false);
  const refDataCache = useSelector(selectRefDataCache);
  const consolBuyerRemitterPageEnabled = useSelector(selectConsolBuyerRemitterPageEnabled);
  const consolidatedContext = useContext(ConslidatedContext);
  const isAPIInProgress = useSelector(selectLoading);

  let buyerSubmitRef;
  let isStudentFormValid;
  let isBuyerFormSubmitted;
  let setIsPayerFormValid;
  if (consolidatedContext) {
    ({ buyerSubmitRef, isStudentFormValid, isBuyerFormSubmitted, setIsPayerFormValid } =
      consolidatedContext);
  }

  useEffect(() => {
    hasValidationErrors();
  }, [formData, errors, validations]);

  useEffect(() => {
    if (consolBuyerRemitterPageEnabled) {
      setIsPayerFormValid(isFormValid && documentsVerified && otherFieldsValid);
    }
  }, [isFormValid, documentsVerified, otherFieldsValid]);

  useEffect(() => {
    if (isBuyerFormSubmitted && consolBuyerRemitterPageEnabled) {
      // => buyer form was just submitted
      submit();
    }
  }, [isBuyerFormSubmitted]);

  const sharedProps = {
    model,
    getDynamicFieldLabel,
    isCAMLFieldVisible,
    isPaymentFieldVisible,
    isPaymentFieldRequired,
    isDfTxn: formData['payerCountry'] === 'USA' && 'USA' !== model?.session?.sellerInfo?.country,
    isFieldVisible,
    isApolloCreditCard,
    iftiEnabled,
    isFieldMandatory,
    getLabelforPayerName,
    getHelpTextForPayerName,
    getPaymentFieldValidationExpression,
    countries,
    languages,
    stateProvinces,

    bankStateProvinces,
    payerCountries,
    payerFeeCountries,
    renderContactNo,
    getLabelforPayer,
    getAddressValidation,
    getAddressError,
    getValidationExpressionForZipCode,
    invalidZipCodeErrorMsg,
    isUsAch,
    isUsOnlineBanking,
    isGPXCan,
    isGPXCaml,
    isComplianceFieldMandatory,
    getValidationExpressionForBankZipCode,
    invalidBankZipCodeErrorMsg,
    getLabelForCaState,
    refs,
    routingCodeLabel,
    currencies,
    //from payer form
    formData,
    documentsVerified,
    errors,
    validations,
  };

  useEffect(() => {
    addValidations();
  }, [model, formData.payerCountry]);

  const handleInputChange = (event, keyname) => {
    const { name, value } = event.target;
    if (event.target.type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: event.target.checked,
      });
      handleBlur(event);
    } else {
      // To get the value of a specific key when e.target.value is an object. For eg, dialCode country
      let val = typeof value === 'object' && keyname ? value[keyname] : value;
      setFormData({
        ...formData,
        [name]: val ? val.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '') : '',
      });
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const error = validateField(name, value);
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const hasValidationErrors = () => {
    let formValid = Object.keys(validations).some((fieldName) => {
      let conditionToRender = validations[fieldName].renderCondition;
      if (typeof conditionToRender === 'string' ? eval(conditionToRender) : conditionToRender) {
        const error = validateField(fieldName, formData[fieldName]);
        return !!error;
      }
      return false;
    });

    setFormValid(!formValid);
  };

  const showAllValidationErrors = () => {
    let allErrors = {};
    Object.keys(validations).map((fieldName) => {
      if (validations[fieldName].renderCondition) {
        const error = validateField(fieldName, formData[fieldName]);
        allErrors[fieldName] = error;
      }
    });

    setErrors({
      ...errors,
      ...allErrors,
    });
  };

  const submitForm = () => {
    if (consolBuyerRemitterPageEnabled) {
      buyerSubmitRef.current.click();
    }
    if (
      !isFormValid ||
      !documentsVerified ||
      !otherFieldsValid ||
      (consolBuyerRemitterPageEnabled && !isStudentFormValid)
    ) {
      showAllValidationErrors();
      validateOtherFields(true);
      return;
    }

    if (consolBuyerRemitterPageEnabled && !isBuyerFormSubmitted) {
      return;
    }

    submit();
  };

  const validateField = (name, value) => {
    const fieldValidations = validations[name];
    let error = '';
    if (fieldValidations) {
      if (
        fieldValidations.required &&
        ((typeof value === 'string' && !value?.trim()) ||
          (typeof value === 'boolean' && !value) ||
          value == undefined)
      ) {
        error = ERROR_MESSAGES.required;
      } else if (
        value &&
        fieldValidations.pattern?.value &&
        !fieldValidations.pattern.value?.test(value)
      ) {
        error = fieldValidations.pattern.message;
      } else if (
        value &&
        fieldValidations.minLength &&
        value.length < fieldValidations.minLength.value
      ) {
        error = fieldValidations.minLength.message;
      }

      //TODO: if date, handle min date, max date, invalid date
    }

    return error;
  };

  const addValidations = () => {
    let validations = {
      terms: {
        required: true,
        renderCondition: true,
      },
      docQuality: {
        required: true,
        renderCondition: model.paymentType.enableDocCollection,
      },
      //--------
      firstName: {
        required: isPaymentFieldRequired('firstName'),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: isPaymentFieldVisible('firstName'),
        disableCondition: false,
      },
      middleName: {
        required: isPaymentFieldRequired('middleName'),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: isPaymentFieldVisible('middleName'),
        disableCondition: false,
      },
      lastName: {
        required: isPaymentFieldRequired('lastName'),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: isPaymentFieldVisible('lastName'),
        disableCondition: false,
      },
      payerName: {
        required: isFieldMandatory('payerName') || iftiEnabled || isApolloCreditCard,
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: isFieldVisible('payerName') || iftiEnabled || isApolloCreditCard,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      idNumber: {
        required: isPaymentFieldRequired('idNumber'),
        pattern: {
          value: getPaymentFieldValidationExpression('idNumber'),
          message: getDynamicFieldLabel('JS_PAYER_ID_NUMBER_ERROR_MSG'),
        },
        renderCondition: isPaymentFieldVisible('idNumber'),
        disableCondition: false,
      },
      dateOfBirth: {
        required: isPaymentFieldRequired('dateOfBirth'),
        renderCondition: isPaymentFieldVisible('dateOfBirth'),
        disableCondition: false,
      },
      nationality: {
        required: isPaymentFieldRequired('nationality'),
        renderCondition: isPaymentFieldVisible('nationality'),
        disableCondition: false,
      },
      email: {
        required: true,
        pattern: {
          value: PATTERNS.email,
          message: ERROR_MESSAGES.email,
        },
        renderCondition: true,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      dialingFromCountry: {
        required: isPaymentFieldRequired('contactNo'),
        renderCondition: renderContactNo,
        disableCondition: false,
      },
      contactNo: {
        required: isPaymentFieldRequired('contactNo'),
        pattern: {
          value: PATTERNS.CONTACT_NO_REGEX,
          message: ERROR_MESSAGES.CONTACT_NO_REGEX,
        },
        renderCondition: renderContactNo,
        disableCondition: false,
      },
      //Address section
      payerAddress: {
        required: isFieldMandatory('payerAddress') || iftiEnabled || isApolloCreditCard,
        pattern: {
          value: getAddressValidation(),
          message: getAddressError(),
        },
        renderCondition: isFieldVisible('payerAddress') || iftiEnabled || isApolloCreditCard,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      payerAddress2: {
        required: isFieldMandatory('payerAddress2'),
        pattern: {
          value: getAddressValidation(),
          message: getAddressError(),
        },
        renderCondition: !isApolloCreditCard && isFieldVisible('payerAddress2'),
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      payerCity: {
        required: isFieldMandatory('payerCity') || iftiEnabled || isApolloCreditCard,
        pattern: {
          value: getAddressValidation(),
          message: getAddressError(),
        },
        renderCondition: isFieldVisible('payerCity') || iftiEnabled || isApolloCreditCard,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      payerState: {
        required: !isApolloCreditCard && (isFieldMandatory('payerState') || isGPXCaml()),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: isFieldVisible('payerState') || isApolloCreditCard,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      payerZip: {
        required:
          (!isApolloCreditCard && isFieldMandatory('payerZip')) ||
          isUsAch() ||
          isUsOnlineBanking() ||
          (isGPXCan() && isGPXCaml()),
        pattern: {
          value: getValidationExpressionForZipCode(),
          message: invalidZipCodeErrorMsg(),
        },
        renderCondition: isFieldVisible('payerZip') || isApolloCreditCard,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },
      payerCountry: {
        required: isFieldMandatory('payerCountry') || iftiEnabled || isApolloCreditCard,
        renderCondition: isFieldVisible('payerCountry') || iftiEnabled || isApolloCreditCard,
        disableCondition: refs.GPX && !model.session.sellerInfo.gpxRemitterEnabled,
      },

      //bank details section
      branchCode: {
        required: isPaymentFieldRequired('branchCode'),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: isCAMLFieldVisible('branchCode') && model.paymentType.displayBankDetails,
        disableCondition: false,
      },
      bankSwiftCode: {
        required:
          isComplianceFieldMandatory('bankSwiftCode') || isPaymentFieldRequired('bankSwiftCode'),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition:
          isCAMLFieldVisible('bankSwiftCode') && model.paymentType.displayBankDetails,
      },
      payerBankName: {
        required: isFieldMandatory('payerBankName') || isPaymentFieldRequired('payerBankName'),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition: model.paymentType.displayBankDetails,
        disableCondition: model.paymentType.bankDetailsFixed,
      },
      payerAccountNumber: {
        required:
          isFieldMandatory('payerAccountNumber') ||
          isPaymentFieldRequired('payerAccountNumber') ||
          isGPXCan(),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition:
          isCAMLFieldVisible('payerAccountNumber') && model.paymentType.displayBankDetails,
        disableCondition:
          model.paymentType.bankDetailsFixed && !isPaymentFieldVisible('payerAccountNumber'),
      },
      payerBankAddress: {
        required:
          isFieldMandatory('payerBankAddress') ||
          isComplianceFieldMandatory('payerBankAddress') ||
          isPaymentFieldRequired('payerBankAddress') ||
          isGPXCan(),
        pattern: {
          value: getAddressValidation(),
          message: getAddressError(),
        },
        renderCondition:
          isCAMLFieldVisible('payerBankAddress') && model.paymentType.displayBankDetails,
        disableCondition: model.paymentType.bankDetailsFixed,
      },
      payerBankAddress2: {
        required:
          isFieldMandatory('payerBankAddress2') || isPaymentFieldRequired('payerBankAddress2'),
        pattern: {
          value: getAddressValidation(),
          message: getAddressError(),
        },
        renderCondition:
          isCAMLFieldVisible('payerBankAddress2') && model.paymentType.displayBankDetails,
        disableCondition: model.paymentType.bankDetailsFixed,
      },
      payerBankCity: {
        required:
          isFieldMandatory('payerBankCity') ||
          isComplianceFieldMandatory('payerBankCity') ||
          isPaymentFieldRequired('payerBankCity') ||
          isGPXCan(),
        pattern: {
          value: getAddressValidation(),
          message: getAddressError(),
        },
        renderCondition:
          isCAMLFieldVisible('payerBankCity') && model.paymentType.displayBankDetails,
        disableCondition: model.paymentType.bankDetailsFixed,
      },
      payerBankState: {
        required:
          isFieldMandatory('payerBankState') ||
          isComplianceFieldMandatory('payerBankState') ||
          isPaymentFieldRequired('payerBankState') ||
          isGPXCaml(),
        pattern: {
          value: PATTERNS.SWIFT_REGEX,
          message: ERROR_MESSAGES.SWIFT_REGEX,
        },
        renderCondition:
          isCAMLFieldVisible('payerBankState') && model.paymentType.displayBankDetails,
        disableCondition: model.paymentType.bankDetailsFixed,
      },
      payerBankZip: {
        required:
          isFieldMandatory('payerBankZip') ||
          isComplianceFieldMandatory('payerBankZip') ||
          isPaymentFieldRequired('payerBankZip') ||
          (isGPXCan() && isGPXCaml()),
        pattern: {
          value: getValidationExpressionForBankZipCode(),
          message: invalidBankZipCodeErrorMsg(),
        },
        renderCondition: isCAMLFieldVisible('payerBankZip') && model.paymentType.displayBankDetails,
        disableCondition: model.paymentType.bankDetailsFixed,
      },
      bankCountry: {
        required:
          isFieldMandatory('payerBankCountry') || isPaymentFieldRequired('payerBankCountry'),
        renderCondition: model.paymentType.displayBankDetails,
      },
      language: {
        required: true,
        renderCondition: !model.paymentType.onlinePayment,
      },
      fundingSource: {
        required: isPaymentFieldRequired('fundingSource'),
        renderCondition: isPaymentFieldVisible('fundingSource'),
      },
      // additional optional fields section
      camlEntityType: {},
      //additional fields for entitiy type individual
      camlPhoneNumber: {
        pattern: {
          value: PATTERNS.CONTACT_NO_REGEX,
          message: ERROR_MESSAGES.CONTACT_NO_REGEX,
        },
      },
      camlDateOfBirth: {}, //TODO: Set max date to today
      contactCountryCode: {},
      camlNickName: {
        pattern: {
          value: PATTERNS.ALPHA_NUMERIC,
          message: ERROR_MESSAGES.ALPHA_NUMERIC,
        },
      },
      camlDocType: {},
      camlDocRef: {},
      camlDocCountryOfIssue: {},
      camlOccupation: {
        pattern: {
          value: PATTERNS.ALPHA_NUMERIC,
          message: ERROR_MESSAGES.ALPHA_NUMERIC,
        },
      },
      camlEmployerName: {
        pattern: {
          value: PATTERNS.ALPHA_NUMERIC,
          message: ERROR_MESSAGES.ALPHA_NUMERIC,
        },
      },
      // if entity type is business
      camlPrincipalBusiness: {
        pattern: {
          value: PATTERNS.ALPHA_NUMERIC,
          message: ERROR_MESSAGES.ALPHA_NUMERIC,
        },
      },
      camlRegNumber: {},
      camlRegCountryOfIssue: {},
      authUsers: {}, //TODO: Check the exact key name in old code

      //common CAML optional fields
      camlAccountHolderName: {},
      camlBankAccType: {},
      bankRoutingCode: {},
      camlContactPersonName: {
        pattern: {
          value: PATTERNS.ALPHA_NUMERIC,
          message: ERROR_MESSAGES.ALPHA_NUMERIC,
        },
      },
      camlContactPersonEmail: {
        pattern: {
          value: PATTERNS.email,
          message: ERROR_MESSAGES.email,
        },
      },
      camlContactPersonNumber: {
        pattern: {
          value: PATTERNS.CONTACT_NO_REGEX,
          message: ERROR_MESSAGES.CONTACT_NO_REGEX,
        },
      },
    };

    setValidations(validations);
  };

  const setCountry = (name, selectedCountry) => {
    setFormData({
      ...formData,
      [name]: selectedCountry,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const handleDateInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <PayerFormContext.Provider value={sharedProps}>
        <PayerPersonalDetailsSection
          handleInputChange={handleInputChange}
          handleBlur={handleBlur}
          setCountry={setCountry}
          handleDateInputChange={handleDateInputChange}
        />
        <PayerAddressSection
          handleInputChange={handleInputChange}
          handleBlur={handleBlur}
          setCountry={setCountry}
          setFormData={setFormData}
          validateField={validateField}
          setErrors={setErrors}
        />
        <PayerBankDetailsSection
          handleInputChange={handleInputChange}
          handleBlur={handleBlur}
          setCountry={setCountry}
        />

        {/* Doc collection for INR_BANK_ACCOUNT */}
        {model.paymentType.id === 'INR_BANK_ACCOUNT' && model.paymentType.enableDocCollection && (
          <DocUploadSection
            getDynamicFieldLabel={getDynamicFieldLabel}
            model={model}
            showLoanDocuments={formData['fundingSource'] === 'LOAN'}
            documents={documents}
            updateDoc={updateDoc}
            handleAddressProofChange={(e) => setAddressProof(e.target.value)}
          />
        )}

        {refs.isCAMLSectionVisible && (
          <PayerAdditionalOptionalFields
            handleInputChange={handleInputChange}
            handleBlur={handleBlur}
            setCountry={setCountry}
          />
        )}

        <div>
          <FormControlLabel
            control={
              <Checkbox name={'terms'} checked={formData['terms']} onChange={handleInputChange} />
            }
            label={
              <RawHtmlContent
                htmlContent={
                  formData['bankCountry'] !== 'USA'
                    ? 'TERMS_OF_USE_MESSAGE_AND_PRIVACY'
                    : 'TERMS_OF_USE_MESSAGE_AND_PRIVACY_USA'
                }
              />
            }
          />
          <FormHelperText error={true}>{errors['terms']}</FormHelperText>
        </div>

        {refDataCache.countryDialingCodes.find((c) => c.countryCode == formData['payerCountry'])
          ?.marketingConsentEnabled && (
          <div className='mb-2'>
            <FormControlLabel
              control={
                <Checkbox
                  name={'optInMarketingConsentCheckbox'}
                  checked={formData['optInMarketingConsentCheckbox']}
                  onChange={handleInputChange}
                />
              }
              label={<Translate value='JS_MARKETING_OPT_IN' className='text-16' />}
            />
          </div>
        )}

        {validations['docQuality']?.renderCondition && (
          <div className='mb-2'>
            <FormControlLabel
              control={
                <Checkbox
                  name={'docQuality'}
                  checked={formData['docQuality']}
                  onChange={handleInputChange}
                  disabled={!documentsVerified}
                />
              }
              label={<Translate value='JS_DOC_COL_MESSAGE' className='text-16' />}
            />
          </div>
        )}

        <div className='mt-5 text-center border-t border-t-lightGray2 pt-3'>
          <Button
            className={`text-14 rounded-full p-[14px 24px] ${
              !isFormValid ||
              !documentsVerified ||
              !otherFieldsValid ||
              isAPIInProgress ||
              (consolBuyerRemitterPageEnabled && !isStudentFormValid)
                ? 'opacity-90 bg-disabled shadow-none text-disabledFont'
                : ''
            }`}
            variant='contained'
            disabled={isAPIInProgress}
            onClick={() => submitForm()}
          >
            <span>
              <Translate
                value={
                  !model.session.selectedQuote.paymentType.onlinePayment &&
                  !model.session.petnetRedirectionUrl
                    ? 'JS_SUBMIT'
                    : 'JS_REMITTER_PROCEED_TO_PAYMENT_BUTTON'
                }
                className='mr-0.5'
              />
              <ArrowForwardIcon />
            </span>
          </Button>
        </div>
      </PayerFormContext.Provider>
    </div>
  );
};

export default PayerForm;
