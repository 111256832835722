import GroupIcon from '@mui/icons-material/Group';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import { useIntl } from 'react-intl';
import SmsUpdate from '../../components/SmsUpdate';
import { Translate } from '../../i18n/translate';
export const HowToComplete = ({ columns, pledge }) => {
  const intl = useIntl();
  return (
    <>
      <div className='mt-5 bg-gray2 p-4'>
        <div className={columns == 2 ? 'flex justify-center' : 'flex justify-start'}>
          <p className='text-22-bold ml-[60px] w-[210px]'>
            {intl.formatMessage({ id: 'JS_OFFLINE_PAYMENT_COMPLETE_TRANSFER' })}
          </p>
        </div>
        <div className='mt-3'>
          <div className='flex justify-start items-stretch flex-[0_0_auto] px-1 py-0'>
            <div className='inline-flex  items-start flex-[0_0_auto]'>
              <div className='h-[40px] w-[40px] rounded-full bg-gray8 flex justify-center items-center'>
                <LaptopOutlinedIcon className='text-white' />
              </div>
            </div>
            <div className='ml-4'>
              <div className='text-16-bold text-midnight'>
                <Translate value='JS_OFFLINE_ONLINE_TITLE' />
              </div>
              <div className=''>
                <p className='text-lightGra5 text-14-400-22'>
                  <Translate value='JS_OFFLINE_ONLINE_CONTENT' />
                </p>
              </div>
            </div>
          </div>

          <div className='flex justify-start items-stretch flex-[0_0_auto] px-1 py-0'>
            <div className='inline-flex  items-start flex-[0_0_auto]'>
              <div className='h-[40px] w-[40px] rounded-full bg-gray8 flex justify-center items-center'>
                <PhoneIcon className='text-white' />
              </div>
            </div>
            <div className='ml-4 mt-2'>
              <div className='text-16-bold text-midnight'>
                <Translate value='JS_OFFLINE_PHONE_TITLE' />
              </div>
              <div className=''>
                <p className='text-lightGra5 text-14-400-22'>
                  <Translate value='JS_OFFLINE_PHONE_CONTENT' />
                </p>
              </div>
            </div>
          </div>
          <div className='flex justify-start items-stretch flex-[0_0_auto] px-1 py-0'>
            <div className='inline-flex  items-start flex-[0_0_auto]'>
              <div className='h-[40px] w-[40px] rounded-full bg-gray8 flex justify-center items-center'>
                <GroupIcon className='text-white' />
              </div>
            </div>
            <div className='ml-4  mt-2'>
              <div className='text-16-bold text-midnight'>
                <Translate value='JS_OFFLINE_PERSON_TITLE' />
              </div>
              <div className=''>
                <p className='text-lightGra5 text-14-400-22'>
                  <Translate value='JS_OFFLINE_PERSON_CONTENT' />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=''></div>
      </div>
      <div>
        <SmsUpdate pledgeId={pledge.receivableId} />
      </div>
      <div className='ml-3 mt-4'>
        <p className='text-16 text-midnight leading-6'>
          <Translate value='JS_OFFLINE_INSTRUCTION_NOTE' />
        </p>
      </div>
    </>
  );
};
