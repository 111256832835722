import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectData, selectLocale } from '../../store/slices/session/sessionSlice';
import { RawHtmlContent } from '../Format';

export const Footer = () => {
  const locale = useSelector(selectLocale);
  const seller = useSelector(selectData);
  const [legalText, setLegalText] = useState(null);
  const containerRef = useRef(null);
  useEffect(() => {
    getLegalDisclaimerIfDefined(seller?.sellerInfo);
  }, [seller]);

  const getLegalDisclaimerIfDefined = (sellerInformationView) => {
    if (sellerInformationView != null && sellerInformationView.legalEntityDisclaimer != null) {
      sellerInformationView.legalEntityDisclaimer.disclaimerTextViews.forEach((disclaimerView) => {
        if (
          locale.replace('-', '_') == disclaimerView.language &&
          disclaimerView.isActive &&
          disclaimerView.disclaimerTypeId.toLowerCase() === 'footer' &&
          disclaimerView.text.trim() !== ''
        ) {
          setLegalText(disclaimerView.text);
        }
      });
    }
  };
  return (
    legalText && (
      <div ref={containerRef}>
        <div
          className={
            'bg-gray30 p-2.5 text-gray8  border-t border-solid border-gray4 text-14-400-22 mt-24'
          }
        >
          <RawHtmlContent htmlContent={legalText} requireTranslate={false} />
        </div>
      </div>
    )
  );
};
