import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import InputWithLabel from '../../components/InputWithLabel';

const MAX_AUTH_USERS = 3;

const PayerCAMLAuthUsersInput = ({ label, name }) => {
  const [authUserFeilds, setAuthUserFields] = useState([
    {
      id: 'camlAuthUser1',
      value: '',
    },
  ]);

  const handleAuthUserChange = (e, index) => {
    setAuthUserFields(
      authUserFeilds.slice().map((ele, i) => {
        if (index === i) {
          return { ...ele, value: e.target.value };
        }

        return e;
      }),
    );
  };

  const deleteAuthUserField = (index) => {
    setAuthUserFields(authUserFeilds.filter((e, i) => i !== index));
  };

  const handleBlur = () => {};

  const addAuthUser = () => {
    setAuthUserFields([
      ...authUserFeilds,
      {
        id: 'camlAuthUser' + (authUserFeilds.length + 1),
        value: '',
      },
    ]);
  };
  const intl = useIntl();

  const getTranslatedValue = (label) => {
    return intl.formatMessage({ id: label });
  };

  return (
    <>
      <InputWithLabel label={label} name={name}>
        {authUserFeilds.map((authUser, index) => (
          <div key={index}>
            <TextField
              label={''}
              name={'authUser' + index}
              value={authUser.value}
              variant='outlined'
              className='mb-2'
              inputProps={{
                placeholder: getTranslatedValue('JS_PAYER_CAML_PAYER_AUTHUSER_PLACEHOLDER'),
                maxLength: 40,
              }}
              InputProps={{
                endAdornment: authUserFeilds.length > 1 && (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => deleteAuthUserField(index)}
                      edge='end'
                    >
                      <DeleteOutlineOutlinedIcon className='text-error' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              onChange={(e) => handleAuthUserChange(e, index)}
              onBlur={handleBlur}
            />
          </div>
        ))}
      </InputWithLabel>

      {authUserFeilds.length < MAX_AUTH_USERS && (
        <Button
          variant='text'
          startIcon={<AddIcon />}
          sx={{
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
            },
          }}
          onClick={() => addAuthUser()}
          className='text-accent p-0'
        >
          Add another representative
        </Button>
      )}
    </>
  );
};

export default PayerCAMLAuthUsersInput;
