import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { CountrySelect } from '../../components/CountryDropDown';
import InputWithLabel from '../../components/InputWithLabel';
import { Translate } from '../../i18n/translate';
import PayerFormContext from './payerFormContext';

const PayerBankDetailsSection = ({ handleInputChange, handleBlur, setCountry }) => {
  const {
    model,
    getDynamicFieldLabel,
    isCAMLFieldVisible,
    isPaymentFieldVisible,
    isPaymentFieldRequired,
    isFieldMandatory,
    bankStateProvinces,
    languages,
    payerFeeCountries,
    isGPXCan,
    isGPXCaml,
    isComplianceFieldMandatory,
    getLabelForCaState,
    //directly from payer form
    formData,
    errors,
    validations,
    refs,
  } = useContext(PayerFormContext);
  const intl = useIntl();

  const getTranslatedValue = (label) => {
    return intl.formatMessage({ id: label });
  };

  const renderBankFeilds = () => {
    if (!model.paymentType.displayBankDetails) {
      return <></>;
    }

    return (
      <div>
        <h4 className='text-16-bold mb-3'>
          <Translate value='JS_PAYER_BANK_DETAILS_TITLE' />
        </h4>
        {/* Field 1: Branch code */}
        {isCAMLFieldVisible('branchCode') && (
          <InputWithLabel
            label={getDynamicFieldLabel('JS_PAYER_BRANCH_CODE_LABEL')}
            required={isPaymentFieldRequired('branchCode')}
          >
            <TextField
              label={''}
              name={'branchCode'}
              value={formData['branchCode']}
              variant='outlined'
              inputProps={{
                placeholder: getTranslatedValue(
                  getDynamicFieldLabel('JS_PAYER_BRANCH_CODE_PLACEHOLDER'),
                ),
                maxLength: 40,
              }}
              disabled={validations['branchCode']?.disableCondition}
              error={!!errors['branchCode']}
              helperText={errors['branchCode']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 2: Bank swift code */}
        {isCAMLFieldVisible('bankSwiftCode') && (
          <InputWithLabel
            label={'swiftCodeLabel'}
            required={
              isComplianceFieldMandatory('bankSwiftCode') || isPaymentFieldRequired('bankSwiftCode')
            }
          >
            <TextField
              label={''}
              name={'bankSwiftCode'}
              value={formData['bankSwiftCode']}
              variant='outlined'
              inputProps={{
                placeholder: getTranslatedValue('swiftCodeLabel'),
                maxlength: 11,
              }}
              disabled={validations['bankSwiftCode']?.disableCondition}
              error={!!errors['bankSwiftCode']}
              helperText={errors['bankSwiftCode']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 3: Payer bank name */}
        <InputWithLabel
          label={'JS_PAYER_BANK_NAME_LABEL'}
          required={isFieldMandatory('payerBankName') || isPaymentFieldRequired('payerBankName')}
        >
          <TextField
            label={''}
            name={'payerBankName'}
            value={formData['payerBankName']}
            variant='outlined'
            inputProps={{
              placeholder: getTranslatedValue('JS_PAYER_BANK_NAME_PLACEHOLDER'),
              maxLength: 70,
            }}
            disabled={validations['payerBankName']?.disableCondition}
            error={!!errors['payerBankName']}
            helperText={errors['payerBankName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </InputWithLabel>
        {/* Field 4 : Payer Account number */}
        {isCAMLFieldVisible('payerAccountNumber') && (
          <InputWithLabel
            label={getDynamicFieldLabel('JS_PAYER_BANK_ACCOUNT_NUMBER_LABEL')}
            required={
              isFieldMandatory('payerAccountNumber') ||
              isPaymentFieldRequired('payerAccountNumber') ||
              isGPXCan()
            }
          >
            {/* TODO: Implement obfuscate logic */}
            <TextField
              label={''}
              name={'payerAccountNumber'}
              value={formData['payerAccountNumber']}
              variant='outlined'
              inputProps={{
                placeholder: intl.formatMessage({
                  id: getDynamicFieldLabel('JS_PAYER_BANK_ACCOUNT_NUMBER_PLACEHOLDER'),
                }),
                maxLength: 40,
              }}
              disabled={validations['payerAccountNumber']?.disableCondition}
              error={!!errors['payerAccountNumber']}
              helperText={errors['payerAccountNumber']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 5 : Payer bank address 1*/}
        {isCAMLFieldVisible('payerBankAddress') && (
          <InputWithLabel
            label={'JS_PAYER_BANK_ADDRESS_1_LABEL'}
            required={
              isFieldMandatory('payerBankAddress') ||
              isComplianceFieldMandatory('payerBankAddress') ||
              isPaymentFieldRequired('payerBankAddress') ||
              isGPXCan()
            }
          >
            <TextField
              label={''}
              name={'payerBankAddress'}
              value={formData['payerBankAddress']}
              variant='outlined'
              inputProps={{
                placeholder: getTranslatedValue('JS_PAYER_BANK_ADDRESS_1_PLACEHOLDER'),
                maxLength: 60,
              }}
              disabled={validations['payerBankAddress']?.disableCondition}
              error={!!errors['payerBankAddress']}
              helperText={errors['payerBankAddress']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 6 : Payer bank address 2*/}
        {isCAMLFieldVisible('payerBankAddress2') && (
          <InputWithLabel
            label={'JS_PAYER_BANK_ADDRESS_2_LABEL'}
            required={
              isFieldMandatory('payerBankAddress2') || isPaymentFieldRequired('payerBankAddress2')
            }
          >
            <TextField
              label={''}
              name={'payerBankAddress2'}
              value={formData['payerBankAddress2']}
              variant='outlined'
              inputProps={{
                placeholder: getTranslatedValue('JS_PAYER_BANK_ADDRESS_2_PLACEHOLDER'),
                maxLength: 40,
              }}
              disabled={validations['payerBankAddress2']?.disableCondition}
              error={!!errors['payerBankAddress2']}
              helperText={errors['payerBankAddress2']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 7 : Payer bank city*/}
        {isCAMLFieldVisible('payerBankCity') && (
          <InputWithLabel
            label={'JS_PAYER_BANK_CITY_LABEL'}
            required={
              isFieldMandatory('payerBankCity') ||
              isComplianceFieldMandatory('payerBankCity') ||
              isPaymentFieldRequired('payerBankCity') ||
              isGPXCan()
            }
          >
            <TextField
              label={''}
              name={'payerBankCity'}
              value={formData['payerBankCity']}
              variant='outlined'
              inputProps={{
                placeholder: getTranslatedValue('JS_PAYER_BANK_CITY_PLACEHOLDER'),
                maxLength: 40,
              }}
              disabled={validations['payerBankCity']?.disableCondition}
              error={!!errors['payerBankCity']}
              helperText={errors['payerBankCity']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 8 : Payer bank state*/}
        {isCAMLFieldVisible('payerBankState') && (
          <InputWithLabel
            label={
              formData.bankCountry !== 'CAN'
                ? 'JS_PAYER_BANK_STATE_LABEL'
                : getLabelForCaState('JS_BANK_STATE_LABEL')
            }
            required={
              isFieldMandatory('payerBankState') ||
              isComplianceFieldMandatory('payerBankState') ||
              isPaymentFieldRequired('payerBankState') ||
              isGPXCaml()
            }
          >
            {!['USA', 'CAN', 'MEX'].includes(formData.bankCountry) ? (
              <TextField
                label={''}
                name={'payerBankState'}
                value={formData['payerBankState']}
                variant='outlined'
                inputProps={{
                  placeholder: getTranslatedValue('JS_PAYER_BANK_STATE_PLACEHOLDER'),
                  maxLength: 40,
                }}
                disabled={validations['payerBankState']?.disableCondition}
                error={!!errors['payerBankState']}
                helperText={errors['payerBankState']}
                fullWidth
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
            ) : (
              <>
                <FormControl
                  fullWidth
                  disabled={validations['payerBankState']?.disableCondition}
                  error={!!errors['payerBankState']}
                >
                  <Select
                    name='payerBankState'
                    value={formData['payerBankState']}
                    onChange={handleInputChange}
                  >
                    {bankStateProvinces.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.tName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors['payerBankState'] && (
                  <FormHelperText error>{errors['payerBankState']}</FormHelperText>
                )}
              </>
            )}
          </InputWithLabel>
        )}
        {/* Field 9 : Payer bank zip*/}
        {isCAMLFieldVisible('payerBankZip') && (
          <InputWithLabel
            label={'JS_PAYER_BANK_ZIP_LABEL'}
            required={
              isFieldMandatory('payerBankZip') ||
              isComplianceFieldMandatory('payerBankZip') ||
              isPaymentFieldRequired('payerBankZip') ||
              (isGPXCan() && isGPXCaml())
            }
          >
            <TextField
              label={''}
              name={'payerBankZip'}
              value={formData['payerBankZip']}
              variant='outlined'
              inputProps={{
                placeholder: getTranslatedValue('JS_PAYER_BANK_ZIP_PLACEHOLDER'),
                maxLength: 40,
              }}
              disabled={validations['payerBankZip']?.disableCondition}
              error={!!errors['payerBankZip']}
              helperText={errors['payerBankZip']}
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </InputWithLabel>
        )}
        {/* Field 10: Payer Bank Country */}
        <InputWithLabel
          label={'JS_PAYER_BANK_COUNTRY_LABEL'}
          required={
            isFieldMandatory('payerBankCountry') || isPaymentFieldRequired('payerBankCountry')
          }
        >
          <CountrySelect
            countries={payerFeeCountries}
            setCountry={(v) => {
              setCountry('bankCountry', v);
            }}
            value={formData['bankCountry']}
            disabled={validations['payerBankCountry']?.disableCondition}
            error={errors['payerBankCountry']}
          />
          {errors['payerBankCountry'] && (
            <FormHelperText error>{errors['payerBankCountry']}</FormHelperText>
          )}
        </InputWithLabel>
      </div>
    );
  };

  return (
    <div>
      <>{renderBankFeilds()}</>
      <div>
        {/* Field 11: Payment instruction language for offline payments */}
        {!model.paymentType.onlinePayment && (
          <InputWithLabel label='JS_PAYMENT_INSTRUCTION_LANGUAGE_LABEL' required={true}>
            <FormControl
              fullWidth
              disabled={validations['language']?.disableCondition}
              error={!!errors['language']}
            >
              <Select
                name='language'
                value={formData['language'] ?? ''}
                onChange={handleInputChange}
              >
                {languages.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    <Translate value={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors['language'] && <FormHelperText error>{errors['language']}</FormHelperText>}
          </InputWithLabel>
        )}

        {/* Field 12: Funding source */}
        {isPaymentFieldVisible('fundingSource') && (
          <InputWithLabel
            label={getDynamicFieldLabel('FUNDING_SOURCE_LABEL')}
            required={isPaymentFieldRequired('fundingSource')}
          >
            <FormControl
              fullWidth
              disabled={validations['language']?.fundingSource}
              error={!!errors['fundingSource']}
            >
              <Select
                name='fundingSource'
                value={formData['fundingSource'] ?? ''}
                onChange={handleInputChange}
              >
                {refs.paymentTypeFields['fundingSource'].optionValues.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {getDynamicFieldLabel('FUNDING_SOURCE_' + option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors['fundingSource'] && (
              <FormHelperText error>{errors['fundingSource']}</FormHelperText>
            )}
          </InputWithLabel>
        )}
      </div>
    </div>
  );
};

export default PayerBankDetailsSection;
