import { useTheme } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { TranslateWithValues } from '../../i18n/translate';

const DocumentPreview = ({ open, file, onClose, labelName }) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  };

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // };

  // const previousPage = () => {
  //   setPageNumber(pageNumber - 1);
  // };

  // const nextPage = () => {
  //   setPageNumber(pageNumber + 1);
  // };

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={open}
      // onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>
        <h4 className='text-18 font-bold'>
          <span>
            {TranslateWithValues('PM_DOC_REVIEW', { labelName: labelName?.toLowerCase() })}
          </span>
        </h4>
        <p className='text-base text-lightGray3 font-normal mt-4'>
          <span>
            {TranslateWithValues('PM_DOC_CONFIRM_REVIEW', { labelName: labelName?.toLowerCase() })}
          </span>
        </p>

        <p className='text-16-bold italic mt-6'>{file?.name}</p>
      </DialogTitle>
      <DialogContent
        className={`${file?.type === 'application/pdf' ? 'h-[58vh] overflow-hidden' : ''}`}
      >
        <>
          {file && file.type.includes('image') && (
            <div className='border-2 border-dashed border-lightGray4'>
              <img src={URL.createObjectURL(file)} alt='image preview' />
            </div>
          )}
          {file && file.type === 'application/pdf' && (
            <div className='border-2 border-dashed border-lightGray4 h-full'>
              <iframe
                src={URL.createObjectURL(file)}
                type='application/pdf'
                width='100%'
                height='100%'
                seamless
                allowfullscreen
              />
            </div>
            // <>
            //   <Document className='pdfPage' file={file} onLoadSuccess={onDocumentLoadSuccess}>
            //     <Page pageNumber={pageNumber} className='pdfPage' />
            //   </Document>
            // </>
          )}
        </>
        {/* <div className='flex justify-start items-center my-3'>
          <Button
            variant='text'
            startIcon={<ArrowBackIcon />}
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
              '&.MuiButtonBase-root.Mui-disabled': {
                bgcolor: 'transparent',
                opacity: 0.5,
              },
            }}
            disabled={pageNumber === 1}
            onClick={previousPage}
            className='text-accent p-0 mr-5'
          >
            Previous
          </Button>
          <p className='font-bold'>
            Page {pageNumber} of {numPages}
          </p>
          <Button
            variant='text'
            endIcon={<ArrowForwardIcon />}
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
              '&.MuiButtonBase-root.Mui-disabled': {
                bgcolor: 'transparent',
                opacity: 0.5,
              },
            }}
            onClick={nextPage}
            disabled={pageNumber === numPages}
            className='text-accent p-0 ml-5'
          >
            Next
          </Button>
        </div> */}
      </DialogContent>
      <DialogActions className='justify-center'>
        <Button onClick={handleClose} autoFocus variant='contained' className='p-[14px 24px]'>
          <span>
            {TranslateWithValues('PM_DOC_REVIEWED', { labelName: labelName?.toLowerCase() })}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentPreview;
