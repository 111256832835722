import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { useContext } from 'react';
import { CountrySelect } from '../../components/CountryDropDown';
import DatePickerDropdown from '../../components/DatePickerDropdown';
import InputWithLabel from '../../components/InputWithLabel';
import TelInput from '../../components/TelInput';
import { Translate } from '../../i18n/translate';
import PayerFormContext from './payerFormContext';

const PayerPersonalDetailsSection = ({
  handleInputChange,
  handleBlur,
  setCountry,
  handleDateInputChange,
}) => {
  const {
    getDynamicFieldLabel,
    isPaymentFieldVisible,
    isPaymentFieldRequired,
    isDfTxn,
    isFieldVisible,
    isApolloCreditCard,
    iftiEnabled,
    isFieldMandatory,
    getLabelforPayerName,
    getHelpTextForPayerName,
    countries,
    renderContactNo,
    //directly from payer form
    formData,
    errors,
    validations,
  } = useContext(PayerFormContext);

  return (
    <div>
      <h4 className='text-16-bold mb-3'>
        <Translate value='PAYER_INFORMATION_TITLE' />
      </h4>
      {/* Field 1: First name */}
      {isPaymentFieldVisible('firstName') && (
        <InputWithLabel
          label={getDynamicFieldLabel('JS_PAYER_FIRST_NAME_LABEL')}
          required={isPaymentFieldRequired('firstName')}
          tooltip={isDfTxn ? 'JS_PAYER_FIRST_NAME_LXNX_HINT' : ''} //TODO: Implement tooltip logic in InputWithLabel component
        >
          <TextField
            label={''}
            name={'firstName'}
            value={formData['firstName']}
            variant='outlined'
            inputProps={{
              placeholder: getDynamicFieldLabel('JS_PAYER_FIRST_NAME_PLACEHOLDER'),
              maxLength: 40,
            }}
            error={!!errors['firstName']}
            helperText={errors['firstName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={validations['firstName']?.disableCondition}
          />
        </InputWithLabel>
      )}

      {/* Field 2: Middle name */}
      {isPaymentFieldVisible('middleName') && (
        <InputWithLabel
          label={getDynamicFieldLabel('JS_PAYER_MIDDLE_NAME_LABEL')}
          required={isPaymentFieldRequired('middleName')}
          tooltip={isDfTxn ? 'JS_PAYER_MIDDLE_NAME_LXNX_HINT' : ''}
        >
          <TextField
            label={''}
            name={'middleName'}
            value={formData['middleName']}
            variant='outlined'
            inputProps={{
              placeholder: getDynamicFieldLabel('JS_PAYER_MIDDLE_NAME_PLACEHOLDER'),
              maxLength: 40,
            }}
            error={!!errors['middleName']}
            helperText={errors['middleName']}
            fullWidth
            onChange={handleInputChange}
            disabled={validations['middleName']?.disableCondition}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      )}

      {/* Field 3: Last name */}
      {isPaymentFieldVisible('lastName') && (
        <InputWithLabel
          label={getDynamicFieldLabel('JS_PAYER_LAST_NAME_LABEL')}
          required={isPaymentFieldRequired('lastName')}
          tooltip={isDfTxn ? 'JS_PAYER_LAST_NAME_LXNX_HINT' : ''}
        >
          <TextField
            label={''}
            name={'lastName'}
            value={formData['lastName']}
            variant='outlined'
            inputProps={{
              placeholder: getDynamicFieldLabel('JS_PAYER_LAST_NAME_PLACEHOLDER'),
              maxLength: 40,
            }}
            error={!!errors['lastName']}
            helperText={errors['lastName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={validations['lastName']?.disableCondition}
          />
        </InputWithLabel>
      )}

      {/* Field 4: Payer name */}
      {(isFieldVisible('payerName') || iftiEnabled || isApolloCreditCard) && (
        <InputWithLabel
          label={getLabelforPayerName()}
          required={isFieldMandatory('payerName') || iftiEnabled || isApolloCreditCard}
          tooltip={isDfTxn ? 'JS_PAYER_NAME_LXNX_HINT' : ''}
        >
          <TextField
            label={''}
            name={'payerName'}
            value={formData['payerName']}
            variant='outlined'
            inputProps={{ placeholder: getHelpTextForPayerName(), maxLength: 140 }}
            error={!!errors['payerName']}
            helperText={errors['payerName']}
            fullWidth
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={validations['payerName']?.disableCondition}
          />
        </InputWithLabel>
      )}

      {/* Field 5: ID number */}
      {isPaymentFieldVisible('idNumber') && (
        <InputWithLabel
          label={getDynamicFieldLabel('JS_PAYER_ID_NUMBER_LABEL')}
          required={isPaymentFieldRequired('idNumber')}
          tooltip={isDfTxn ? 'JS_PAYER_ID_LXNX_HINT' : ''}
        >
          <TextField
            label={''}
            name={'idNumber'}
            value={formData['idNumber']}
            variant='outlined'
            inputProps={{
              placeholder: getDynamicFieldLabel('JS_PAYER_ID_NUMBER_PLACEHOLDER'),
              maxLength: 40,
            }}
            error={!!errors['idNumber']}
            helperText={errors['idNumber']}
            fullWidth
            onChange={handleInputChange}
            disabled={validations['idNumber']?.disableCondition}
            onBlur={handleBlur}
          />
        </InputWithLabel>
      )}

      {/* Field 6: Payer DOB */}
      {/* {isPaymentFieldVisible('dateOfBirth') && (
        <DatePicker
          name='dateOfBirth'
          labelKey={getDynamicFieldLabel('PAYER_DATE_OF_BIRTH_LABEL')}
          // value={addressProof.dateOfAddrProofExpiry}
          handleChange={handleInputChange} //TODO: Specific handling for date change, check onDateOfBirthChanged in old code.
          mandatory={isPaymentFieldRequired('dateOfBirth')}
          maxDate={new Date().toISOString().split('T')[0]}
        />
      )} */}

      {isPaymentFieldVisible('dateOfBirth') && (
        <DatePickerDropdown
          maxDate={new Date()}
          label={getDynamicFieldLabel('JS_PAYER_DATE_OF_BIRTH_LABEL')}
          required={isPaymentFieldRequired('dateOfBirth')}
          tooltip={isDfTxn ? 'JS_PAYER_DATE_OF_BIRTH_LXNX_HINT' : ''}
          disabled={validations['dateOfBirth']?.disableCondition}
          onChange={(v) => handleDateInputChange('dateOfBirth', v)}
        />
      )}

      {/* Field 7: Payer nationality */}
      {isPaymentFieldVisible('nationality') && (
        <InputWithLabel
          label={getDynamicFieldLabel('JS_PAYER_NATIONALITY_LABEL')}
          required={isPaymentFieldRequired('nationality')}
        >
          <CountrySelect
            countries={countries} //TODO:Add validations in country select component, as well as label prop
            setCountry={(v) => {
              setCountry('nationality', v);
            }}
            value={formData['nationality']}
            disabled={validations['nationality']?.disableCondition}
            error={!!errors['nationality']}
          />
          {errors['nationality'] && <FormHelperText error>{errors['nationality']}</FormHelperText>}
        </InputWithLabel>
      )}

      {/* Field 8: Payer Email */}
      <InputWithLabel
        label={'JS_PAYER_EMAIL_LABEL'}
        required={true}
        tooltip={isDfTxn ? 'JS_PAYER_EMAIL_LXNX_HINT' : ''}
      >
        <TextField
          label={''}
          name={'email'}
          value={formData['email']}
          variant='outlined'
          inputProps={{ placeholder: getDynamicFieldLabel('JS_PAYER_EMAIL_PLACEHOLDER') }}
          error={!!errors['email']}
          helperText={errors['email']}
          fullWidth
          disabled={validations['email']?.disableCondition}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
      </InputWithLabel>

      {/* Field 9: Contact number */}
      {/* TODO: Verify contact field works as expected */}
      {renderContactNo && (
        <InputWithLabel
          label='JS_PAYER_CONTACT_NO_LABEL'
          required={isPaymentFieldRequired('contactNo')}
          tooltip={isDfTxn ? 'JS_PAYER_CONTACT_NO_LXNX_HINT' : ''}
        >
          <TelInput
            onChange={handleInputChange}
            name='contactNo'
            dialCodeName='dialingFromCountry'
            error={!!errors['contactNo']}
            onBlur={handleBlur}
            disabled={validations['contactNo']?.disableCondition}
            payerCountry={formData['payerCountry']}
          />
          {errors['contactNo'] && <FormHelperText error>{errors['contactNo']}</FormHelperText>}
        </InputWithLabel>
      )}
    </div>
  );
};

export default PayerPersonalDetailsSection;
