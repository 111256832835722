import { useEffect } from 'react';

export const getSsoInfo = (ssoRequest) => {
  const parser = new DOMParser();
  if (ssoRequest.startsWith('<?xml')) {
    const xmlDoc = parser.parseFromString(ssoRequest, 'text/xml');
    const merchantId = xmlDoc.querySelector('merchantId')?.textContent;
    const merchantSecret = xmlDoc.querySelector('merchantSecret')?.textContent;
    const sellerId = xmlDoc.querySelector('clientId')?.textContent || 0; //Service provider value is 0, clientId is not passed
    const returnUrl = xmlDoc.querySelector('returnUrl')?.textContent;
    const reqLocale = xmlDoc.querySelector('locale')?.textContent || 'en_GB';
    const buyerId = xmlDoc.querySelector('buyer id')?.textContent || '';
    const clientRef = xmlDoc.querySelector('clientReference')?.textContent || '';
    return { merchantId, merchantSecret, sellerId, reqLocale, buyerId, returnUrl, clientRef };
  } else {
    // json case
    const jsonReq = JSON.parse(ssoRequest);
    const merchantId = jsonReq.paymentInformation.merchantId;
    const merchantSecret = jsonReq.paymentInformation.merchantSecret;
    const sellerId = jsonReq.paymentInformation.clientId || 0; //Service provider value is 0, clientId is not passed
    const returnUrl = jsonReq.paymentInformation.returnUrl;
    const reqLocale = jsonReq.paymentInformation.locale || 'en_GB';
    const buyerId = jsonReq.paymentInformation.buyer?.id || '';
    const clientRef = jsonReq.paymentInformation.clientReference || '';
    return { merchantId, merchantSecret, sellerId, reqLocale, buyerId, returnUrl, clientRef };
  }
};

export const getConfig = (refDataCache) => {
  var oldFeeDisplayLogicEnabled;
  if (refDataCache.configList && refDataCache.configList.length > 0)
    var config = (oldFeeDisplayLogicEnabled = refDataCache.configList.find((config) => {
      return (
        config.configKey && config.configKey === 'enable.mcp.credit.card.old.fee.display.logic'
      );
    }));
  if (config) {
    oldFeeDisplayLogicEnabled = config.configValue;
  }
  if (oldFeeDisplayLogicEnabled === undefined) {
    oldFeeDisplayLogicEnabled = 'false';
  }
  return oldFeeDisplayLogicEnabled;
};

export const getConfigValue = (keyName, configList) => {
  return configList.find((e) => e.configKey === keyName)?.configValue;
};

export const useScript = (src, onload, insertOnCondtion = true) => {
  useEffect(() => {
    if (insertOnCondtion) {
      let script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onload;
      script.onerror = () => {
        console.error(`Failed to load script ${src}`);
      };

      document.body.appendChild(script);

      // Cleanup script
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [insertOnCondtion, src]);
};
