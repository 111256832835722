import { createSlice } from '@reduxjs/toolkit';

const INITIAL_MODAL_DATA = {
  open: false,
  modalData: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: INITIAL_MODAL_DATA,
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      state.modalData = action.payload;
    },
    closeModal: (state) => {
      state.open = false;
      state.modalData = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export const selectModalData = (state) => state.modal.modalData;
export const selectModalOpen = (state) => state.modal.open;

export default modalSlice.reducer;
