import { useEffect, useState } from 'react';
import { useScript } from '../../../utils/helper';
import { getAmount, getCurrency } from '../../../utils/quoteService';
import { STATUSES } from '../constants';

const FlutterWave = ({ session, showReceipt, goToChoosePayments }) => {
  const [scriptReady, setScriptReady] = useState(false);

  useScript('https://checkout.flutterwave.com/v3.js', () => {
    setScriptReady(true);
  });

  useEffect(() => {
    if (scriptReady) {
      makePayment();
    }
  }, [scriptReady]);

  const makePayment = () => {
    let remitter = session.remitter;

    let flutter = FlutterwaveCheckout({
      public_key: session.flutterwavePublicKey,
      tx_ref: session.pledge.dealReference,
      amount: getAmount(session.selectedQuote),
      currency: getCurrency(session.selectedQuote),
      country: remitter.countryCode,
      payment_options: 'banktransfer',
      customer: {
        email: remitter.email,
        phone_number: remitter.contactNo,
        name: remitter.name,
      },
      callback: (data) => {
        flutter.close();
        if (data.status === 'successful' || data.status === 'completed') {
          showReceipt(STATUSES.SUCCESS); // show success receipt
        } else {
          goToChoosePayments();
        }
      },
      onclose: () => {
        goToChoosePayments();
      },
    });
  };

  return <div></div>;
};

export default FlutterWave;
