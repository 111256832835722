import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { Translate } from '../../i18n/translate';
import PayerDetail from '../PayerDetail';
import StudentDetail from '../StudentDetail';
import ConslidatedContext from './consolidatedContext';

const ConsolidatedPage = () => {
  const [showStudentSection, setShowStudentDetails] = useState(true);
  const [isStudentFormValid, setIsStudentFormValid] = useState(false);
  const [isPayerFormValid, setIsPayerFormValid] = useState(false);
  const [isBuyerFormSubmitted, setIsBuyerFormSubmitted] = useState(false);
  const buyerSubmitRef = useRef(null);

  return (
    <ConslidatedContext.Provider
      value={{
        isStudentFormValid,
        setIsStudentFormValid,
        buyerSubmitRef,
        isBuyerFormSubmitted,
        setIsBuyerFormSubmitted,
        isPayerFormValid,
        setIsPayerFormValid,
      }}
    >
      <div className='container max-w-[750px]'>
        <h4 className='text-16-bold'>
          <Translate value='JS_StudentDetails' />
          <span
            onClick={() => setShowStudentDetails(!showStudentSection)}
            className='cursor-pointer mr-0.5'
          >
            {showStudentSection ? (
              <Tooltip title='Click here to collapse'>
                <IconButton disableRipple>
                  <KeyboardArrowDownIcon style={{ fontSize: 34 }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title='Click here to expand'>
                <IconButton disableRipple>
                  <KeyboardArrowUpIcon style={{ fontSize: 34 }} />
                </IconButton>
              </Tooltip>
            )}
          </span>
        </h4>
        <div className={`accordion-content-cs ${showStudentSection ? 'open' : ''}`}>
          <StudentDetail />
        </div>

        <PayerDetail />
      </div>
    </ConslidatedContext.Provider>
  );
};

export default ConsolidatedPage;
