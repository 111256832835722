import { Button } from '@mui/material';
import { TranslateWithValues } from '../../i18n/translate';

const EmbeddedPayment = ({ pspURL, sellerName, hideReceiptPage, checkForUnrender }) => {
    return (
        <div className='text-center max-w-[750px] m-auto'>
            <iframe aria-label="iframe" src={pspURL} style={{ width: '100%', height: '100%', minHeight: '650px' }}></iframe>
            {hideReceiptPage && <div className='text-center'>
                <Button
                    variant='contained'
                    onClick={checkForUnrender}
                    className='text-14 !py-3 text-14 mt-16'
                >
                    <span>{TranslateWithValues('PM_RETURN_TO_SELLER', { sellerName })}</span>
                </Button>
            </div>}
        </div>
    )
}

export default EmbeddedPayment;