import CloseIcon from '@mui/icons-material/Close';
import { FormHelperText, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { sendInstructionEmail } from '../../apis';
import { Translate } from '../../i18n/translate';
import { imagePath } from '../../utils/imgHelper';
import { PATTERNS } from '../../utils/patterns';
import { getAmount, getCurrency, getHomeAmount, getHomeCurrency } from '../../utils/quoteService';
import InputWithLabel from '../InputWithLabel';

const ShareModal = ({ open, setOpen, selectedQuote, isOffline = true }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (e) => {
    if (!e.target.value) {
      setErrorMessage('JS_INSTRUCTION_EMAIL_REQUIRED');
    } else if (!e.target.value.match(PATTERNS.email)) {
      setErrorMessage('JS_INSTRUCTION_EMAIL_VALID');
    } else setErrorMessage('');
  };

  const sendEmail = async () => {
    setErrorMessage('');
    const expectedAmount = getAmount(selectedQuote);
    const expectedAmountCurrency = getCurrency(selectedQuote);
    const settlementAmount = getHomeAmount(selectedQuote);
    const settlementAmountCurrency = getHomeCurrency(selectedQuote);
    const emailAddress = email;
    let response = await sendInstructionEmail({
      emailAddress,
      settlementAmount,
      expectedAmount,
      expectedAmountCurrency,
      settlementAmountCurrency,
    });
    if (!response.success) {
      return;
    }
    response = response.data;
    if (!response.validationResult.success) {
      setErrorMessage('JS_INSTRUCTION_EMAIL_SEND_FAILURE_MSG');
      return;
    }
    setSuccessMessage(true);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle className='text-18-bold'>
        <img src={imagePath('icons/share-info.svg')} />
        <div>
          <Translate
            value={`${isOffline ? 'JS_INSTRUCTION_SHARE_TITLE' : 'JS_RECIEPT_SHARE_TITLE'}`}
          />
        </div>
        <IconButton aria-label='close' className='!absolute top-3 right-5' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id='alert-dialog-slide-description'
          className='text-lightGray3 14-400-22 mb-2'
        >
          <Translate
            value={`${isOffline ? 'JS_INSTRUCTION_EMAIL_DETAILS' : 'JS_RECIEPT_EMAIL_DETAILS'}`}
          />
        </DialogContentText>
        <InputWithLabel label='JS_INSTRUCTION_EMAIL_LABEL' required={true}>
          <TextField
            label={''}
            name={'email'}
            value={email}
            variant='outlined'
            inputProps={{
              pattern: PATTERNS.email,
            }}
            error={!!errorMessage}
            helperText={<Translate value={errorMessage} />}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => validateEmail(e)}
            disabled={successMessage}
          />
          {successMessage && (
            <FormHelperText className='text-success'>
              <Translate value={'JS_INSTRUCTION_EMAIL_SEND_SUCCESS_MSG'} />
            </FormHelperText>
          )}
        </InputWithLabel>
      </DialogContent>
      <DialogActions>
        <Button
          className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
          variant='contained'
          onClick={sendEmail}
          disabled={successMessage || !!errorMessage || !email}
        >
          <Translate
            value={`${
              isOffline ? 'JS_INSTRUCTION_EMAIL_SEND_BUTTON' : 'JS_RECIEPT_EMAIL_SEND_BUTTON'
            }`}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
