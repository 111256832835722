import _ from 'lodash';

export const getAmount = (quote) => {
  return _.get(quote, 'buyerAmount');
};
export const getHomeAmount = (quote) => {
  if (_.get(quote, 'paymentType.id') == 'CREDIT_CARD') {
    return _.get(quote.currentQuote, 'expectedAmount');
  } else if (
    _.get(quote, 'paymentType.isMcpCreditCard') &&
    _.get(quote, 'paymentType.defaultPspId !== WorldPay')
  ) {
    return _.get(quote.currentQuote, 'homeAmountAndFee');
  } else {
    return _.get(quote, 'homeAmount');
  }
};

export const getHomeAmountAndFee = (quote, configValue) => {
  if (configValue == 'true') {
    if (_.get(quote, 'paymentType.id') == 'CREDIT_CARD') {
      return _.get(quote.currentQuote, 'expectedAmount');
    } else if (_.get(quote, 'paymentType.isMcpCreditCard')) {
      return _.get(quote.currentQuote, 'homeAmountAndFee');
    } else {
      return _.get(quote, 'homeAmount');
    }
  } else {
    if (_.get(quote, 'paymentType.id') == 'CREDIT_CARD') {
      return _.get(quote.currentQuote, 'expectedAmount');
    } else {
      return _.get(quote, 'homeAmount');
    }
  }
};

export const getCurrency = (quote) => {
  return _.get(quote, 'buyerCurrency');
};

export const getHomeCurrency = (quote) => {
  if (_.get(quote, 'paymentType.id') == 'CREDIT_CARD') {
    return _.get(quote.currentQuote, 'expectedCurrency');
  } else {
    return _.get(quote, 'homeCurrency');
  }
};

export const getFee = (quote) => {
  return _.get(quote.currentQuote, 'fee');
};

export const getRate = (quote) => {
  return _.get(quote.currentQuote, 'buyerQuoteRate');
};

export const getClientRate = (quote) => {
  return _.get(quote.currentQuote, 'clientRate');
};
