// modalActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { openModal } from './modalSlice';

export const showModalWithFunctions = createAsyncThunk(
  'modal/showModalWithFunctions',
  async ({ title, body, yesLabel, noLabel, yesAction, noAction, icon }, { dispatch }) => {
    dispatch(
      openModal({
        title,
        body,
        yesLabel,
        noLabel,
        yesAction,
        noAction,
        icon
      }),
    );
  },
);
