import DOMPurify from 'dompurify';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { TranslateWithValues } from '../../i18n/translate';

export const Cf = (currency) => {
  return (
    <>
      <FormattedMessage id={`CC_${currency}`} />
    </>
  );
};

export const Nf = (number, minimumFractionDigits = 2) => {
  return (
    <FormattedNumber
      value={number}
      style='decimal'
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={minimumFractionDigits}
    />
  );
};

export const RawHtmlContent = ({ htmlContent, requireTranslate = true, translateValues = {} }) => {
  if (requireTranslate) {
    htmlContent = TranslateWithValues(htmlContent, translateValues);
  }

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
