const Tracker = ({ themeObject, scriptLoaded }) => {
  return (
    <div>
      {scriptLoaded && (
        <convera-payment-tracking
          dataLocale='en_GB'
          dataTheme={themeObject}
          dataReference-id=''
        ></convera-payment-tracking>
      )}
    </div>
  );
};

export default Tracker;
